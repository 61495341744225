@import '../../../../../../styles/main.scss';

.progressItem {
    position: relative;

    &__horizontal {
        display: flex;
        min-height: 52px;
        padding: 5px 12px;
        align-items: center;
        position: relative;

        &:hover {
            background-color: var(--hoverColor);
        }

        &Name {
            display: flex;
            align-items: center;
            @include text_small_bold;
            flex: 1;
            padding-right: 10px;
            word-break: break-all;

            svg {
                min-width: 20px;
                margin-right: 20px;
            }
        }

        &Member {
            @include text_small_bold;
            display: flex;
            text-align: left;
            align-items: center;
            padding-right: 5px;
            flex: 0.7;
            justify-content: space-between;


            & .avatar{
                width: 24px;
                height: 24px;
                min-width: 24px;
                border-radius: 20px;
                margin-right: 8px;
            }
        }


        &Status {
            display: flex;
            justify-content: space-between;
            align-items: center;
            flex: 0.9;


            &> :first-child {
              @include text_small_regular;
              color: $darkGray;
            }

            &Dots {
                display: flex;
                align-items: center;
                justify-content: center;
                cursor: pointer;
            }

            & button {
                max-height: 36px;
                min-width: 120px;
            }
        }
    }

    &__modal {
        width: 230px;
        padding: 10px;
        overflow: hidden;
        background-color: var(--white);
        border: 1px solid var(--lightGray);
        box-shadow: 0px 5px 60px rgba(0, 0, 0, 0.1);
        border-radius: 8px;
        transition: all 0.1s;
        -webkit-animation: anvil 0.15s cubic-bezier(0.38, 0.1, 0.36, 0.9) forwards;

        @keyframes anvil {
            0% {
                transform: scale(1) translateY(0px);
                opacity: 0;
            }

            1% {
                transform: scale(0.96) translateY(0px);
                opacity: 0;
            }

            100% {
                transform: scale(1) translateY(0px);
                opacity: 1;
            }
        }


        &Underline {
            width: 100%;
            height: 1px;
            background-color: $gray;
            margin: 7px 0;
        }

        &.bottom {
            height: auto;
        }

        &Item {
            height: 32px;
            display: flex;
            align-items: center;
            border-radius: 7px;
            @include text_small_regular;
            cursor: pointer;

            &:hover {
                background-color: var(--lightGray);
            }

            &.margin {
                margin: 2px 0;
            }

            svg {
                margin: 0 10px;
            }
        }
    }

    &__underline {
        width: 100%;
        height: 1px;
        background-color: $gray;
    }

    &__row{
        display: flex;
        align-items: center;
    }


    @media (max-width: 1150px) {
        &__horizontal {

            &Status {
                flex: 0.1;
                justify-content: flex-end;
                & > :first-child{
                    display: none;
                }
            }
        }
    }


    @media (max-width: 1045px) {
        &__horizontal {

            &Date {
                display: none;
            }
        }
    }

    @media (max-width: 900px) {
        &__horizontal {
            &Name {
                @include text_x_small_bold;

                svg {
                    margin-right: 10px;
                }
            }

            &Status {
                &> :first-child {
                    height: 22px;
                    padding: 0 5px;
                    @include item_header_10;
                }

            }
        }
    }

    @media (max-width: 600px) {
        &__horizontal {
            &Member {
                @include text_x_small_bold;
            }
        }
    }
}