@import '../../../../../../styles/main.scss';

.progressItem {
    position: relative;

    &__horizontal {
        display: flex;
        min-height: 52px;
        padding: 5px 12px;
        align-items: center;
        position: relative;

     

        & .continueButton {
            display: block;
        }

        & .viewButton {
            button {
                min-width: 74px;
            }
        }

        & .continueDots {
            display: none;
            cursor: pointer;
        }


        &:hover {
            background-color: var(--hoverColor);
        }

        &Name {
            display: flex;
            align-items: center;
            @include text_small_bold;
            flex: 1;
            padding-right: 10px;
            word-break: break-all;

            svg {
                min-width: 20px;
                margin-right: 20px;
            }
        }

        &Date {
            @include text_small_regular;
            color: $darkGray;
            text-align: left;
            flex: 0.8;
        }


        &Status {
            display: flex;
            justify-content: space-between;
            align-items: center;
            flex: 1.2;


            &> :first-child {
                height: 24px;
                padding: 0 10px;
                border-radius: 5px;
                display: flex;
                justify-content: center;
                align-items: center;
                @include item_header_12;
                background-color: rgba(58, 108, 255, 0.2);
                color: $blue;

                &.green {
                    background-color: $green20;
                    color: $mainGreen;
                }
            }

            &Dots {
                display: flex;
                align-items: center;
                justify-content: center;
                cursor: pointer;
            }

            & button {
                max-height: 36px;
                min-width: 120px;
            }
        }
    }

    &__modal {
        width: 230px;
        padding: 10px;
        overflow: hidden;
        background-color: var(--white);
        border: 1px solid var(--lightGray);
        box-shadow: 0px 5px 60px rgba(0, 0, 0, 0.1);
        border-radius: 8px;
        transition: all 0.1s;
        -webkit-animation: anvil 0.15s cubic-bezier(0.38, 0.1, 0.36, 0.9) forwards;

        @keyframes anvil {
            0% {
                transform: scale(1) translateY(0px);
                opacity: 0;
            }

            1% {
                transform: scale(0.96) translateY(0px);
                opacity: 0;
            }

            100% {
                transform: scale(1) translateY(0px);
                opacity: 1;
            }
        }


        &Underline {
            width: 100%;
            height: 1px;
            background-color: $gray;
            margin: 7px 0;
        }

        &.bottom {
            height: auto;
        }

        &Item {
            height: 32px;
            display: flex;
            align-items: center;
            border-radius: 7px;
            @include text_small_regular;
            cursor: pointer;

            &:hover {
                background-color: var(--lightGray);
            }

            &.margin {
                margin: 2px 0;
            }

            svg {
                margin: 0 10px;
                path {
                    fill: var(--black);
                }
            }
        }
    }

    &__underline {
        width: 100%;
        height: 1px;
        background-color: $gray;
    }

    @media (max-width: 1045px) {
        &__horizontal {

            &Date {
                display: none;

            }
        }
    }

    @media (max-width: 900px) {
        &__horizontal {
            &Name {
                @include text_x_small_bold;

                svg {
                    margin-right: 10px;
                }
            }

            &Status {
                &> :first-child {
                    height: 22px;
                    padding: 0 5px;
                    @include item_header_10;
                }

            }
        }
    }

    @media (max-width: 818px) {
        & .continueButton {
            & svg {
                display: none;
            }

            & button {
                min-width: 80px;
            }
        }
    }

    @media (max-width: 767px) {
        & .continueButton {
            & svg {
                display: inline;
            }

            & button {
                min-width: 120px;
            }
        }
    }

    @media (max-width: 600px) {
        &__horizontal {

            & .continueButton {
                display: none;
            }

            & .viewButton {
                display: none;
            }

            & .continueDots {
                display: block;
            }

            &Name {
                flex: 1.6;
                @include text_x_small_bold;
                padding-right: 5px;

                svg {
                    margin-right: 10px;
                }
            }

            &Status {
                flex: 1;

                &Left {
                    font-size: 8px;
                    line-height: 12px;
                    letter-spacing: 0.08em;
                }
            }
        }
    }
}