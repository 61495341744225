@import '../../styles/main.scss';
.switchField{
    height: 44px;
    border-radius: 7px;
    background-color: var(--lightGray);
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 16px;
    @include text_small_bold;
}