@import '../../../../../../../../styles/main.scss';

.selectorSettings {

    &__underline {
        width: 100%;
        height: 1px;
        background-color: $gray;
        margin: 10px 0 15px;
    }

    &__field {
        display: flex;
        align-items: center;
        margin-bottom: 5px;

        &> :first-child {
            flex: 1;
            margin-right: 10px;
        }

        &> :last-child {
            display: flex;
            justify-content: center;
            align-items: center;
            cursor: pointer;

            &.disactive {
                cursor: default;
            }
        }
    }

    &__addButton {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 115px;
        height: 36px;
        border: 1px solid $gray;
        box-shadow: 0px 3px 4px rgba(24, 24, 28, 0.05);
        border-radius: 7px;
        @include text_small_bold;
        color: $mainGreen;
        cursor: pointer;

        &> :first-child {
            margin-right: 7px;
        }
    }
}