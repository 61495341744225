@import '../../../../styles/main.scss';

.organizationSettings {
    height: 100%;
    display: flex;
    flex-direction: column;

    &__layout {
        padding-left: 28px
    }

    &__title {
        @include desktop_h2;
        color: $darkBlue;
        margin-top: 31px;
    }

    @media (max-width: 767px) {
        &__layout {
            padding-left: 20px
        }
    }
}