@import '../../../../styles/main.scss';

.myOrganization {
    display: flex;
    height: 64px;
    align-items: center;
    flex: 1;
    @include text_small_regular;
    color: $darkGray;
    text-align: left;
    padding: 0 10px;

    &:hover {
        background-color: var(--hoverColor);
    }

    &__search {
        margin: 25px 0;
        display: flex;
        align-items: center;
        gap: 15px;
    }

    &__organization {
        &Header {
            flex: 2.7;
        }
    }

    &__members {
        &Header {
            flex: 1;
        }
    }

    &__date {
        &Header {
            flex: 1.2;
        }
    }

    &__role {
        &Header {
            flex: 2;
        }
    }

    @media (max-width: 1200px) {

        &__date {
            &Header {
                display: none;
            }
        }
    }

    @media (max-width: 767px) {

        &__search {
            margin: 20px 0;
        }
    }

    @media (max-width: 919px) {

        &__members {
            &Header {
                display: none;
            }
        }
    }

    @media (max-width: 469px) {

        &__organization {
            &Header {
                flex: 2.3
            }
        }
    }
}