@import "../../../styles/main.scss";

.calculatorBody {
  width: 46%;
  &__title {
    @include text_small_bold;
    margin-bottom: 16px;
  }
  &__or {
    @include text_small_bold;
    color: $mediumGray;
    margin-block: 10px;
    text-align: center;
  }
  &__buttonSpace {
    margin-top: 12px;
    margin-bottom: 16px;
  }
  &__exportButton {
    padding-inline: 36px;
    padding-block: 8px;
  }
  &__result {
    &Box {
      background-color: var(--lightGray);
      border-radius: 7px;
      padding: 8px 0;
    }
    &Text {
      @include text_small_bold;
      text-align: center;
      color: $mediumGray;
    }
  }
}
