@import '../../../../../../../../styles/main.scss';

.metronomeSettings {

    &__switcher{
        margin-top: 10px;
    }

    &__underline{
        height: 1px;
        background-color: $gray;
        margin: 10px 0 15px;
        width: 100%;
    }

    &__addButton {
        width: 127px;
    }

    & .item {
        padding-bottom: 15px;
        border-bottom: 1px solid $gray;
        margin-bottom: 15px;

        &__header {
            display: flex;
            align-items: center;
            justify-content: space-between;
            @include text_small_bold;

            &Icon {
                display: flex;
                justify-content: center;
                align-items: center;
                cursor: pointer;
            }
            margin-bottom: 10px;
        }

        &__footer{
            margin-top: 10px;
            display: flex;
            align-items: flex-end;

            &Dropdown{
                width: 195px;
                margin-right: 15px;
            }

            &Repeat{
                display: flex;
                justify-content: center;
                align-items: center;
                margin-left: 15px;
                height: 44px;
                @include text_small_regular;

                & > :first-child{
                    margin-right: 10px;
                }
            }

            &Input{
                margin-top: 10px;
            }
        }
    }
}