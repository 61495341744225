.cropModal {
    width: 500px;
    height: 570px;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: center;
    position: relative;

    &__controls {
        width: 460px;
        margin: 0 0 10px;

        &:hover input[type='range']::-webkit-slider-thumb {
            box-shadow: 0px 0px 0px 8px rgba(63, 81, 181, 0.16);
            border-radius: 50%;
        }

        &:hover input[type='range']::-moz-range-thumb {
            box-shadow: 0px 0px 0px 8px rgba(63, 81, 181, 0.16);
        }
    }


    &__zoomRange {
        -webkit-appearance: none;
        -moz-appearance: none;
        height: 12px;
        border-top: 5px solid #fff;
        border-bottom: 5px solid #fff;
        background: #29B473;
        width: 100%;

        &::-moz-range-thumb {
            -webkit-appearance: none;
            -moz-appearance: none;
            border: 1px solid #29B473;
            background-color: #29B473;
            border-radius: 50%;
            width: 12px;
            height: 12px;
            transition: box-shadow 150ms cubic-bezier(41, 180, 115, 0.2) 0ms;
        }

        &::-webkit-slider-thumb {
            -webkit-appearance: none;
            -moz-appearance: none;
            border: 1px solid #29B473;
            background-color: #29B473;
            border-radius: 50%;
            width: 12px;
            height: 12px;
            transition: box-shadow 150ms cubic-bezier(41, 180, 115, 0.2) 0ms;
        }
    }



    &__buttons {
        display: flex;
        width: 100%;
        justify-content: space-between;

        &>* {
            width: 240px;
        }
    }

    @media (max-width: 600px){
        width: 100%;
    }

}