@import '../../../../../../../styles/main.scss';

.inviteMembers {

    // &__modal{
    //     width: 100vw;
    // }

    &__title {
        @include desktop_h4;
        text-align: left;
    }

    &__subtitle {
        @include text_medium_regular;
        color: $darkGray;
        margin: 5px 0 20px;
        text-align: left;
    }

    &__row {
        display: flex;
        align-items: center;
        align-items: flex-end;
        margin-top: 15px;

        &Input {
            width: 397px;
        }

        &Dropdown {
            width: 203px;
            margin: 0 15px 0 20px;
        }

        &Number {
            height: 44px;
            display: flex;
            justify-content: center;
            align-items: center;
            width: 30px;
            min-width: 30px;
            margin-right: 15px;
            @include text_medium_bold;
        }

        &Icon {
            height: 44px;
            display: flex;
            justify-content: center;
            align-items: center;

            svg {
                cursor: pointer;
            }
        }
    }

    &__addButton {
        width: 143px;
        margin: 15px 0 0 45px;
    }

    &__buttons {
        display: flex;
        justify-content: flex-end;
        margin-top: 70px;

        &>* {
            width: 200px;
        }

        & > :first-child {
            margin-right: 10px;
        }
    }

    @media (max-width: 830px) {
        &__modal{
            width: 87vw;
        }
    }

    @media (max-width: 767px) {

        &__row {
            &Number {
                width: 21px;
                min-width: 21px;
                margin-right: 10px; 
            }
        }

        &__addButton {
            margin-left: 31px;
        }
    }

    @media (max-width: 600px) {
        &__modal{
            width: 100vw;
        }

        &__row{
            &Dropdown {
                margin: 0 10px 0;
            }
        }
        &__buttons {
            margin-top: 25px;
            &>* {
                width: 50%;
            }
        }
    }
}