.fr-box{
    height: 100%;
    display: flex;
    flex-direction: column;
 }


 .fr-wrapper{
    height: 100%;
    max-height: 100%;
    overflow-y: auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
 }

 .fr-popup{
   z-index: 5000 !important;
 }