@import '../../styles/main.scss';

.dashboardNavigation {
    display: flex;
    height: 100vh;
    max-width: 100vw;

    &__error {
        position: fixed;
        width: 100vw;
        height: 50px;
        background-color: $red09;
        top: 0;
        left: 0;
        display: flex;
        align-items: center;
        justify-content: center;
        z-index: 200;

        p {
            margin-left: 10px;
            color: $red;
            @include text_small_bold;
        }

        &Button {
            position: absolute;
            right: 15px;
            cursor: pointer;
        }
    }

    &__content {
        width: 100%;
        max-width: calc(100vw - 280px);
        height: 100vh;
        background-color: var(--lightGray);

        @media (max-width: 767px) {
            max-width: 100vw;
            background-color: none;
        }

        & .loaderBlock {
            background-color: var(--lightGray);
            display: flex;
            flex-direction: column;
            flex-grow: 1;

            &__header {
                display: flex;
                justify-content: space-between;
                align-items: center;
                padding: 0 32px;
                position: relative;
                z-index: 1;
            }

            &__title {
                @include desktop_h2;
                color: $darkBlue;
                margin: 31px 0;
            }

            &__content {
                background-color: var(--white);
                box-shadow: 0px 3px 4px rgba(24, 24, 28, 0.05);
                border-radius: 7px 0px 0px 0px;
                padding: 32px;

                overflow: hidden;

                width: 100%;
                height: calc(100vh - 94px);
                max-height: calc(100vh - 94px);
            }
        }
    }

    @media (max-width: 767px) {
        &.hide {
            display: none;
        }
    }

}