@import '../../../styles/main.scss';

.choosePassword {
    width: 400px;

    @media (max-width: 600px) {
        width: 100%;
    }

    &__header {
        @include desktop_h1;
        color: $darkBlue;
        text-align: left;

        @media (max-width: 600px) {
            @include desktop_h2
        }
    }

    &__subtitle {
        @include text_medium_regular;
        color: $darkGray;
        text-align: left;
        margin: 10px 0 25px;
    }

    &__password {
        margin: 10px 0 25px;
        margin-top: 20px;

        &New{
            & > p {
                @include text_x_small_regular;
                color: $darkGray;
                margin-top: 5px;
            }   

            & .redColor {
                color: $red;
            }
        }

    }
}