@import "../../../../styles/main.scss";

.library {
  display: flex;
  flex-direction: column;
  flex: 1;
  max-height: 100%;

  &__loader {
    flex: 1;
    max-height: 100%;
  }

  & > :last-child {
    margin-top: 10px;
  }

  &__name {
    flex: 0.7;
  }

  &__date {
    flex: 0.4;
  }

  &__author {
    flex: 1.3;

    &::before {
      content: "author";
    }
  }

  &__search {
    margin-bottom: 25px;
    display: flex;
    justify-content: space-between;
    align-items: center;

    &Right {
      display: flex;
      justify-content: center;
      align-items: center;
      margin-left: 30px;

      & > :first-child {
        margin-right: 10px;
      }
    }

    &Button {
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;
    }
  }

  &__title {
    @include desktop_h4;
    color: $mediumGray;
    margin-bottom: 10px;
  }

  &__content {
    flex: 1;
    padding-bottom: 25px;
    overflow: hidden;
  }

  @media (max-width: 1150px) {
    &__author {
      flex: 1;
    }

    &__date {
      display: none;
    }
  }

  @media (max-width: 850px) {
    &__action {
      display: none;
    }
  }

  @media (max-width: 767px) {
    &__search {
      margin-bottom: 20px;
    }

    &__author {
      &::before {
        content: "";
      }

      flex: 0.4;
    }
  }

  @media (max-width: 600px) {
    &__search {
      flex-direction: column;
      align-items: flex-start;

      &Right {
        margin-left: 0px;
        margin-top: 20px;
      }
    }
  }
}

.paginationLoader {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;

  & svg {
    animation: rotation 1.5s infinite linear;
  }

  @keyframes rotation {
    from {
      transform: rotate(0deg);
    }

    to {
      transform: rotate(359deg);
    }
  }
}

.pagination {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 25px;

  &__left {
    @include text_small_regular;
    color: $darkGray;

    span {
      @include text_small_bold;
      color: var(--black);
    }
  }
}
