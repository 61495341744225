@import '../../../../../../styles/main.scss';

.progressItem {
    position: relative;
    display: flex;
    flex-direction: column;
    min-height: 137px;
    box-shadow: 0px 3px 4px rgba(24, 24, 28, 0.05);
    border: 1px solid #DBDEE7;
    background-color: #FFFFFF;
    border-radius: 7px;
    padding: 18px 20px 12px;

    &Dark {
        background-color: var(--lightGray);
        border: none;
    }

    &:hover {
        background-color: var(--hoverColor);
    }


    &__header {
        display: flex;
        align-items: flex-start;
        justify-content: space-between;

        &Status {
            height: 24px;
            padding: 0 10px;
            border-radius: 5px;
            display: flex;
            justify-content: center;
            align-items: center;
            @include item_header_12;
            background-color: rgba(58, 108, 255, 0.2);
            color: $blue;

            &.green {
                background-color: $green20;
                color: $mainGreen;
            }
        }

        &Dots {
            display: flex;
            justify-content: center;
            align-items: center;
            cursor: pointer;

            &Complete {
                display: none;
                cursor: pointer;
            }
        }
    }

    &__title {
        @include desktop_h4;
        flex: 1;
        margin: 7px 0 3px;
    }

    & button {
        max-height: 36px;
        max-width: 120px;
    }


    &__modal {
        width: 230px;
        padding: 10px;
        overflow: hidden;
        background-color: var(--white);
        border: 1px solid var(--lightGray);
        box-shadow: 0px 5px 60px rgba(0, 0, 0, 0.1);
        border-radius: 8px;
        transition: all 0.1s;
        -webkit-animation: anvil 0.15s cubic-bezier(0.38, 0.1, 0.36, 0.9) forwards;

        @keyframes anvil {
            0% {
                transform: scale(1) translateY(0px);
                opacity: 0;
            }

            1% {
                transform: scale(0.96) translateY(0px);
                opacity: 0;
            }

            100% {
                transform: scale(1) translateY(0px);
                opacity: 1;
            }
        }


        &Underline {
            width: 100%;
            height: 1px;
            background-color: $gray;
            margin: 7px 0;
        }

        &.bottom {
            height: auto;
        }

        &Item {
            height: 32px;
            display: flex;
            align-items: center;
            border-radius: 7px;
            @include text_small_regular;
            cursor: pointer;

            &:hover {
                background-color: var(--lightGray);
            }

            &.margin {
                margin: 2px 0;
            }

            svg {
                margin: 0 10px;
                path {
                    fill: var(--black)
                }
            }
        }
    }

    @media (max-width: 900px) {
        min-height: 119px;
        padding: 18px 10px 18px;

        &__title {
            @include text_x_small_bold;
            margin-bottom: 5px;
            flex: 1;
        }


        &__header {
            &Status {
                height: 22px;
                padding: 0 5px;
                @include item_header_10;
            }
        }
    }

    @media (max-width: 600px) {

        &__header {
            &Dots {
                &Complete {
                    display: block;
                }
            }
        }


        &__button {
            display: none;
            background-color: red;

            & button {
                display: none;
                background-color: red;

            }
        }

        &__modal {
            width: 165px;

            &Item {
                height: auto;
                min-height: 32px;
                padding: 5px 0;
            }
        }
    }
}