@import '../../../../../../../styles/main.scss';

.container{
    height: 48px;
    border: 1px dashed $mainGreen;
    border-radius: 7px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 16px;

    @include text_medium_regular;
    color: $darkGray;
}