@import '../../../styles/main.scss';

.signIn {
    width: 400px;

    @media (max-width: 600px) {
        width: 100%;
    }

    &__header {
        @include desktop_h1;
        color: $darkBlue;
        text-align: left;
        margin-bottom: 25px;

        @media (max-width: 600px) {
            @include desktop_h2
        }
    }

    &__googleButton {
        margin-top: 25px;
        margin-bottom: 10px;

        &.error{
            button{
                border: 1px solid rgba(239, 47, 50, 0.2);
            }
        }
    }


    &__appleButton {

        &.error{
            button{
                border: 1px solid rgba(239, 47, 50, 0.2);
            }
        }
    }

    &__error{
        @include text_x_small_bold;
       color: $red;
       margin-top: 4px;
    }

    &__condition {
        display: flex;
        align-items: center;
        margin: 25px 0;

        &Line {
            width: 100%;
            height: 1px;
            background-color: $gray;
        }

        p {
            @include text_x_small_bold;
            margin: 0 15px;
            color: $gray;
        }
    }

    &__button {
        margin: 25px 0;
    }

    &__password {
        margin: 15px 0;
    }

    &__forgotPassword {
        @include text_small_bold;
        text-align: right;
        margin-top: 15px;
        color: $mainGreen;
        display: flex;
        justify-content: flex-end;

        &>div {
            cursor: pointer;
        }
    }


    p {
        color: $darkGray;
        @include text_small_regular;

        span {
            color: $mainGreen;
            @include text_small_bold;
            cursor: pointer;
        }
    }

    // tbody {
    //     &  * {
    //         border: 1px solid black;
    //     }
    //     border: 1px solid black;
    
    // }
}

