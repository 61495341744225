@import '../../../styles/main.scss';

.leftPanel {
    width: 280px;
    min-width: 280px;
    background-color: var(--lightGray);
    display: flex;
    flex-direction: column;
    overflow-y: auto;


    &__logo {
        width: 140px;
        cursor: pointer;

        svg {
            width: 100%;
            height: 32px;
            margin: 35px 0 0 35px
        }
    }

    &__content {
        display: flex;
        flex-direction: column;
        flex: 1;
        margin-top: 23px;
        width: 248px;
        align-self: center;
        justify-content: space-between;


        &Title {
            @include item_header_10;
            color: $mediumGray;
            text-transform: uppercase;
            margin: 38px 0 13px 20px;
        }

        a {
            height: 44px;
            display: flex;
            align-items: center;
            margin-bottom: 5px;
            @include text_small_bold;
            color: $mediumGray;
            border-radius: 8px;
            position: relative;
            padding-left: 20px;

            svg {
                stroke: $mediumGray;
            }


            &.active {
                background-color: $green10;
                color: $mainGreen;

                svg {
                    stroke: $mainGreen;
                }
            }

            svg {
                margin-right: 10px;
            }
        }

        &LinkButton{
            height: 44px;
            display: flex;
            align-items: center;
            margin-bottom: 5px;
            @include text_small_bold;
            color: $mediumGray;
            border-radius: 8px;
            position: relative;
            padding-left: 20px;
            cursor: pointer;

            svg {
                stroke: $mediumGray;
                margin-right: 10px;
            }
        }

        &Counter {
            position: absolute;
            right: 10px;
        }
    }



    &__organization {
        padding: 0;
        top: 64px;
        padding-bottom: 30px;

        &Block {
            padding: 36px;
            width: 820px;
        }
    }



    @media (max-width: 966px) {
        &__organization {

            &Content {
                width: 87vw;
                max-width: 87vw;
            }

            &Block {
                padding: 36px;
                width: 100%;
            }
        }
    }

    @media (max-width: 900px) {

        &__logo {
            svg {
                margin: 23px 0 0 23px
            }
        }

        &__content {
            margin-top: 12px;

            &Title {
                margin-top: 24px;
            }
        }

    }

    @media (max-width: 767px) {
        display: none;
        width: 100vw;
        max-width: 100vw;

        &::-webkit-scrollbar {
            display: none;
        }


        &__content {
            align-self: flex-start;
            margin-left: 16px;
            margin-top: 14px;



            &Title {

                margin: 32px 0 8px 20px;
            }
        }

        &.show {
            display: flex;
            justify-content: flex-start;
        }
    }


    @media (max-width: 600px) {
        &__organization {
            top: 0;
            padding: 0;

            &Content {
                width: 100vw;
                max-width: 100vw;
                min-height: 100vh;
                height: fit-content;

            }

            &Block {
                padding: 0;

            }
        }
    }

}