@import '../../../../styles/main.scss';

.allMembers {

    &__search {
        margin: 25px 0;
    }


    &__memberHeader {
        flex: 1.2;
    }

    &__dateHeader {
        flex: 1;
        &::before{
            content: "Account added date";
        }
    }

    &__roleHeader {
        flex: 1;
    }

    @media (max-width: 1220px) {
        &__dateHeader {
            flex: 0.7;
        }

        &__roleHeader {
            flex: 0.8;
        }
    }

    @media (max-width: 960px) {
        &__dateHeader {
            &::before{
                content: "Date";
            }
        }
    }

    @media (max-width: 767px) {
        &__search {
            margin: 20px 0;
        }
    }
}