@import "../../../styles/main.scss";

.academy {
  overflow: scroll;
  &__videos {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-gap: 25px;
    margin-top: 25px;
    justify-items: center;
    align-items: center;

    @media (max-width: 1440px) {
      grid-template-columns: 1fr 1fr;
    }
    @media (max-width: 1080px) {
      grid-template-columns: 1fr;
    }
  }
  &__QA {
    margin-top: 25px;
    &Title {
      @include desktop_h1;
      color: $mainGreen;
    }
    &Container {
      margin-top: 25px;
    }

    &Item {
      border-bottom: 1px solid #0807081a;
      padding-bottom: 15px;
      margin-bottom: 41px;
      &Header {
        display: flex;
        justify-content: space-between;
      }
      &Title {
        @include text_large_bold;
        color: $darkBlue;
      }
      &Plus {
        @include desktop_h4;
        color: $darkBlue;
        margin-right: 15px;
        cursor: pointer;
      }
      &Answer {
        @include text_medium_regular;
        color: #616A85;
        margin-top: 15px;
      }
    }
  }
}
