@import "../../../../../../styles/main.scss";

.organizationItem {
  min-height: 253px;
  background-color: var(--white);
  box-shadow: 0px 3px 4px rgba(24, 24, 28, 0.05);
  border-radius: 7px;
  border: 1px solid $gray;
  padding: 20px;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;

  &:hover {
    background-color: var(--hoverColor);
  }

  &__dark {
    background-color: var(--lightGray);
    border: none
  }

  &__top {
    display: flex;
    flex-direction: column;
    align-items: center;

    &Avatar {
      width: 76px;
      height: 76px;
      min-height: 76px;
      border-radius: 7px;
      overflow: hidden;
      @include desktop_h3;

      & > img {
        width: 100%;
        height: 100%;
      }
    }

    &Title {
      @include desktop_h4;
      margin: 15px 0 5px;
      text-align: center;
      word-break: break-all;
    }

    &Subtitle {
      @include text_x_small_regular;
      color: $darkGray;
      text-align: center;
    }
  }

  &__button {
    width: 100%;
    margin-top: 5px;

    button {
      max-height: 36px;
    }
  }

  @media (max-width: 900px) {
    min-height: 180px;

    &__top {
      &Avatar {
        width: 32px;
        height: 32px;
        min-height: 32px;
      }

      &Title {
        @include desktop_h5;
      }
    }

    &__button {
      button {
        max-height: 28px;
      }
    }
  }
}
