@import '../../../../../../../../styles/main.scss';

.timeMarker {

    flex: 1;
    display: flex;
    flex-direction: column;
    padding: 10px 0;

    &__text {
        @include text_large_regular;
        margin-bottom: 10px;
    }

    &__markButtons {
        width: min-content;
        display: grid;
        grid-template-columns: 1fr 1fr;
        grid-column-gap: 15px;
        grid-row-gap: 10px;
        margin-bottom: 10px;
        width: 495px;
        align-self: center;
    }

    &__markButton {
        // width: 240px;
        // max-width: 240px;
    }

    &__buttons {
        display: flex;
        align-self: flex-end;
    }

    &__returnButton {
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        width: 44px;
        height: 44px;
        border: 1px solid #DBDEE7;
        box-shadow: 0px 3px 4px rgba(24, 24, 28, 0.05);
        border-radius: 7px;
    }

    &__resetButton {
        width: 120px;
        margin-left: 15px;
    }

    &__markButton {
        min-width: 120px;

        & button {
            padding: 0 15px;
        }
    }



    // flex: 1;
    // display: flex;
    // align-items: flex-start;
    // justify-content: space-between;
    // padding: 10px 0;

    // &__left {
    //     @include text_large_regular;
    //     color: var(--black);
    //     min-width: max-content;
    //     padding-right: 30px;
    // }

    // &__right {
    //     display: flex;
    //     align-items: center;
    //     flex-wrap: wrap;
    //     gap: 10px;
    //     justify-content: flex-end;
    // }

    // &__returnButton {
    //     display: flex;
    //     align-items: center;
    //     justify-content: center;
    //     cursor: default;
    //     width: 44px;
    //     height: 44px;
    //     border: 1px solid #DBDEE7;
    //     box-shadow: 0px 3px 4px rgba(24, 24, 28, 0.05);
    //     border-radius: 7px;
    //     cursor: pointer;

    //     &.disable {
    //         cursor: default;
    //     }
    // }

    // &__resetButton {
    //     width: 120px;
    //     // margin: 0 15px;
    // }

    // &__markButton {
    //     min-width: 120px;
    //     & button{
    //         padding: 0 15px;
    //     }
    // }

    // @media (max-width: 476px) {
    //   flex-direction: column;

    //   &__right {
    //     justify-content: flex-start;
    //     margin-top: 10px;
    //   }
    // }

    @media (max-width: 625px) {
        &__markButtons {
            width: 100%;
        }
    }

    @media (max-width: 540px) {
        &__markButtons {
            grid-template-columns: 1fr;
        }

        &__markButton{
            max-width: 240px;
        }
    }
}