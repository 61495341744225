@import '../../../../styles/main.scss';

.pdfItem {
    position: relative;

    & .content {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        min-height: 137px;
        box-shadow: 0px 3px 4px rgba(24, 24, 28, 0.05);
        border: 1px solid #DBDEE7;
        background-color: #FFFFFF;
        border-radius: 7px;
        padding: 18px 20px 12px;

        &Dark {
            background-color: var(--lightGray);
            border: none
        }

        &__header {
            display: flex;
            justify-content: space-between;
            align-items: flex-start;
            width: 100%;
            margin-bottom: 7px;

            &Pdf{
                width: 45px;
                height: 24px;
                border-radius: 5px;
                background-color: $blue;
                display: flex;
                align-items: center;
                justify-content: center;
                @include item_header_12;
                color: var(--white);
            }

            & button {
                max-width: 49px;
                width: 49px;
                height: 24px;
            }


            &> :last-child {
                display: flex;
                justify-content: center;
                align-items: center;
                cursor: pointer;
            }
        }

        &__title {
            flex: 1;
            word-break: break-all;
            @include desktop_h4;
        }

        &__footer {
            display: flex;
            align-items: center;
            margin-top: 7px;


            &.yellow {
                color: $orange;

                &> :first-child {
                    background-color: $orange;
                }
            }

            &.red {
                color: $red;

                &> :first-child {
                    background-color: $red;
                }
            }

            &.green {
                color: $mainGreen;

                &> :first-child {
                    background-color: $mainGreen;
                }
            }



            &Dot {
                width: 8px;
                height: 8px;
                border-radius: 10px;
            }

            &>p {
                font-size: 12px;
                line-height: 12px;
                text-transform: uppercase;
                letter-spacing: 0.08em;
                font-weight: 700;
                margin: 0 6px;
            }

            &Refresh {
                display: flex;
                justify-content: center;
                align-items: center;
                width: 24px;
                height: 24px;
                border-radius: 100px;
                background-color: $green20;
                cursor: pointer;
            }
        }
    }

    &__modal {
        width: 230px;
        padding: 10px;
        margin-left: 10px;
        overflow: hidden;
        background-color: var(--white);
        border: 1px solid var(--lightGray);
        box-shadow: 0px 5px 60px rgba(0, 0, 0, 0.1);
        border-radius: 8px;
        transition: all 0.1s;
        -webkit-animation: anvil 0.15s cubic-bezier(0.38, 0.1, 0.36, 0.9) forwards;

        @keyframes anvil {
            0% {
                transform: scale(1) translateY(0px);
                opacity: 0;
            }

            1% {
                transform: scale(0.96) translateY(0px);
                opacity: 0;
            }

            100% {
                transform: scale(1) translateY(0px);
                opacity: 1;
            }
        }


        &Underline {
            width: 100%;
            height: 1px;
            background-color: $gray;
            margin: 7px 0;
        }

        &.bottom {
            height: auto;
        }

        &Item {
            height: 32px;
            display: flex;
            align-items: center;
            border-radius: 7px;
            @include text_small_regular;
            cursor: pointer;

            &:hover {
                background-color: var(--lightGray);
            }

            &.margin {
                margin: 2px 0;
            }

            svg {
                margin: 0 10px;
            }

            &Logo {
                display: flex;
                justify-content: center;
                align-items: center;
                position: absolute;
                right: 0;
                margin-right: 10px;

                animation: rotation 1.5s infinite linear;

                @keyframes rotation {
                    from {
                        transform: rotate(0deg);
                    }

                    to {
                        transform: rotate(359deg);
                    }
                }
            }
        }
    }



    @media (max-width: 900px) {

        & .content {
            min-height: 105px;
            padding: 18px 10px 12px;

            &__title {
                @include text_x_small_bold;
            }

            &__footer {
                display: flex;
                align-items: center;
                margin-top: 7px;

                &>p {
                    font-size: 8px;
                }

                &Refresh {
                    width: 20px;
                    height: 20px;

                    & svg {
                        max-width: 16px;
                        height: 16px;
                    }
                }
            }
        }
    }

    @media (max-width: 390px) {
        & .content {

            &__footer {
                width: min-content;
            }
        }
    }
}