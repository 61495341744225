
@import '../../../../../../styles/main.scss';

.section{
    padding: 15px 15px 5px;
    border: 1px solid;
    border-radius: 7px;
    margin-bottom: 10px;
    background-color: var(--white);


    &__collapseArrow{
        display: block;
        width: 24px;
        height: 22px;
        cursor: pointer;
        margin: 0 0 10px 0px;
        
        &.rotate{
            transform: rotate(180deg);
            margin-bottom: 0;
        }
    }


    &.checked{
        background-color: var(--mediumGray);
    }

    &__header{
        display: flex;
        align-items: center;
        justify-content: space-between;

        &Right{
            display: flex;
            flex-direction: column;
            align-items: end;
            justify-content: flex-end;
            margin-bottom: 37px;

            &Checked {
                margin-bottom: 0;
            }
            
            &Date{
                @include text_x_small_bold;
                align-self: center;
                margin-top: 15px;
                height: 16px;
                margin-bottom: 6px;
                width: max-content;
            }  
        }

        &Header{
            @include desktop_h2;
            margin-right: 10px;
            // &Checked {
            //     text-decoration: line-through;
            // }
        }

        &Date{
            @include text_x_small_bold;
            margin-right: 10px;
            width: max-content;
        }

        &Description{
            @include text_small_bold;
            margin-right: 10px
        }
        
    }

    &__description{
        @include text_small_bold;
        margin-top: 5px;
    }

    &.checked{
        background-color: #F5F5F7;
    }

    & .hide{
        height: 0px;
        overflow: hidden;
    }
}
