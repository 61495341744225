@import '../../styles/main.scss';

.switch {
  width: 44px;
  height: 24px;
  border-radius: 12px;
  background-color: $gray;
  display: flex;
  align-items: center;
  cursor: pointer;
  transition: all 0.3s;

  &.active {
    background-color: $mainGreen;
  }

  &__circle {
    width: 20px;
    height: 20px;
    border-radius: 100px;
    background-color: var(--white);
    margin-left: 2px;
    transition: all 0.3s;

    &.active {
      margin-left: 22px;
    }
  }
}
