@import '../../../styles/main.scss';

.resetPassword {
    width: 400px;

    @media (max-width: 600px) {
        width: 100%;
    }


    &__header {
        @include desktop_h1;
        color: $darkBlue;
        text-align: left;

        @media (max-width: 600px) {
            @include desktop_h2
        }
    }


    &__picker{
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        align-self: center;
        margin: 25px auto;
        width: 102px;

        cursor: pointer;

        &Avatar {
            width: 100px;
            height: 100px;
            border-radius: 100px;
            border: 3px dashed $gray;
            display: flex;
            justify-content: center;
            align-items: center;
            overflow: hidden;

            &.exist{
                border: 0;
            }

            &>img {
                width: 100%;
                height: 100%;
            }
        }

        &>p {
            @include text_x_small_regular;
            color: $mediumGray;
            margin-top: 10px;
            text-align: center;
        }
    }

    &__input {
        margin: 15px 0 25px;
    }

}