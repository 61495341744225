@import '../../styles/main.scss';

.minutesPicker {

    &__label {
        @include text_small_bold;
        color: $darkGray;
        margin-bottom: 5px;
    }

    &__fields {
        display: flex;

        &Input {
            display: flex;
            border-radius: 7px;
            height: 44px;
            width: 60px;
            border-radius: 7px;
            border: 1px solid #DBDEE7;
            box-shadow: 0px 3px 4px rgba(24, 24, 28, 0.05);
            outline: none;
            
            overflow: hidden;

            &>input {
                width: 100%;
                height: 100%;
                border: none;
                outline: none;
                text-align: center;

                &::placeholder{
                    color: $mediumGray;
                    @include text_small_regular;
                }

            }

            &.focus {
                box-shadow: 0px 0px 0px 3px rgba(58, 108, 255, 0.2);
                border: 1px solid $blue;
            }
        }



        &>p {
            @include text_small_regular;
            margin: 0 5px;
            display: flex;
            justify-content: center;
            align-items: center;
        }
    }
}