@import '../../styles/main.scss';

.alertModule {
    position: fixed;
    width: 100vw;
    height: 50px;
    top: 0;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 200002;
    padding: 0 40px;
    text-align: center;
    


    &__loader {
        display: flex;
        justify-content: center;
        align-items: center;
        animation: rotation 1.5s infinite linear;

        @keyframes rotation {
            from {
                transform: rotate(0deg);
            }

            to {
                transform: rotate(359deg);
            }
        }
    }

    &.red {
        background-color: $red09;
        color: $red;
    }

    &.blue {
        background-color: rgba(216, 226, 255, 0.9);
        color: $blue;


    }

    &.green {
        background-color: rgba(212, 240, 227, 0.9);
        color: $mainGreen;

    }

    &.orange {
        background-color: rgba(255, 241, 208, 0.9);
        color: $orange;
    }


    p {
        margin-left: 10px;
        @include text_small_bold;
    }

    &__button {
        position: absolute;
        right: 15px;
        cursor: pointer;
    }

}