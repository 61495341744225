.grid {
  &Wrapper {
    flex: 1;
    overflow-y: auto;

    // &::-webkit-scrollbar {
    //     display: none;
    // }
  }

  padding-top: 5px;

  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;

  grid-column-gap: 15px;
  grid-row-gap: 15px;

  @media (max-width: 1440px) {
    grid-template-columns: 1fr 1fr 1fr;
  }

  @media (max-width: 1150px) {
    grid-template-columns: 1fr 1fr;
  }

  @media (max-width: 900px) {
    grid-column-gap: 5px;
    grid-row-gap: 5px;
  }

  @media (max-width: 767px) {
    max-height: 80vh;
  }
}
