@import '../../../../../../styles/main.scss';

.requestedItem {
    display: flex;
    min-height: 64px;
    padding: 0 12px;
    padding: 5px 0;

    &:hover {
        background-color: var(--hoverColor);
    }

    &__member {
        flex: 2;
        display: flex;
        align-items: center;

        &Avatar {
            width: 40px;
            height: 40px;
            border-radius: 100px;
            overflow: hidden;
            margin-right: 10px;

            &>img {
                width: 100%;
                height: 100%;
            }
        }

        &Initials {
            width: 100%;

            &> :first-child {
                @include text_small_bold;
                line-height: 18px;
                max-width: 95%;
            }

            &> :last-child {
                @include text_small_regular;
                color: $mediumGray;
                line-height: 18px;
                max-width: 95%;
            }
        }
    }

    &__date {
        flex: 1;
        @include text_small_regular;
        display: flex;
        align-items: center;
    }

    &__role {
        flex: 1.5;
        display: flex;
        justify-content: space-between;
        align-items: center;

        &>p {
            @include text_small_regular;
        }

        &Dropdown {
            width: 230px;
        }

        &Left {
            display: flex;
            align-items: center;

            &>svg {
                margin-left: 10px;
                animation: rotation 1.5s infinite linear;

                @keyframes rotation {
                    from {
                        transform: rotate(0deg);
                    }

                    to {
                        transform: rotate(359deg);
                    }
                }

            }
        }
    }

    &__actions {
        flex: 1;
        display: flex;
        align-items: center;
        flex-wrap: wrap;

        &> :first-child {
            margin-right: 10px;
        }

        &Button {
            min-width: 89px;
            height: 36px;
            max-height: 36px;
        }
    }

    &__underline {
        height: 1px;
        width: 100%;
        background-color: $gray;
    }


    @media (max-width: 1400px) {
        &__date {
            display: none;
        }
    }

    @media (max-width: 1220px) {
        &__member {
            flex: 1.7;
        }

        &__role {
            &Dropdown {
                width: 140px;
            }
        }


    }

    @media (max-width: 1133px) {
        &__actions {
            flex: 0.8;

            &> :first-child {
                margin-bottom: 5px;
            }
        }
    }

    @media (max-width: 1077px) {
        &__member {
            &Avatar {
                width: 24px;
                height: 24px;
                min-width: 24px;
                margin-right: 8px;
                min-width: 24px;

                &>img {
                    width: 100%;
                    height: 100%;
                }
            }
        }
    }

    @media (max-width: 960px) {
        &__member {

            &Initials {
                &> :last-child {
                    @include text_x_small_regular;
                    font-size: 10px
                }
            }

        }
    }

    @media (max-width: 900px) {
        &__role {
            &Dropdown {
                width: 90px;

                &> :first-child {
                    &> :first-child {
                        max-height: 24px;
                    }
                }

                & svg {
                    max-height: 20px;
                }
            }
        }

        &__actions {
            @include text_x_small_bold;
            // flex-wrap: nowrap;

            &Button {
                @include text_x_small_bold;
                min-width: 72px;
                height: 28px;
                max-height: 28px;
            }
        }
    }

    @media (max-width: 600px) {
        &__role {
            display: none;
        }

        &__actions {
            flex: 1.5;
            flex-wrap: nowrap;

            &> :first-child {
                margin-bottom: 0;
            }
        }

        &__member {
            &Avatar {
                display: none;
            }
        }
    }
}