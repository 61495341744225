@import '../../../../../../../../styles/main.scss';

.algorithmSettings {
    &__underline{
        height: 1px;
        width: 100%;
        background-color: $gray;
        margin: 10px 0 15px;
    }

    &__addButton{
        margin-bottom: 15px;
        &  button{
            max-width: 190px;
        }
    }
}