@import '../../../../../styles/main.scss';

.userModule {
    width: 248px;
    height: 74px;
    box-shadow: 0px 3px 4px rgba(24, 24, 28, 0.05);
    border-radius: 7px;
    background-color: var(--white);
    align-self: center;
    display: flex;
    align-items: center;
    padding: 0 20px 0 10px;
    justify-content: space-between;
    cursor: pointer;
    position: relative;
    z-index: 2;
    margin-top: 20px;
    margin-bottom: 20px;
    min-height: 74px;

 

    &__avatar {
        width: 40px;
        height: 40px;
        min-width: 40px;
        border-radius: 100px;
        overflow: hidden;

        & > img{
            width: 100%;
        }
    }

    &__center {
        width: 134px;

        &Title {
            @include text_small_bold;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
        }

        &Subtitle {
            @include text_x_small_regular;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
        }
    }


    @keyframes anvil {
        0% {
          transform: scale(1) translateY(0px);
          opacity: 0;
        }
        1% {
          transform: scale(0.96) translateY(10px);
          opacity: 0;
        }
        100% {
          transform: scale(1) translateY(0px);
          opacity: 1;
        }
      }


    &__modal {
        width: 300px;
        height: 318px;
        box-shadow: 0px 5px 60px rgba(0, 0, 0, 0.1);
        border-radius: 8px;
        border: 0;
        background-color: var(--white);
        -webkit-animation: anvil 0.15s cubic-bezier(0.38, 0.1, 0.36, 0.9) forwards;


        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 25px 0 15px;
        transition: all 0.1s;
        cursor: default;
        margin-bottom: 5px;

        &Avatar{
            width: 76px;
            height: 76px;
            border-radius: 100px;

            & img{
                width: 100%;
                height: 100%
            }
        }

        &Title{
            @include desktop_h3;
            margin: 20px 0 5px;
            text-align: center
        }

        &Subtitle{
            @include text_small_regular;
            color: $darkGray;
        }

        &Underline{
            width: 100%;
            height: 1px;
            background-color: $gray;
            margin: 20px  0 10px;
        }

        &Button {
            height: 44px;
            width: 100%;
            display: flex;
            align-items: center;
            padding: 0 20px;
            @include text_small_bold;
            color: $mediumGray;
            margin-top: 5px;
            cursor: pointer;

            svg{
                margin-right: 10px;
            }
        }
    }


}

.logoutModal{
    width: 542px;

    &__title{
        @include desktop_h4;
        text-align: center;
        margin-bottom: 20px;
    }


    &__buttons{
        display: flex;
        flex-direction: row;
        justify-content: space-between;

        & > * {
            width: 266px;
        }
    }
}