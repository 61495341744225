@import "../../styles/main.scss";

.gridDraggable {
  flex: 1;
  overflow-y: auto;

  &__title {
    @include desktop_h4;
    color: $mediumGray;
    margin-bottom: 15px;
  }

  &__folderWrapper {
    // height: 72px;
    // height: 0;
    height: unset;
  }

  &__items {
    &Folders {
      margin-bottom: 25px;
    }

    &Content {
      padding-top: 5px;
      display: grid;
      grid-template-columns: 1fr 1fr 1fr 1fr;
      grid-column-gap: 15px;
      grid-row-gap: 15px;
    }

    & .copiedItem {
      & ~ div {
        transform: none !important;
      }
    }

    & .activeItem {
      background-color: $green10;
    }
  }

  @media (max-width: 1440px) {
    &__items {
      &Content {
        grid-template-columns: 1fr 1fr 1fr;
      }
    }
  }

  @media (max-width: 1150px) {
    &__items {
      &Content {
        grid-template-columns: 1fr 1fr;
      }
    }
  }

  @media (max-width: 900px) {
    &__items {
      &Content {
        grid-column-gap: 5px;
        grid-row-gap: 5px;
      }
    }
  }
  @media (max-width: 767px) {
    max-height: 80vh;
  }
}
