@import '../../../../../../../styles/main.scss';

.container {
    min-height: 38px;
    border: 1px solid $gray;
    border-radius: 7px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 5px 20px 5px 26px;
    max-width: 100%;
    position: relative;

    @include text_small_bold;
    color: $mainGreen;

    &__popperTrigger {
        height: 20px;
        width: 20px;
        position: absolute;
        z-index: 20;
        bottom: 0;
        left: 0;
        background-color: red;
    }


    & svg {
        cursor: pointer;
    }

    & p {
        margin-right: 10px;
        width: max-content;
    }

    width: min-content;

    &> :last-child {
        cursor: pointer;
    }
}


.popup {
    background-color: white;
    box-shadow: 0px 5px 60px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
    max-width: 340px;
    padding: 10px;
    @include text_small_regular;
    -webkit-animation: anvil 0.15s cubic-bezier(0.38, 0.1, 0.36, 0.9) forwards;

    &__row{
        flex: 1;
        display: flex;
        & p {
            margin-right: 11px;
            flex: 1;
        }

        & svg {
            width: 20px;
            height: 20px;
            cursor: pointer;
        }

        margin-bottom: 12px;
    }

    @keyframes anvil {
        0% {
            transform: scale(1) translateY(0px);
            opacity: 0;
        }

        1% {
            transform: scale(0.96) translateY(-10px);
            opacity: 0;
        }

        100% {
            transform: scale(1) translateY(0px);
            opacity: 1;
        }
    }
}