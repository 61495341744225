@import '../../styles/main.scss';

.uploadPdfModal {
    width: 700px;

    &__title {
        @include desktop_h3;
        color: $darkBlue;
        margin-bottom: 20px;
    }


    &__uploader {
        height: 100px;
        width: 100%;
        background-color: rgba(253, 187, 18, 0.1);
        border: 1px dashed #FDBB12;
        border-radius: 7px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        color: #FDBB12;
        flex-direction: column;
        @include text_small_bold;
        cursor: pointer;
        position: relative;
        margin: 15px 0;



        &Trash {
            position: absolute;
            top: 12px;
            right: 15px;
            cursor: pointer;
        }

        &Icon {
            & svg {
                width: 24px;
                height: 24px;
                margin-bottom: 5px;
            }
        }


        &Loader {
            display: flex;
            justify-content: center;
            align-items: center;
            animation: rotation 1.5s infinite linear;

            & svg {
                width: 40px;
                height: 40px;
            }


            @keyframes rotation {
                from {
                    transform: rotate(0deg);
                }

                to {
                    transform: rotate(359deg);
                }
            }

        }

        &Name {
            text-overflow: ellipsis;
            white-space: nowrap;
            overflow: hidden;
            width: 80%;
            text-align: center;
            margin: 0 auto;
        }

        &.active {
            background-color: rgba(58, 108, 255, 0.1);
            border: 1px solid #3A6CFF;
            color: $blue;
            cursor: default;

            & a {
                color: $blue;
            }
        }
    }

    &__buttons {
        display: flex;

        &> :first-child {
            margin-right: 10px;
        }

        &>* {
            width: 100%;
        }
    }

    @media (max-width: 790px) {
        width: calc(100vw - 90px);

        &__title {
            margin-top: 5px;
        }
    }
}