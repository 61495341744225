@import '../../../../../styles/main.scss';

.container {
    width: 502px;
    height: 100vh;
    background-color: var(--white);
    position: fixed;
    right: 0;
    border-left: 1px solid $gray;
    padding: 36px 15px;

    .button {
        margin-bottom: 24px
    }

    .title {
        @include desktop_h4;
        margin-bottom: 16px;
    }

    .block {
        margin-bottom: 24px;
    }

    .row {
        display: flex;
        flex-direction: row;
        row-gap: 16px;
        column-gap: 16px;
        flex-wrap: wrap;
    }

    .addButton {
        width: 151px;
        max-height: 38px;
    }

    .diagnosisInput{
        max-width: 343px;


        & > :first-child{
            max-height: 38px;
           

            & input{
                padding-right: 58px;
            }

        }

        &__button{
            padding: 0 15px 0 10px;
            cursor: pointer;
            color: $mainGreen;
            @include text_small_bold;
            position: absolute;
            z-index: 200;
            right: 0;
            top: 0;
            height: 38px;
            display: flex;
            justify-content: center;
            align-items: center;

            &.disable{
                color: $mediumGray;
                cursor: default;
            }
        }
    }

    @media (max-width: 1266px) {
        width: 410px;
    }

    @media (max-width: 1024px) {
        width: 326px;
    }

    @media (max-width: 900px) {
        width: 267px;
    }

    @media (max-width: 690px) {
        display: none;
    }
}