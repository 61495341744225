@import '../../../../styles/main.scss';

.organizationsManagement {
    display: flex;
    flex-direction: column;
    flex: 1;
    max-height: 100%;

    &__inviteButton {
        width: 245px;
        height: 44px;
        border-radius: 7px;
        background-color: #29B473;
        display: flex;
        justify-content: center;
        align-items: center;
        @include text_small_bold;
        color: var(--white);

        &>svg {
            margin-right: 10px;
        }

        & p::before {
            content: 'Create New Organization';
        }
    }

    &__search {
        margin-bottom: 25px;
    }

    &__organization {
        flex: 1;
    }

    &__members {
        flex: 0.25;
    }

    &__date {
        flex: 0.4;
    }

    &__actions {
        flex: 0.3;
    }


    &__modal {
        padding: 0;
        top: 64px;
        padding-bottom: 30px;

        &Block {
            padding: 36px;
            width: 820px;
        }
    }

    @media (max-width: 1180px) {
        &__organization {
            flex: 0.6;
        }

        &__date {
            display: none;
        }
    }

    @media (max-width: 966px) {
        &__modal {
            &Content {
                width: 87vw;
                max-width: 87vw;
            }

            &Block {
                padding: 36px;
                width: 100%;
            }
        }
    }

    @media (max-width: 767px) {
        &__search {
            margin-bottom: 20px;
            margin-top: 3px;
        }
    }

    @media (max-width: 600px) {
        &__organization {
            flex: 0.5;
        }

        &__members {
            flex: 0.2;
        }

        &__actions {
            flex: 0.25;
        }

        &__modal {
            top: 0;
            padding: 0;

            &Content {
                width: 100vw;
                max-width: 100vw;
                min-height: 100vh;
                height: fit-content;

            }

            &Block {
                padding: 0;

            }
        }

        &__inviteButton {
            height: 28px;
            width: 120px;

            &>p::before {
                content: 'Create new';
            }
        }
    }

    @media (max-width: 445px) {
        &__organization {
            flex: 1;
        }

        &__members {
            display: none;
        }
    }
}