@import '../../../../../../styles/main.scss';

.footer{
    height: 52px;
    display: flex;
    // justify-content: center;
    background-color: #FFFFFF;
    overflow-x: scroll;
    max-width: calc(100vw - 500px);
    overflow-x: auto;
    margin: auto;

    &__underline{
        height: 1px;
        width: 100%;
        background-color:#DBDEE7 ;
    }



    &__add{
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        padding: 0 15px;
    }

    &__pdfTab{
        height: 100%;
        padding: 0 16px;
        background-color: rgba(253, 187, 18, 0.1);
        display: flex;
        align-items: center;
        justify-content: center;
        @include text_small_bold;
        color: $orange;
        width: max-content;
        cursor: pointer;
        white-space: nowrap;


        &.active{
            background-color: #29b4731a;
            color: $mainGreen;
        }

        &Plus{
            display: flex;
            align-items: center;
            justify-content: center; 
            margin: 0 1.5px;
        }

        &Cross{
            display: flex;
            align-items: center;
            justify-content: center;
            margin-left: 10px;
            cursor: pointer;
        }
    }
}