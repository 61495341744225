@import '../../../../styles/main.scss';

.editProfile {
    flex: 1;

    &__dashboardHeader {
        display: flex;
        align-items: center;
        padding: 21px 0;

        &Arrow {
            display: flex;
            align-items: center;
            justify-content: center;
            cursor: pointer;
            margin-right: 20px;
        }

        &Name {
            @include text_small_bold;
            color: $mediumGray;
        }

        &Title {
            @include desktop_h2;
            color: $darkBlue;
        }
    }

    &__content {
        overflow-y: auto;
        height: 100%;
    }

    &__title {
        @include desktop_h4;
        margin-bottom: 10px;
    }

    &__subtitle {
        @include text_small_regular;
        color: $darkGray;
        margin: 10px 0 25px;
    }

    &__picker {
        display: inline-block;
        margin: 25px 0 30px;
        cursor: pointer;

        &Avatar {
            width: 140px;
            height: 140px;
            border-radius: 100px;
            border: 3px dashed $gray;
            display: flex;
            justify-content: center;
            align-items: center;
            overflow: hidden;

            &.exist {
                border: 0;
            }

            &>img {
                width: 100%;
                height: 100%;
            }
        }

        &>p {
            @include text_x_small_regular;
            color: $mediumGray;
            margin-top: 10px;
            text-align: center;
        }
    }

    &__input {
        margin-bottom: 20px;
        max-width: 420px;

        &>p {
            @include text_x_small_regular;
            color: $darkGray;
            margin-top: 5px;
        }
    }

    &__buttons {
        display: flex;
        margin-top: 25px;

        &>* {
            width: 83px;
            max-width: 83px;
        }

        &> :first-child {
            margin-right: 10px;
        }
    }

    &__underline {
        height: 1px;
        background-color: $gray;
        margin: 25px 0;
    }

    &__accounts {
        display: flex;
        margin: 25px 0 0;

        &Input {
            width: 420px;
            margin-right: 30px
        }

        &Title {
            @include text_small_bold;
            color: $darkGray;
            margin-bottom: 5px;
        }

        &Buttons> :nth-child(2) {
            margin-bottom: 10px;
        }

        &Button {
            width: 260px;
            height: 44px;
            border-radius: 7px;
            display: flex;
            align-items: center;
            justify-content: center;
            box-shadow: 0px 3px 4px rgba(24, 24, 28, 0.05);
            border: 1px solid $mainGreen;
            background: rgba(41, 180, 115, 0.1);
            @include text_small_bold;


            &Content {
                display: flex;
                justify-content: center;
                align-items: center;
            }

            &Logo {
                display: flex;
                justify-content: center;
                align-items: center;
                margin-right: 10px
            }
        }
    }

    &__deleteButton {
        max-width: 155px;
    }

    & .redColor {
        color: $red;
    }


    @media (max-width: 900px) {
        &__dashboardHeader {
            padding: 23px 0;

            &Name{
                @include desktop_h4;
                color: $darkBlue;
            }

            &Title{
                display: none;
            }
        }

        &__picker {
            &Avatar {
                width: 80px;
                height: 80px;
            }
        }

        &__subtitle {
            @include text_x_small_regular;
        }

    }

    @media (max-width: 767px) {
        &__picker {
            margin: 20px 0;
        }

        &__buttons {
            margin-top: 20px;

            &>* {
                max-width: 73px;
            }

            & button {
                max-width: 73px;
                max-height: 36px;
                height: 36px;
            }
        }

        &__underline {
            margin: 20px 0;
        }

        &__subtitle {
            margin: 10px 0 20px;
        }
    }

    @media (max-width: 600px) {
        &__dashboardHeader {
            padding: 22px 0;
        }
    }
}