@import '../../../../../../styles/main.scss';

.myOrganization {
    display: flex;
    height: 64px;
    align-items: center;
    flex: 1;
    @include text_small_regular;
    color: $darkGray;
    text-align: left;
    padding: 0 10px;
    position: relative;

    &:hover {
        background-color: var(--hoverColor);
    }

    &__search {
        margin: 25px 0;
    }

    &__organization {
        display: flex;
        align-items: center;
        flex: 2.7;
        @include text_small_bold;
        color: var(--black);

        &Avatar {
            width: 40px;
            height: 40px;
            border-radius: 7px;
            background-color: $gray;
            margin-right: 10px;
            overflow: hidden;

            &>img {
                width: 100%;
                height: 100%;
            }
        }
    }

    &__members {
        display: flex;
        align-items: center;
        flex: 1;
        height: 100%;
        text-align: left;


        &Counter {
            margin-left: 8px;
        }
    }

    &__date {
        flex: 1.2;
    }

    &__role {
        flex: 2;
        display: flex;
        align-items: center;
        justify-content: space-between;
        @include text_small_regular;

        &Right {
            display: flex;
            align-items: center;

        }

        &Button {
            width: 74px;
            max-width: 74px;
            margin-right: 20px;

            button {
                height: 36px;
                max-height: 36px;
            }

            ;
        }

        svg {
            cursor: pointer;
        }
    }

    &__underline {
        height: 1px;
        width: 100%;
        background-color: $gray;
    }

    &__modal {
        width: 200px;
        border: 1px solid var(--lightGray);
        border: 0;
        box-shadow: 0px 5px 60px rgba(0, 0, 0, 0.1);
        border-radius: 8px;
        transition: all 0.1s;
        z-index: 1;
        padding: 10px;
        background-color: var(--white);
        -webkit-animation: anvil 0.15s cubic-bezier(0.38, 0.1, 0.36, 0.9) forwards;

        @keyframes anvil {
            0% {
                transform: scale(1) translateY(0px);
                opacity: 0;
            }

            1% {
                transform: scale(0.96) translateY(0px);
                opacity: 0;
            }

            100% {
                transform: scale(1) translateY(0px);
                opacity: 1;
            }
        }


        &Item {
            height: 32px;
            padding-left: 10px;
            display: flex;
            align-items: center;
            border-radius: 7px;
            @include text_small_regular;
            color: var(--black);
            cursor: pointer;

            &:hover {
                background-color: var(--lightGray);
            }

            &.margin {
                margin: 2px 0;
            }

            svg {
                margin: 0 10px;
            }
        }
    }


    @media (max-width: 1200px) {
        &__date {
            display: none;
        }
    }

    @media (max-width: 919px) {
        &__members {
            display: none;
        }
    }

    @media (max-width: 900px) {

        &__organization {

            &Avatar {
                width: 24px;
                height: 24px;
                min-width: 24px;
            }
        }

        &__role {


            &Button {
                margin: 0 12px;
                width: 53px;

                button {
                    max-width: 53px;
                    height: 28px;
                    max-height: 28px;
                }

                ;
            }

        }
    }

    @media (max-width: 767px) {
        &__role {
            @include text_x_small_regular;

            &Button {
                margin: 0 5px;
            }
        }

    }

    @media (max-width: 469px) {

        &__organization {
            flex: 2.3;
            padding-right: 5px;

            &Avatar {
                width: 24px;
                height: 24px;
                min-width: 24px;

            }
        }
    }
}