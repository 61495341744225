@import '../../../../../../styles/main.scss';

.section {
    width: 100%;
    min-height: 100px;
    border-radius: 7px;
    border: 1px solid #29B473;
    padding: 15px;
    cursor: pointer;
    background-color: var(--white);
    display: flex;
    flex-direction: column;

    &__header{
        display: flex;
        justify-content: space-between;
        align-items: center;
        & input{
            min-height: 24px;
        }
    }

    &__checkbox{
    }

    &__loader{
        width: 100%;
        height: 100px;
        border-radius: 7px;
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: $green10;
        margin-bottom: 8px;

        svg {
            animation: rotation 1.5s infinite linear;
        }


        @keyframes rotation {
            from {
                transform: rotate(0deg);
            }

            to {
                transform: rotate(359deg);
            }
        }

    }
   

    &__wrapper{
        padding-bottom: 10px;
    }

    &.active {
        border: 1px solid #3A6CFF;
        box-shadow: 0px 0px 0px 3px rgba(58, 108, 255, 0.2);
        border-radius: 7px;
    }

    &.draggingOver{
        background-color: rgba(41, 180, 115, 0.05);
    }

    &__name {
        margin-bottom: 5px;
      

        & input {
            @include desktop_h2;
            border: none;
            outline: none;
            resize: none;
            min-width: 200px;
            max-width: 500px;
            background-color: transparent;
            color: var(--black);

            &::placeholder {
                color: $gray;
            }
        }
    }

    &__text {

        & input {
            @include text_small_bold;
            border: none;
            outline: none;
            resize: none;
            min-width: 200px;
            max-width: 500px;
            background-color: transparent;

            &::placeholder {
                color: $gray;
            }
        }
    }


    &__collapseArrow{
        display: inline;
        width: 24px;
        height: 22px;
        // margin-left: 15px;
        
        &.rotate{
            transform: rotate(180deg);
        }
    }

    & .footer{
        flex-grow: 1;

        &__noData{
            @include text_small_regular;
            color: $mediumGray;
            display: flex;
            justify-content: center;
            align-items: center;
            flex: 1;
            height: 40px;
        }
    }

    &__footer{
        display: flex;
        align-items: center;
        margin-top: 11px;
        width: 100%;
        justify-content: flex-end;
        position: relative;

        &Button{
            width: 136px;
        }

        &Buttons {
            display: flex;
            justify-content: center;
            align-items: center;
            height: 36px;
            border: 1px solid #DBDEE7;
            box-shadow: 0px 3px 4px rgba(24, 24, 28, 0.05);
            border-radius: 7px;
            margin-left: 10px;

            & > * {
                width: 36px;
                height: 36px;
                display: flex;
                justify-content: center;
                align-items: center;
                // width: 36px;
            }

            & > :first-child{
                transform: rotate(90deg);
            }

            & > :nth-child(3){
                transform: rotate(270deg);

            }

            &Line{
                width: 1px;
                height: 100%;
                background-color: #DBDEE7;
            }

            &>svg {
                transform: rotate(90deg);
            }
        }

        &Dots {
            display: flex;
            justify-content: center;
            align-items: center;
            width: 36px;
            height: 36px;
            border: 1px solid #DBDEE7;
            box-shadow: 0px 3px 4px rgba(24, 24, 28, 0.05);
            border-radius: 7px;
            margin-left: 15px;

            &>svg {
                transform: rotate(90deg);
            }
        }

        &Popup {
            position: absolute;
            right: 0;
            top: 0;
            overflow: hidden;
            height: 0;
            width: 0;
            padding: 0px;
            background-color: var(--white);
            border: 1px solid #F5F5F7;
            box-shadow: 0px 5px 60px rgba(0, 0, 0, 0.1);
            border-radius: 8px;
            transition: all 0.1s;

            &.active{
                width: 130px; 
                height: auto;
                padding: 10px;
            }


            &Item {
                padding-left: 10px;
                height: 32px;
                border-radius: 7px;
                display: flex;
                align-items: center;
                cursor: pointer;
                @include text_small_regular;

                &> :first-child {
                    margin-right: 10px;
                }

                &:hover {
                    background-color: var(--lightGray);
                }
            }
        }

        
    }



}