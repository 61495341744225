@import "../../../../styles/main.scss";

.pdfItem {
  display: flex;
  min-height: 137px;
  box-shadow: 0px 3px 4px rgba(24, 24, 28, 0.05);
  border: 1px solid #dbdee7;
  background-color: #ffffff;
  border-radius: 7px;
  padding: 18px 20px 18px;

  &:hover {
    background-color: var(--hoverColor);
  }

  &__dark {
    background-color: var(--lightGray);
    border: none;
  }

  &__right {
    display: flex;
    flex-direction: column;
    margin-left: 15px;
    justify-content: space-between;
    width: 100%;

    & > p {
      @include desktop_h4;
      margin-bottom: 4px;
    }

    & button {
      max-width: 92px;
      max-height: 36px;
    }
  }

  @media (max-width: 900px) {
    min-height: 119px;
    padding: 18px 10px 18px;

    &__right {
      margin-left: 10px;

      & > p {
        @include text_x_small_bold;
      }
    }
  }
}
