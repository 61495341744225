@import '../../../../../styles/main.scss';

.container {
    display: flex;
    flex-direction: column;
    flex: 1;
    max-height: calc(100vh - 128px);
    padding: 0 100px;

    &__messages {
        flex: 1;
        overflow-y: auto;
        margin-top: 20px
    }


    .loader {
        display: flex;
        justify-content: center;
        align-items: center;
        flex: 1;


        & svg {
            animation: rotation 1.5s infinite linear;
            width: 40px;
            height: 40px;

        }

        @keyframes rotation {
            from {
                transform: rotate(0deg);
            }

            to {
                transform: rotate(359deg);
            }
        }
    }


    &__footer {
        &Example {
            @include text_small_regular;
            color: $darkGray;
            margin: 10px 0;

            & span {
                @include text_small_bold;
            }
        }

        &Input {
            display: flex;
            align-items: center;


            input {
                height: 48px;
                width: 100%;
                border: 1px solid #DBDEE7;
                box-shadow: 0px 3px 4px rgba(24, 24, 28, 0.05);
                overflow: hidden;
                border-radius: 7px;
                @include text_small_bold;

                outline: none;
                padding: 15px;

                &::placeholder {
                    color: $mediumGray;
                }

                &:focus {
                    box-shadow: 0px 0px 0px 3px rgba(58, 108, 255, 0.2);
                    border: 1px solid $blue;
                }
            }

            & p {
                padding: 0 10px;
                @include text_small_bold;
                cursor: pointer
            }

            & .inactive {
                cursor: default;
                color: $gray;
            }

            & svg {
                margin: 0 15px;
                animation: rotation 1.5s infinite linear;
            }

            @keyframes rotation {
                from {
                    transform: rotate(0deg);
                }

                to {
                    transform: rotate(359deg);
                }
            }
        }

        &Warning {
            @include text_small_regular;
            color: $darkGray;
            margin: 10px 0 34px;

            & span {
                color: $red;
                @include text_small_bold;
            }
        }
    }

    @media (max-width: 900px) {
        padding: 0 17px;
    }

    @media (max-width: 716px) {
        max-height: calc(100vh - 172px);

        // &__messages {
        //     flex: auto;
        //     min-height: 300px;
        // }
    }
}