@import '../../../../../../../../styles/main.scss';

.textboxSettings{
    &__underline{
        height: 1px;
        width: 100%;
        background-color: $gray;
        margin: 10px 0 15px;
    }

    &__title{
        @include desktop_h2;
        margin-bottom: 25px;
    }

    &__subtitle{
        @include text_small_bold;
        margin: 15px 0 10px;
    }

    &__subtitleHeader{
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;

        & > :last-child{
           & button{
            min-width: 100px;
            max-height: 32px;
           } 
        }
    }

    &__template{
        margin: 15px 0;
    }

    &__item{
        margin-bottom: 20px;

        &Header{
            display: flex;
            align-items: center;
            margin: 10px 0;
    
            & > :last-child{
                display: flex;
                justify-content: center;
                align-items: center;
                cursor: pointer;
                margin-left: 10px;
            }
        }
    }

    &__addButton{
        margin-top: 15px;
        & button{
            max-width: 180px;
        }
    }

    &__modal{
        width: 100%;
        height: 100%;
        position: relative;

        &Content{
            display: flex;
            flex-direction: column;
            height: 100%;
            padding-top: 20px;
        }
    }

    &__modalContainer{
        width: 90%;
        height: 90%;
        top: 5%;
        display: flex;flex-direction: column;
    }
}