@import '../../styles/main.scss';

.counter {
    width: 150px;
    min-width: 150px;
    height: 44px;
    border-radius: 7px;
    border: 1px solid $gray;
    box-shadow: 0px 3px 4px rgba(24, 24, 28, 0.05);
    background: #FFFFFF;
    padding: 0 4px;
    display: flex;
    justify-content: space-between;
    align-items: center;

    &__input{
        width: 100%;
        height: 100%;
        border: 0;
        outline: none;
        text-align: center;
        @include text_small_regular;
        color: var(--black);

        &.disable{
            color: $gray;
        }
    }

    &__button{
        width: 36px;
        height: 36px;
        min-width: 36px;
        border-radius: 4px;
        background-color: $gray;
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;


        &Disable{
            cursor: default;
        }
    }


}