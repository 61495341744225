@import '../../../../../../../../styles/main.scss';

.dropdownData {
    flex: 1;
    padding: 10px 0;

    &__selector{
        margin-bottom: 10px;
    }

}