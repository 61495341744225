@import '../../styles/main.scss';

.leaveOwnerModal {

    &__container {
        padding-bottom: 30px;
    }

    &__first {
        width: 542px;

        &Buttons {
            display: flex;
            flex-direction: row;
            justify-content: space-between;

            &> :first-child {
                margin-right: 10px
            }

            &>* {
                flex: 1;
            }
        }
    }

    &__second {
        width: 700px;

        &Buttons {
            display: flex;
            flex-direction: row;
            justify-content: flex-end;
            margin-top: 70px;

            &> :first-child {
                margin-right: 10px
            }

            &>* {
                // flex: 0.3;
                width: 200px;
            }
        }
    }


    &>svg {
        margin-bottom: 10px
    }

    &__title {
        @include desktop_h4;
    }

    &__subtitle {
        @include text_small_regular;
        color: $darkGray;
        margin: 5px 0 20px;
    }

    &__row {
        display: flex;
        margin-bottom: 10px;

        &Title {
            @include text_small_bold;
            display: none;
        }

        &>* {
            width: auto;
            flex: 1;
        }

        &> :first-child {
            margin-right: 20px;
        }
    }

    &__input {
        margin: 15px 0 20px;
    }


    @media (max-width: 790px) {
        &__second {
            width: calc(100vw - 90px);
        }

    }

    @media (max-width: 620px) {
        &__first {
            width: calc(100vw - 90px);
        }
    }

    @media (max-width: 600px) {
        &__second {
            &Buttons {
                margin-top: 20px;

                & button {
                    max-height: 35px;
                }
            }

        }

        &__row {
            flex-direction: column;

            &Name {
                display: none;
            }

            &Email {
                & .label {
                    display: none;
                }
            }

            &Title {
                display: block;
                margin-bottom: 5px;
            }
        }
    }

    @media (max-width: 425px) {
        &__first {
            &Buttons {
                &> :first-child {
                    margin: 0 0 10px;
                }

                flex-direction: column;
            }
        }
    }
}