@import '../../../../styles/main.scss';

.draftItem {
    position: relative;

    & .content {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        min-height: 137px;
        box-shadow: 0px 3px 4px rgba(24, 24, 28, 0.05);
        border: 1px solid #DBDEE7;
        background-color: #FFFFFF;
        border-radius: 7px;
        padding: 18px 20px 12px;

        &Dark {
            background-color: var(--lightGray);
            border: none;
        }

        &:hover {
            background-color: var(--hoverColor);
        }


        &__title {
            // flex: 1;
            word-break: break-all;
            @include desktop_h4;
        }

        &__author{
            @include text_small_regular;
            color: $darkGray;
            margin-top: 5px;
        }


        &__button{
            margin-top: 5px;
            width: 100%;
            min-height: 36px;
            border-radius: 7px;
            padding: 8px 10px;
            background-color: $mainGreen;
            color: $white;
            display: flex;
            justify-content: center;
            align-items: center;
            @include text_small_bold;
            cursor: pointer;

            & svg {
                animation: rotation 1.5s infinite linear;

            }



            @keyframes rotation {
                from {
                    transform: rotate(0deg);
                }

                to {
                    transform: rotate(359deg);
                }
            }
        }
  
    }



    @media (max-width: 900px) {

        & .content {
            min-height: 105px;
            padding: 18px 10px 12px;

            &__title {
                @include text_x_small_bold;
            }

            &__author{
                @include text_x_small_regular;
            }

 
        }
    }

    @media (max-width: 810px) {
        & .content {
          
            &__button{
                @include text_x_small_bold;
                text-align: center;
                margin-top: 15px;

            }
 
        }
    }

    @media (max-width: 390px) {
        & .content {

        }
    }
}