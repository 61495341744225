@import '../../../../../../../../../styles/main.scss';

.calculator {
    flex: 1;
    padding: 10px 0;

    &__field{
        margin-bottom: 10px;
    }

    &__noData {
        @include text_small_regular;
        color: $mediumGray;
        display: flex;
        justify-content: center;
        align-items: center;
        padding-top: 7px;
        flex: 1;
    }
}