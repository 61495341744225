@import '../../../../styles/main.scss';


.allOrganization {
    overflow: hidden;
    overflow-y: auto;
    height: 100%;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    grid-column-gap: 15px;
    grid-row-gap: 15px;
    flex: 1;

    // &::-webkit-scrollbar {
    //     display: none;
    // }

    &__search {
        margin: 25px 0;
        display: flex;
        align-items: center;
        gap: 15px
    }


    @media (max-width: 1440px) {
        grid-template-columns: 1fr 1fr 1fr;
    }


    @media (max-width: 968px) {
        grid-template-columns: 1fr 1fr;
    }

    @media (max-width: 767px) {
        &__search {
            margin: 20px 0;
        }
    }
}