@import '../../../../styles/main.scss';

.modal {
    width: 542px;

    &__title {
        @include desktop_h4;
        text-align: center;
        margin-bottom: 20px;
    }

    &__buttons {
        display: flex;
        align-items: center;

        &> :first-child {
            margin-right: 10px;
        }

        &>* {
            flex: 1;
        }

    }

    @media (max-width: 630px) {
        width: calc(100vw - 90px);

        &__title {
            margin-top: 5px;
        }
    }
}