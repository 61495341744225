@import '../../../../../../../../styles/main.scss';

.tab {
    display: flex;
    width: max-content;
    height: 100%;
    padding: 0 6px 0 15px;
    @include text_small_bold;
    color: $mainGreen;
    border-right: 1px solid var(--lightGray);
    align-items: center;
    background-color: var(--white);
    position: relative;

    

    &.editable{
        padding: 0 9px;
    }

    &__wrapper{
        height: 100%;
        background-color: #FFFFFF;
        cursor: pointer;
    }

    &.active{
        background-color: #29b4731a;
    }

    &__exclamation{
        display: flex;
        justify-content: center;
        align-items: center;

        & > svg{
            // width: 18px;
            // height: 18px;
            margin-left: 8px;
        }
    }


    &__dots {
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
        margin-left: 5px;
    }

    &__modal {
        height: max-content;
        border-radius: 8px;
        width: 177px;
        box-shadow: 0px 5px 60px rgba(0, 0, 0, 0.1);
        border: 1px solid #F5F5F7;
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 10px 0 8px;
        background-color: var(--white);

        &Underline{
            height: 1px;
            background-color: $gray;
            width: 100%;
            margin: 7px;
        }

        @keyframes anvil {
            0% {
                transform: scale(1) translateY(0px);
                opacity: 0;
            }
    
            1% {
                transform: scale(0.96) translateY(10px);
                opacity: 0;
            }
    
            100% {
                transform: scale(1) translateY(0px);
                opacity: 1;
            }
        }
        -webkit-animation: anvil 0.15s cubic-bezier(0.38, 0.1, 0.36, 0.9) forwards;
    
    }

    &__item {
        position: relative;
        width: 157px;
        height: 32px;
        display: flex;
        align-items: center;
        margin-bottom: 2px;
        cursor: pointer;
        padding-left: 10px;

        &.disable{
            cursor: default;
            color: $gray;

            &:hover{
                background-color: var(--white);
            }
        }

        &RightIcon{
            position: absolute;
            right: 5px;
            display: flex;
            justify-content: center;
            align-items: center;
            transform: rotate(270deg);
        }

        & > svg{
            margin-right: 10px;
        }

        &:hover{
            background-color: #FAFAFA;
        }
    }
    
    &__field{
        & > input{
            border: 1px solid $blue;
            box-shadow: 0px 0px 0px 3px rgba(58, 108, 255, 0.2);
            border-radius: 3px;
            height: 30px;
            padding: 0 6px;
            min-width: 64px;
            @include text_small_bold;
            outline: none;
            max-width: 150px;

            &::placeholder{
                color: $gray;
            }
        }
    }
}