@import '../../../../styles/main.scss';

.container {
    height: 100vh;
    width: 100vw;
    display: flex;
    flex-direction: column;

    .arrow{
        position: absolute;
        top: 42px;
        left: 37px;
        cursor: pointer;
    }

    .header {
        margin-top: 32px;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        margin-left: 100px;
        padding-right: 100px;
        flex-wrap: wrap;

        &__notepad {
            margin-right: 502px;
            padding-right: 32px;
        }

        &__logo {
            display: flex;
            align-items: baseline;
            gap: 15px;
        }

        &__logoText {
            @include desktop_h1;
            color: $darkBlue;
        }

        &__left {
            display: flex;
            flex-direction: row;
            align-items: center;
            margin-right: 40px;

            & img {
                width: 214px;
            }

            &Version {
                height: 24px;
                padding: 0 10px;
                background-color: #D8E2FF;
                border-radius: 10px;
                @include item_header_12;
                color: $blue;
                display: flex;
                justify-content: center;
                align-items: center;
                margin-left: 25px;
                min-width: max-content
            }
        }

        &__right {
            display: flex;
            flex-direction: row;
            align-items: center;

            &Completed {
                width: 141px;
            }

            &Refresh {
                width: 44px;
                min-width: 44px;
                height: 44px;
                border-radius: 7px;
                border: 1px solid #DBDEE7;
                background-color: var(--white);
                margin: 0 10px;
                display: flex;
                align-items: center;
                justify-content: center;
                cursor: pointer;

                &.loading {
                    svg {
                        animation: rotation 1.5s infinite linear;

                    }

                    @keyframes rotation {
                        from {
                            transform: rotate(0deg);
                        }

                        to {
                            transform: rotate(359deg);
                        }
                    }
                }

                &.disable {
                    cursor: default;
                }
            }

            &Delete {
                display: flex;
                justify-content: center;
                align-items: center;
                height: 44px;
                width: 44px;
                border-radius: 7px;
                background: rgba(239, 47, 50, 0.2);
                cursor: pointer;

                &Loader {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    animation: rotation 1.5s infinite linear;


                    @keyframes rotation {
                        from {
                            transform: rotate(0deg);
                        }

                        to {
                            transform: rotate(359deg);
                        }
                    }
                }

                &.disable {
                    cursor: default;
                }
            }
        }
    }

    .mainSwitcher {
        display: flex;
        min-height: 20px;
        width: 206px;
        align-self: flex-start;
        position: relative;
        margin-top: 20px;
        margin-left: 100px;


        &__button {
            padding: 0 16px;
            height: 32px;
            @include text_medium_bold;
            display: flex;
            justify-content: center;
            align-items: center;
            cursor: pointer;
            color: $darkGray;
            transition: color 0.2s;


            &.active {
                color: var(--black);
            }

            &Notepad {
                width: 112px;
            }

            &Chat {
                width: 79px;
                margin-left: 15px;
            }
        }

        &__indicator {
            position: absolute;
            z-index: 2;
            height: 2px;
            width: 79px;
            background-color: $mainGreen;
            left: 127px;
            bottom: 0;
            transition: all 0.2s;


            &Left {
                width: 112px;
                left: 0;
            }
        }
    }


    @media (max-width: 1266px) {

        .header {
            &__notepad {
                margin-right: 410px;
                padding-right: 17px;
            }
        }
    }

    @media (max-width: 1125px) {
        .arrow{
            top: 32px
        }

        .header {
            &__notepad {
                align-items: flex-start;
                flex-direction: column;
            }

            &__rightNotepad {
                margin-top: 20px;

                & button {
                    min-height: 40px;
                    height: 40px;
                }


                &Refresh {
                    width: 40px;
                    min-width: 40px;
                    height: 40px;
                }

                &Delete {
                    width: 40px;
                    min-width: 40px;
                    height: 40px;
                }
            }
        }
    }

    @media (max-width: 1024px) {

        .header {
            &__notepad {
                margin-right: 326px;
            }
        }
    }

    @media (max-width: 900px) {

        .arrow{
            top: 32px;
            left: 18px;
        }

        .header {
            margin-left: 17px;
            margin-top: 72px;

            &__notepad {
                margin-right: 267px;
            }
        }

        .mainSwitcher {
            margin-left: 17px;
        }
    }

    @media (max-width: 716px) {

        .header {
            align-items: flex-start;
            flex-direction: column;

            &__right {
                margin-top: 20px;

                & button {
                    min-height: 40px;
                    height: 40px;
                }


                &Refresh {
                    width: 40px;
                    min-width: 40px;
                    height: 40px;
                }

                &Delete {
                    width: 40px;
                    min-width: 40px;
                    height: 40px;
                }
            }
        }
    }
}