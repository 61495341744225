@import '../../../styles/main.scss';

.welcomePage {
    width: 1120px;
    display: flex;
    justify-content: space-between;
    align-items: center;


    @media (max-width: 968px) {
        flex-direction: column;
    }

    @media (max-width: 600px) {
        width: 100%;
    }

    @media (max-width: 1300px) {
        width: 100%;
    }

    &__content {
        width: 450px;



        @media (max-width: 1300px) {
            margin-right: 80px;
        }

        @media (max-width: 1115px) {
            margin-right: 40px;
            width: 350px;
        }

        @media (max-width: 968px) {
            order: 2;
            margin-right: 0;
        }

        @media (max-width: 600px) {
            width: 100%;
        }

    }

    &__image {
        width: 470px;
        height: 400px;
        background-color: #D9D9D9;
        border-radius: 10px;


        @media (max-width: 1300px) {
            width: 370px;
            height: 300px;
        }

        @media (max-width: 968px) {
            width: 191px;
            height: 179px;
            order: 1;
        }


    }

    &__header {
        @include desktop_h1;
        color: $darkBlue;
        text-align: left;

        @media (max-width: 968px) {
            @include desktop_h2;
            margin-top: 25px;

        }
    }

    &__subtitle {
        @include text_large_regular;
        color: $darkGray;
        text-align: left;
        margin: 10px 0 25px;

        @media (max-width: 968px) {
            @include text_medium_regular;
            margin-top: 15px
        }
    }

    &__buttons {
        display: flex;
        justify-content: space-between;

        & * {
            width: 220px;
        }

        @media (max-width: 1115px) {
            display: block;

            & * {
                width: 100%;
            }

            & :last-child {
                margin-top: 10px;
            }
        }
    }

    &__privacy {
        display: flex;
        text-align: left;
        @include text_x_small_regular;
        color: $mediumGray;
        margin-top: 25px;

        p {
            margin-left: 10px
        }

        span {
            @include text_x_small_regular;
            text-decoration: underline;
            color: $mainGreen;
            cursor: pointer;
        }
    }
}