@import '../../../../../../../../../styles/main.scss';

.metronome {

    &__cpr {
        margin: 10px 0;

        &> :first-child {
            margin-bottom: 5px;
        }

        &Row {
            display: flex;

            &>* {
                flex: 1;
            }

            &> :first-child {
                margin-right: 5px;
            }
            &Dark {
                .metronome__cprButton {
                    background-color: var(--lightGray);
                }
            }
        }

        &Button {
            display: flex;
            justify-content: center;
            align-items: center;
            height: 64px;
            background: #FFFFFF;
            border: 1px solid $gray;
            border-radius: 7px;
            @include text_small_bold;
            color: $mainGreen;
            text-align: center;

        }

    }

    &__ventilations {
        display: flex;
        margin: 10px 0;


        & > :nth-child(2) {
            margin: 0 30px;
        }


        &Column {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            flex: 1;

            &> :nth-child(2) {
                margin: 5px 0;
            }
        }

        &Button {
            display: flex;
            justify-content: center;
            align-items: center;
            height: 44px;
            border: 1px solid #DBDEE7;
            border-radius: 7px;
            @include text_small_bold;
            color: $mainGreen;
            width: 100%;
            text-align: center;
        }
    }

}