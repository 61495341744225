@import '../../../../../../../../styles/main.scss';

.algorithm {
    flex: 1;
    position: relative;
    padding: 10px 0;


    &__underline {
        width: 100%;
        height: 1px;
        background-color: $gray;
    }

    &__title {
        @include text_small_bold;
        color: var(--black);
        margin: 10px 0 5px;
    }

    &__description {
        @include text_small_regular;
        color: $darkGray;
        margin-bottom: 10px;
    }

    &__selection {
        min-height: 44px;
        border: 1px solid #DBDEE7;
        border-radius: 7px;
        padding: 2px 15px;
        display: flex;
        align-items: center;
        @include text_small_bold;
        color: $mainGreen;
        max-width: 100%;
        word-break: break-all;
        margin-bottom: 5px;
        cursor: pointer;

        &.active {
            background: rgba(41, 180, 115, 0.1);
            border: 1px solid #29B473;
        }

        &.disable{
            color: $gray;
            cursor: default;
        }

        &.defaultCursor{
            cursor: default;
        }

      
    }

    &__checkButton {
        margin-top: 15px;
    }

    &__result {
        @include text_small_regular;
        color: var(--black);
        margin-top: 15px;

        &>span {
            @include text_small_bold;
        }

    }
}