@import "../../styles/main.scss";

.calculator {
  background-color: var(--white);
  display: flex;
  align-items: center;
  @include text_small_bold;
  color: $blue;
  position: relative;
  cursor: pointer;
  height: 52px;
  min-height: 52px;
  padding-inline: 33px;

  &Shadow {
    border-radius: 8px;
    box-shadow: 0px 3px 4px 0px #18181c0d;
    height: 45px;
  }

  svg {
    margin-right: 8px;
  }

  &__counter {
    // position: absolute;
    margin-left: auto;
    margin-right: 5px;
    right: 16px;
  }

  &__noData {
    @include text_small_bold;
    color: $mediumGray;
    text-align: center;
    margin: 15px 0;
  }
}

.popup {
  width: 336px;
  border: 1px solid #efefef;
  box-shadow: 0px 5px 60px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  padding: 16px 12px;
  overflow-y: scroll;
  background-color: var(--white);
  -webkit-animation: anvil 0.15s cubic-bezier(0.38, 0.1, 0.36, 0.9) forwards;

  &__darkMode {
    width: 100%;
    background-color: var(--white);
    &:hover {
      background-color: var(--hoverColor);
    }
  }

  &__title {
    @include text_small_bold;
    text-align: center;
  }

  &__clearButton {
    width: 50%;
    &Container {
      width: 100%;
      display: flex;
      margin-bottom: 12px;
      justify-content: center;
    }
  }

  &__ageSelect {
    display: flex;
    flex-direction: row;
    gap: 16px;
    margin-top: 8px;
    margin-bottom: 12px;
  }
  &__gender {
    &Select {
      @include text_small_bold;
      display: flex;
      justify-content: center;
      gap: 12px;
    }
    &Checkbox {
      gap: 10px;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }

  &__body {
    display: flex;
    justify-content: space-between;
    gap: 12px;
    height: max-content;
    margin-top: 56px;
    &Divider {
      height: auto;
      width: 1px;
      background-color: #dbdee7;
      margin-block: 8px;
    }
  }

  &__button {
    width: 270px;
    height: 36px;
    margin: 5px auto;

    & button {
      max-width: 270px;
      max-height: 36px;
    }
  }

  &__item {
    display: flex;
    flex-direction: row;
    align-items: center;
    min-height: 40px;
    padding: 5px 5px 5px 17px;
    cursor: pointer;
    @include text_small_regular;

    &:hover {
      background-color: var(--hoverColor);
    }

    & > svg {
      margin-right: 12px;
    }

    &Underline {
      width: 100%;
      height: 1px;
      background-color: #efefef;
    }
  }

  @keyframes anvil {
    0% {
      transform: scale(1) translateY(0px);
      opacity: 0;
    }

    1% {
      transform: scale(0.96) translateY(10px);
      opacity: 0;
    }

    100% {
      transform: scale(1) translateY(0px);
      opacity: 1;
    }
  }
}
