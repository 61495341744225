@import '../../../../../../styles/main.scss';

.plusButton {
    width: 108px;
    position: relative;

    &__modal {
        height: 0;
        width: 0;
        overflow: hidden;
        box-shadow: 0px 5px 60px rgba(0, 0, 0, 0.1);
        border-radius: 8px;
        border: 1px solid var(--lightGray);
        position: absolute;
        background-color: var(--white);
        top: 0;
        right: 0;
        transition: all 0.1s;

        &.active {
            width: 287px;
            height: 170px;
            padding: 10px;
        }

        &Item {
            display: flex;
            align-items: center;
            height: 32px;
            border-radius: 7px;
            cursor: pointer;
            @include text_small_regular;

            svg {
                margin: 0 10px
            }

            &:hover {
                background-color: var(--lightGray);
            }

            &Loader {

                position: absolute;
                right: 10px;
                display: flex;
                justify-content: center;
                align-items: center;
                animation: rotation 1.5s infinite linear;

                @keyframes rotation {
                    from {
                        transform: rotate(0deg);
                    }

                    to {
                        transform: rotate(359deg);
                    }
                }
            }
        }

        &Underline {
            width: 100%;
            height: 1px;
            background-color: $gray;
            margin: 7px 0;
        }

        &>.marginBottom {
            margin-bottom: 3px;
        }
    }

    @media (max-width: 600px) {
       width: 80px;
       height: 28px;
       max-height: 28px;
       & button{
        max-height: 28px;
        color: red
       }
    }
}