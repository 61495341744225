@import '../../styles/main.scss';

.dashboardLayout {
    background-color: var(--lightGray);
    display: flex;
    flex-direction: column;
    height: 100%;

    &__organization {
        width: 238px;
        display: none;
        background-color: var(--white);
        align-items: center;
        padding: 16px 6px;
        border-radius: 7px;
        box-shadow: 0px 3px 4px rgba(24, 24, 28, 0.05);
        cursor: pointer;
        margin-left: 20px;
        margin-top: 16px;


        &Arrow {
            display: flex;
            justify-content: center;
            align-items: center;
            transform: rotate(90deg);
        }

        &Logo {
            width: 32px;
            min-width: 32px;
            height: 32px;
            border-radius: 7px;
            overflow: hidden;
            margin-right: 16px;

            &>img {
                width: 100%;
                height: 100%;
            }
        }

        &Title {
            @include text_small_bold;

        }

        &Subtitle {
            @include text_x_small_regular;
            color: $darkGray;
        }

        &Empty {
            @include text_x_small_regular;
            color: $mediumGray;
        }
    }


    &__header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 0 32px;
        position: relative;
        z-index: 1;
    }

    &__title {
        @include desktop_h2;
        color: var(--dakBlue);
        margin: 31px 0;
    }

    &__content {
        display: flex;
        flex-direction: column;
        background-color: var(--white);
        box-shadow: 0px 3px 4px rgba(24, 24, 28, 0.05);
        border-radius: 7px 0px 0px 0px;
        padding: 32px;
        overflow: hidden;
        flex: 1;
        width: 100%;
    }


    @media (max-width: 900px) {
        &__title {
            @include desktop_h4;
            margin: 23px 0;
        }
    }


    @media (max-width: 767px) {
        background-color: #FAFAFA;
        height: auto;
        min-height: 100%;

        &__organization {
            display: flex
        }

        &__content {
            background-color: #FAFAFA;
            padding: 0 20px 10px;
        }
    }

    @media (max-width: 600px) {
        &__title {
            margin: 22px 0;
        }
    }
}