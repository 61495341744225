@import "../../../../styles/main.scss";

.completedChecklists {
  display: flex;
  flex-direction: column;
  flex: 1;
  max-height: 100%;

  &__loader {
    flex: 1;
    max-height: 100%;
  }

  & > :last-child {
    margin-top: 10px;
  }

  &__name {
    flex: 1;
  }

  &__member {
    flex: 0.7;
  }

  &__date {
    flex: 0.9;
  }

  &__search {
    margin-bottom: 25px;
    display: flex;
    justify-content: space-between;
    align-items: center;

    &Right {
      display: flex;
      justify-content: center;
      align-items: center;
      margin-left: 30px;

      & > :first-child {
        margin-right: 10px;
      }
    }

    &Button {
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;
    }
  }

  &__title {
    @include desktop_h4;
    color: $mediumGray;
    margin-bottom: 10px;
  }

  &__content {
    flex: 1;
    padding-bottom: 25px;
    overflow: hidden;
  }

  &__grid {
    &Wrapper {
      flex: 1;
      overflow-y: auto;

      // &::-webkit-scrollbar {
      //     display: none;
      // }
    }

    padding-top: 5px;

    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;

    grid-column-gap: 15px;
    grid-row-gap: 15px;
  }

  @media (max-width: 1440px) {
    &__grid {
      grid-template-columns: 1fr 1fr 1fr;
    }
  }

  @media (max-width: 1150px) {
    &__grid {
      grid-template-columns: 1fr 1fr;
    }

    &__date {
      display: none;
    }

    &__member {
      flex: 0.8;
    }
  }

  @media (max-width: 900px) {
    &__grid {
      grid-column-gap: 5px;
      grid-row-gap: 5px;
    }
  }

  @media (max-width: 767px) {
    &__search {
      margin-bottom: 20px;
    }
  }
}

.paginationLoader {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;

  & svg {
    animation: rotation 1.5s infinite linear;
  }

  @keyframes rotation {
    from {
      transform: rotate(0deg);
    }

    to {
      transform: rotate(359deg);
    }
  }
}

.pagination {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 25px;

  &__left {
    @include text_small_regular;
    color: $darkGray;

    span {
      @include text_small_bold;
      color: var(--black);
    }
  }
}
