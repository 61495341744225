@import '../../styles/main.scss';

.inputContainer {
    width: 100%;
    position: relative;

    &__label {
        color: $darkGray;
        @include text_small_bold;
        margin: 0;
        text-align: left;
        margin-bottom: 5px;
    }

    &__right{
        padding:0 10px;
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
    }

    &__limited{
        display: flex;
        justify-content: flex-end;
        align-items: center;
        @include text_small_regular;
        color: $mediumGray;
        padding: 15px;
    }

    &__error{
        position: absolute;
        bottom: -19px;
        @include text_x_small_regular;
        color: $red;
    }
}

.inputWrapper {
    display: flex;
    flex-direction: row;
    height: 44px;
    border: 1px solid #DBDEE7;
    box-shadow: 0px 3px 4px rgba(24, 24, 28, 0.05);
    overflow: hidden;
    border-radius: 7px;
    align-items: center;


    input {
        width: 100%;
        border: none;
        outline: none;
        padding: 15px;

        &::placeholder{
            color: $mediumGray;
            @include text_small_regular;
        }
    }

    &:hover {
        cursor: text;
    }

    &.focus {
        box-shadow: 0px 0px 0px 3px rgba(58, 108, 255, 0.2);
        border: 1px solid $blue;
    }

    &.error {
        box-shadow: 0px 0px 0px 3px rgba(239, 47, 50, 0.2);
        border: 1px solid $red;
    }
}