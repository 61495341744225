@import '../../../../../styles/main.scss';


.feedbackItem {
    display: flex;
    align-items: center;
    min-height: 64px;
    padding: 6px 12px;

    &__search {
        margin-bottom: 25px;
    }

    &__reporter {
        flex: 1;
        display: flex;
        align-items: center;

        &Avatar {
            height: 40px;
            min-width: 40px;
            border-radius: 100px;
            margin-right: 10px;
            border: 1px solid;

            & img {
                width: 100%;
                height: 100%;
            }
        }

        &Right{
            width: 100%;
        }

        &Name {
            @include text_small_bold;
            max-width: 100%;
            padding-right: 10px;

        }

        &Email {
            @include text_small_regular;
            color: $mediumGray;
            max-width: 100%;
            padding-right: 10px;
        }
    }

    &__category {
        flex: 1;

        &Title {
            @include text_small_bold;
            color: $darkGray;
            max-width: 100%;
            padding-right: 10px;
        }

        &Message {
            @include text_small_regular;
            color: $darkGray;
            max-width: 100%;
            padding-right: 10px;
        }
    }

    &__date {
        flex: 1.2;
        display: flex;
        align-items: center;
        justify-content: space-between;

        &Right{
            display: flex;
            align-items: center
        }

        &Date {
            @include text_small_regular;
            color: $darkGray;
        }

        &Button {
            border-radius: 7px;
            background-color: $mainGreen;
            display: flex;
            justify-content: center;
            align-items: center;
            @include text_small_bold;
            color: var(--white);
            padding: 8px 20px;
            margin-right: 20px;
        }
    }

    &__underline {
        height: 1px;
        background-color: $gray;
    }

    &__modal {
        width: 230px;
        padding: 10px;
        overflow: hidden;
        background-color: var(--white);
        border: 1px solid var(--lightGray);
        box-shadow: 0px 5px 60px rgba(0, 0, 0, 0.1);
        border-radius: 8px;
        transition: all 0.1s;
        -webkit-animation: anvil 0.15s cubic-bezier(0.38, 0.1, 0.36, 0.9) forwards;

        @keyframes anvil {
            0% {
                transform: scale(1) translateY(0px);
                opacity: 0;
            }

            1% {
                transform: scale(0.96) translateY(0px);
                opacity: 0;
            }

            100% {
                transform: scale(1) translateY(0px);
                opacity: 1;
            }
        }


        &Underline {
            width: 100%;
            height: 1px;
            background-color: $gray;
            margin: 7px 0;
        }

        &.bottom {
            height: auto;
        }

        &Item {
            height: 32px;
            display: flex;
            align-items: center;
            border-radius: 7px;
            @include text_small_regular;
            cursor: pointer;

            &:hover {
                background-color: var(--lightGray);
            }

            &.margin {
                margin: 2px 0;
            }

            svg {
                margin: 0 10px;
            }

            &Logo {
                display: flex;
                justify-content: center;
                align-items: center;
                position: absolute;
                right: 0;
                margin-right: 10px;

                animation: rotation 1.5s infinite linear;

                @keyframes rotation {
                    from {
                        transform: rotate(0deg);
                    }

                    to {
                        transform: rotate(359deg);
                    }
                }
            }
        }
    }
}