@import '../../../../../../../../../../styles/main.scss';

.assesmentItem {
    background-color: var(--white);
    &__label {
        color: $darkGray;
        @include text_small_bold;
        margin: 0;
        text-align: left;
        margin-bottom: 5px;
    }

    &__header {
        display: flex;
        align-items: center;
        justify-content: space-between;
        @include desktop_h4;
        margin-bottom: 15px;

        &Button {
            height: 36px;
            width: 36px;
            border-radius: 7px;
            border: 1px solid #DBDEE7;
            box-shadow: 0px 3px 4px rgba(24, 24, 28, 0.05);
            display: flex;
            justify-content: center;
            align-items: center;
            cursor: pointer;

            &.disable{
                opacity: 0.2;
                cursor: default;

            }
        }
    }

    &__description{
        margin: 10px 0 30px;
    }

    &__finding{
        display: flex;

        & > :first-child{
            margin-right: 15px;
            width: 100%;
        }

        &Label{
            @include text_small_bold;
            color: $darkGray;
            margin-bottom: 5px;
        }

        &Item{
            margin-bottom: 10px;

        }

        &Counter{
            display: flex;
            align-items: center;
            margin-bottom: 10px;

            & > :last-child{
                display: flex;
                align-items: center;
                justify-content: center;
                cursor: pointer;
                margin-left: 15px;


                &.disable{
                    cursor: default;
                }
            }
        }

        &Button{
            &  button{
                max-width: 180px;
                max-height: 36px;
            }
            
        }
    }

    &__underline{
        width: 100%;
        height: 1px;
        background-color: $gray;
        margin: 15px 0;
    }
}