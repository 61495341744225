@import '../../../../../../../../styles/main.scss';

.calculatorSettings {

  &__input {
    display: flex;
    align-items: center;
    color: $darkGray;
    margin-top: 15px;
    padding: 10px;
    @include text_medium_bold;

    &> :nth-child(2) {
        margin-left: 15px;
    }

    &> :nth-child(3) {
        margin-left: 15px;
        margin-right: 15px;
    }

    &> :last-child {
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
        margin-left: 10px;
    }
  }

  &__addButton {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 36px;
    width: 181px;
    border-radius: 7px;
    border: 1px solid #DBDEE7;
    box-shadow: 0px 3px 4px rgba(24, 24, 28, 0.05);
    @include text_small_bold;
    color: $mainGreen;
    margin: 10px 0 15px;
    cursor: pointer;

    &.disable {
        color: $gray;
        cursor: default;
    }

    &>svg {
        margin-right: 7px;
    }
  }

  &__underline {
    width: 100%;
    height: 1px;
    background-color: $gray;
    margin-bottom: 15px;

    &Top {
      width: 100%;
      height: 1px;
      background-color: $gray;
      margin: 10px 0 15px;
    }
  }

  &__output {
    display: flex;
    align-items: flex-end;
    margin: 10px 0 15px;

    &Name {
        display: flex;
        align-items: flex-end;

        &> :last-child {
            display: flex;
            justify-content: center;
            align-items: center;
            margin-left: 10px;
            height: 44px;
            cursor: pointer;
        }
    }

    &> :nth-child(2) {
      height: 44px;
      display: flex;
      justify-content: center;
      align-items: center;
      margin: 0 10px;
      color: $mediumGray;
    }

    &> :last-child {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 110px;
      height: 44px;
      border-radius: 7px;
      background-color: var(--lightGray);
      @include text_small_bold;
      color: $mediumGray;
    }
  }

  .dropdownWrapper {
    margin-top: 15px;
    display: flex;
    flex-direction: column;
    width: 200px;

    .dropdownLabel {
      font-weight: bold;
      font-size: 14px;
      color: #616A85;
      margin-bottom: 5px;
    }
  }

  // Logic Range Specific Styling
  &__range {
    margin-bottom: 20px;
  }

  .rangeWrapper {
    display: flex;
    flex-direction: column; // Ensures alphabet is on top of inputs
  }

  .rangeLabel {
    font-weight: bold;
    font-size: 14px;
    color: $darkGray;
    margin-bottom: 8px; // Space between alphabet and input
  }

  .inputWrapper {
    display: flex;
    align-items: center; // Align input, "to" label, and trash icon
    gap: 10px; // Adjusts the spacing between inputs and other elements

    input {
        flex: 1; // Inputs should take equal space
        min-width: 100px; // Ensure inputs have a minimum width
    }

    .toLabel {
        font-size: 14px;
        color: $mediumGray;
    }

    .trashIcon {
        margin-left: 10px; // Adds space between the input and trash icon
        cursor: pointer;
    }
  }

  &__underline {
    width: 100%;
    height: 1px;
    background-color: $gray;
    margin: 20px 0;
  }

  .maxOutputSection {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    margin-bottom: 15px;
  }

  .inputContainer {
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    width: 150px;
  }

  .customInputWidth {
    width: 160px
  }

  .checkboxContainer {
    margin-top: 10px;
    display: flex;
    align-items: center;
    justify-content: flex-end;

    label {
      font-size: 14px;
      color: #616A85;
      cursor: pointer;
      margin-left: 3px;
    }
  }

  .dropdownCheckboxContainer {
    margin-top: 10px;
    margin-left: 10px;
    display: flex;
    align-items: center;
    justify-content: flex-start;

    label {
      font-size: 14px;
      color: #616A85;
      cursor: pointer;
      margin-left: 3px;
    }
  }

  .rangesCheckboxContainer {
    display: flex;
    align-items: center;
    justify-content: flex-start;

    label {
      font-size: 14px;
      color: #616A85;
      cursor: pointer;
      margin-left: 5px;
    }
  }
}
