@import '../../../../styles/main.scss';

.folderItemSquare {
    display: flex;
    align-items: center;
    min-height: 72px;
    box-shadow: 0px 3px 4px rgba(24, 24, 28, 0.05);
    border: 1px solid #DBDEE7;
    border-radius: 7px;
    padding: 0 20px;
    position: relative;
    background-color: var(--white);
    cursor: pointer;

    &Dark {
        background-color: var(--lightGray);
        border: none;
        &__left {
            display: flex;
            align-items: center;
            justify-content: center;
    
            &>svg {
                min-width: 24px;
                path {
                    stroke: white
                }
            }
        }
    }
    &__active{
        background-color: $green10;
    }

    &__center {
        flex: 1;
        width: 100%;
        margin-left: 15px;

        &Title {
            @include desktop_h5;
        }

        &Count {
            @include text_x_small_regular;
            color: $darkGray;
        }
    }

    &__left {
        display: flex;
        align-items: center;
        justify-content: center;

        &>svg {
            min-width: 24px;
        }
    }

    &__modal {
        width: 230px;
        padding: 10px;
        margin-left: 10px;
        overflow: hidden;
        background-color: var(--white);
        border: 1px solid var(--lightGray);
        box-shadow: 0px 5px 60px rgba(0, 0, 0, 0.1);
        border-radius: 8px;
        transition: all 0.1s;
        -webkit-animation: anvil 0.15s cubic-bezier(0.38, 0.1, 0.36, 0.9) forwards;

        @keyframes anvil {
            0% {
                transform: scale(1) translateY(0px);
                opacity: 0;
            }

            1% {
                transform: scale(0.96) translateY(0px);
                opacity: 0;
            }

            100% {
                transform: scale(1) translateY(0px);
                opacity: 1;
            }
        }


        &Underline {
            width: 100%;
            height: 1px;
            background-color: $gray;
            margin: 7px 0;
        }

        &.bottom {
            height: auto;
        }

        &Item {
            height: 32px;
            display: flex;
            align-items: center;
            border-radius: 7px;
            @include text_small_regular;
            cursor: pointer;

            &:hover {
                background-color: var(--lightGray);
            }

            &.margin {
                margin: 2px 0;
            }

            svg {
                margin: 0 10px;
            }
        }
    }

    @media (max-width: 900px) {
        min-height: 106px;
        padding: 18px 10px 18px;

        &__right {
            margin-left: 10px;

            &>p {
                @include text_x_small_bold;
            }
        }
    }
}