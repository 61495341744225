@import '../../../../styles/main.scss';

.members {
  display: flex;
  flex-direction: column;
  flex: 1;
  max-height: 100%;

  &__inviteButton {
    width: 180px;
    min-width: 180px
  }

  @media (max-width: 600px) {
    &__inviteButton {
      max-height: 28px;
      width: 160px;
      min-width: 160px;


      & svg {
        margin-right: 5px;
      }
    }
  }
}