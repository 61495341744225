@import '../../../../../../../../../../styles/main.scss';

.resultItem {
    background-color: var(--white);

    &__label {
        @include desktop_h4; 
        margin-bottom: 15px;
    }

    &__item{
        display: flex;
        align-items: flex-end;
        margin-bottom: 10px;

        &Score{
            min-width: 68px;
            width: 68px;
        }

        &Result{
            width: 100%;
            margin: 0 15px;
        }

        & > p{
            height: 44px;
            display: flex;
            justify-content: center;
            align-items: center;
            @include text_small_regular;
            color: $mediumGray;
            margin: 0 5px;
        }

        & > :last-child{
            height: 44px;
            display: flex;
            align-items: center;
            justify-content: center;
            cursor: pointer;

            &.disable{
                cursor: default;
            }
        }
    }

    &__addButton{
        margin-top: 12px;
        & button{
            max-width: 128px;
            max-height: 36px;
        }
    }
   
}