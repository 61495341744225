@import '../../../../styles/main.scss';

.listBuilder {
    width: 100vw;
    height: 100vh;
    display: flex;

    &__left {
        display: flex;
        flex-direction: column;
        flex: 1;
        padding-top: 32px;
        &Dark {
            background-color: var(--lightGray);
        }
    }

    &__header{
        display: flex;
        align-items: center;

        &Left{
            width: 68px;
            min-width: 68px;
            display: flex;
            justify-content: center;

            &Arrow{
                cursor: pointer;
            }
        }
    
        &Right{
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: space-between;
            padding-right: 32px;

            &Dark {
                & input{
                    background-color: var(--lightGray) !important;
        
                    &::placeholder{
                        color: #DBDEE7;
                    }
                } 
            }

            &.pdfFormat{
                margin-left: calc((100% - 960px) / 2);
                width: 860px;
                padding-right: 0
            }
         

            & input{
                @include desktop_h1;
                outline: none;
                border: none;
                flex: 1;
                padding-right: 30px;
                background-color: white;
                color: var(--black);
                height: 43px;
    
                &::placeholder{
                    color: #DBDEE7;
                }
            }


            & .pdfButton{
                width: 200px;
            }


            & .saveButton{
                width: 160px;
            }

            & .deleteButton{
                display: flex;
                justify-content: center;
                align-items: center;
                height: 44px;
                width: 44px;
                border-radius: 7px;
                background: rgba(239, 47, 50, 0.2);
                margin-left: 10px;
                cursor: pointer;
            }

        }
    }

    &__tabTitle{
        & >  input{
            @include desktop_h2;
            color: $mainGreen;
            margin: 20px 0 15px;
            margin-left: 68px;
            outline: none;
            border: none;
            background-color: white;

          
        }

        &Dark {
            & > input {
                background-color: var(--lightGray) !important;
            }
        }

        &.noneMargin{
            & >  input{
                margin-left: 0;
    
              
            }
     
        }
    }

    &__content{
        flex: 1;
        padding-left: 68px;
        padding-right: 32px;
        overflow-y: auto;
        padding-bottom: 20px;
        padding-top: 5px;

        &.pdfPreview{
            width: 960px;
            align-self: center;
            overflow-y: visible;
            flex: 1;
        }

        &Button{
            max-width: 158px;
            margin-left: calc(100% - 158px);
        }
    }

    &__right {
        width: 500px;
        border-left: 1px solid #DBDEE7;
    }
}