@import '../../../../../../../styles/main.scss';

.item {
    min-height: 146px;
    background: #FFFFFF;
    border: 1px solid #DBDEE7;
    box-shadow: 0px 3px 4px rgba(24, 24, 28, 0.05);
    border-radius: 7px;
    padding: 15px;
    margin-top: 10px;
    display: flex;
    flex-direction: column;
    cursor: pointer !important;

    &Dark {
        background-color: var(--lightGray);
    }


    &.collapsed {
        min-height: 55px;
    }

    &__underline {
        width: 100%;
        height: 1px;
        background-color: $gray;
        margin-top: 15px;
    }

    &__loader {
        width: 100%;
        height: 146px;
        border-radius: 7px;
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: $green10;
        margin-top: 10px;

        svg {
            animation: rotation 1.5s infinite linear;
        }


        @keyframes rotation {
            from {
                transform: rotate(0deg);
            }

            to {
                transform: rotate(359deg);
            }
        }
    }

    &.active {
        border: 1px solid #3A6CFF;
        box-shadow: 0px 0px 0px 3px rgba(58, 108, 255, 0.2);
        border-radius: 7px;
    }

    &__header {
        display: flex;
        justify-content: space-between;
        &Dark {
            & input {
                background-color: var(--lightGray) !important;
            }        
        }

        & input {
            @include text_small_bold;
            color: var(--black);
            min-width: min-content;
            border: none;
            outline: none;
            width: auto;
            min-width: 200px;
            max-width: 500px;
            height: 24px;
            background-color: var(--white);
            color: var(--black);

            &::placeholder {
                color: $gray;
            }
        }

        &Left {
            display: flex;
            align-items: center;

            &Arrow {
                display: inline;
                display: flex;
                justify-content: center;
                align-items: center;
                margin-right: 10px;

                &.rotate {
                    transform: rotate(180deg);
                }
            }
        }
    }

    &__collapseArrow {
        display: inline;
        width: 24px;
        height: 22px;

        &.rotate {
            transform: rotate(180deg);
        }
    }

    &__noData {
        @include text_small_regular;
        color: $mediumGray;
        display: flex;
        justify-content: center;
        align-items: center;
        flex: 1;
    }

    &__footer {
        display: flex;
        justify-content: flex-end;
        align-items: center;
        position: relative;

        &Buttons {
            display: flex;
            justify-content: center;
            align-items: center;
            height: 36px;
            border: 1px solid #DBDEE7;
            box-shadow: 0px 3px 4px rgba(24, 24, 28, 0.05);
            border-radius: 7px;
            margin-left: 10px;

            & > * {
                width: 36px;
                height: 36px;
                display: flex;
                justify-content: center;
                align-items: center;
                // width: 36px;
            }

            & > :first-child{
                transform: rotate(90deg);
            }

            & > :nth-child(3){
                transform: rotate(270deg);

            }

            &Line{
                width: 1px;
                height: 100%;
                background-color: #DBDEE7;
            }

            &>svg {
                transform: rotate(90deg);
            }
        }

     

        &Popup {
            position: absolute;
            right: 0;
            top: 0;
            overflow: hidden;
            height: 0;
            width: 0;
            padding: 0px;
            background-color: var(--white);
            border: 1px solid #F5F5F7;
            box-shadow: 0px 5px 60px rgba(0, 0, 0, 0.1);
            border-radius: 8px;
            transition: all 0.1s;
            z-index: 2;

            &.active {
                width: 130px;
                height: auto;
                padding: 10px;
            }


            &Item {
                padding-left: 10px;
                height: 32px;
                border-radius: 7px;
                display: flex;
                align-items: center;
                cursor: pointer;
                @include text_small_regular;

                &> :first-child {
                    margin-right: 10px;
                }

                &:hover {
                    background-color: var(--lightGray);
                }
            }
        }
    }
}