@import '../../styles/main.scss';

.colorPicker {
    height: 44px;
    border-radius: 7px;
    background-color: var(--lightGray);
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 16px;
    position: relative;
    @include text_small_bold;

    &__relativeBlock{
         position: relative;
    }


    &__button {
        width: 32px;
        height: 32px;
        border-radius: 100px;
        cursor: pointer;
    }


}


.pickerModal {
    max-width: 230px;
    background-color: var(--white);
    border: 1px solid #F5F5F7;
    box-shadow: 0px 5px 60px rgba(0, 0, 0, 0.1);
    border-radius: 8px;

    &__top{
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between; 
        padding: 10px;

        &Button{
            width: 37px;
            height: 37px;
            display: flex;
            justify-content: center;
            align-items: center;
            cursor: pointer;
            margin-bottom: 5px;

            &> :first-child {
                width: 30px;
                height: 30px;
                border-radius: 100px;
            }

            &Border {
                width: 38px;
                height: 38px;
                border-radius: 100px;
                border: 2px solid #B0C4FF;
                position: absolute;
            } 
        }
    }

    &__underline{
        width: 100%;
        height: 1px;
        background-color: $gray;
        margin-bottom: 10px;
    }

    &__bottom{
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 0 12px 10px;

        & svg{
            cursor: pointer;
        }

        &Arrow{
            display: flex;
            justify-content: flex-start;
            align-items: center;
            transform: rotate(90deg);
        }

        &Button{
            width: 50px;
            height: 22px;
            display: flex;
            justify-content: center;
            align-items: center;
            border-radius: 3px;
            @include text_x_small_bold;
            background-color: $mainGreen;
            color: var(--white);
            cursor: pointer;
        }
    }
}
