@import '../../../../../../styles/main.scss';

.invationItem {
    display: flex;
    height: 64px;
    align-items: center;
    flex: 1;
    @include text_small_regular;
    color: $darkGray;
    text-align: left;
    padding: 0 10px;

    &:hover {
        background-color: var(--hoverColor);
    }

    &__organization {
        display: flex;
        align-items: center;
        flex: 3;
        @include text_small_bold;
        color: var(--black);

        &Avatar {
            width: 40px;
            height: 40px;
            border-radius: 7px;
            margin-right: 10px;

            &>img {
                width: 100%;
                height: 100%;
            }
        }
    }


    &__members {
        display: flex;
        align-items: center;
        flex: 1;
        height: 100%;
        text-align: left;

        &Counter {
            margin-left: 8px;
        }
    }

    &__date {
        flex: 1.2;
    }

    &__role {
        flex: 2;
        display: flex;
        align-items: center;
        justify-content: space-between;


        &Right {
            display: flex;
            align-items: center;

            &> :first-child {
                margin-right: 10px;
            }

            button {
                max-height: 36px;
            }
        }

        &Button {
            width: 89px;
            min-width: 89px;
        }

    }

    &__underline {
        height: 1px;
        width: 100%;
        background-color: $gray;
    }


    @media (max-width: 1210px) {
        &__organization {
            flex: 2.2;
        }
    }


    @media (max-width: 1120px) {
        &__members {
            display: none;
        }
    }


    @media (max-width: 1013px) {
        &__date {
            display: none;
        }
    }

    @media (max-width: 900px) {
        &__organization {

            &Avatar {
                width: 24px;
                height: 24px;
                min-width: 24px;
            }
        }
    }




    @media (max-width: 860px) {
        &__role {
            &Right {

                &> :first-child {
                    margin-right: 8px;
                }

                button {
                    max-height: 28px;
                }
            }

            &Button {
                width: 72px;
                min-width: 72px;
            }
        }
    }


    @media (max-width: 767px) {
        &__role {
            @include text_x_small_regular;

        }
    }

    @media (max-width: 470px) {

        &__role {
            &>span {
                display: none;
            }
        }
    }
}