@import '../../styles/main.scss';

.warning{
    @include text_x_small_regular;
    color: $darkGray;
    margin-top: 5px;

    &__red{
        color: $red;
    }
}