@import '../../../../../../../../../styles/main.scss';

.inputText {
    flex: 1;
    padding: 10px 0;

    &__field {
        border: 1px solid $gray;
        box-shadow: 0px 3px 4px rgba(24, 24, 28, 0.05);
        border-radius: 7px;
        outline: none;
        width: 100%;
        min-height: 100px;
        max-height: 300px;
        padding: 12px 15px;
        @include text_small_regular;
        resize: none;

        &.active {
            border: 1px solid $blue;
            box-shadow: 0px 0px 0px 3px rgba(58, 108, 255, 0.2);
        }
    }

    &> ::placeholder {
        color: $mediumGray;
    }
}