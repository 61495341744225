@import '../../styles/main.scss';

.modal {
    width: 700px;

    &__title {
        @include desktop_h3;
        color: $darkBlue;
    }

    & input {
        height: 20px;
        @include text_small_regular;
        margin-top: 22px;
        border: none;
        outline: none;
        width: 100%;

        &::placeholder {
            color: $darkGray;
        }
    }

    &__underline {
        height: 1px;
        background-color: $gray;
        margin: 15px 0 20px;
    }

    & button {
        max-width: 200px;
    }
}