@import '../../../../../styles/main.scss';

.container {
    display: flex;
    flex-direction: column;
    flex: 1;
    padding-right: 502px;
    padding-left: 100px;
    margin-right: 36px;


    .loader{
        display: flex;
        justify-content: center;
        align-items: center;
        flex: 1;


        & svg {
            animation: rotation 1.5s infinite linear;
            width: 40px;
            height: 40px;

        }

        @keyframes rotation {
            from {
                transform: rotate(0deg);
            }

            to {
                transform: rotate(359deg);
            }
        }
    }


    &__textAreaWrapper {
        display: flex;
        flex: 1;
        margin: 20px 0 24px;
        border: 1px solid $gray;
        border-radius: 7px;
        overflow: hidden;
        position: relative;
        box-shadow: 0px 3px 4px rgba(24, 24, 28, 0.05);



        textarea {
            flex: 1;
            resize: none;
            outline: none;
            padding: 12px 15px;
            @include text_small_regular;
            border: none;

            &::placeholder {
                color: #979DB1;
            }

        }
    }


    &__buttons {
        display: flex;
        // flex-wrap: wrap;

        & button {
            padding: 0 12px;
            min-height: 56px;
        }

        //    &Rewrite{
        //     width: 100%;
        //     & button{
        //         padding:  0 12px;
        //         min-height: 40px;
        //        }
        //    }

        // &Undo{
        //     flex: 1
        // }

        // &Rewrite{
        //     flex-grow: 1;
        //     max-width: 174px;

        // }

        &Copy {
            // flex-grow: 1;
            // flex: 1;
            margin: 0 16px;
        }
    }

    &__attention {
        position: absolute;
        z-index: 333;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        text-align: center;
        @include text_small_regular;
        color: $mediumGray;


        & svg {
            margin-bottom: 10px;
        }
    }

    .footer {
        @include text_small_regular;
        color: $darkGray;
        margin: 24px 0 34px;

        &__red {
            color: $red;
            @include text_small_bold;
        }
    }



    @media (max-width: 1266px) {
        padding-right: 410px;
        margin-right: 17px;
    }

    @media (max-width: 1024px) {
        padding-right: 326px;
    }

    @media (max-width: 900px) {
        padding-right: 267px;
        padding-left: 17px;
    }

    @media (max-width: 728px) {

        &__buttons {
            flex-wrap: wrap;



            &Rewrite {
                width: 100%;


                & button {
                    padding: 0 12px;
                    min-height: 40px;
                    margin-bottom: 16px;
                }
            }

            &Copy {
                width: 47%;
                margin: 0 6% 0 0;

                & button {
                    flex: 1
                }

            }

            &Undo {
                width: 47%;

                & button {
                    flex: 1;
                }
            }
        }

    }

    // @media (max-width: 716px) {
    //     &__textAreaWrapper {
    //         flex: 1;
    //         min-height: 300px;
    //     }
    // }

    @media (max-width: 690px) {
        padding-right: 0;
    }

}