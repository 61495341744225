@import '../../../../../../../../styles/main.scss';

.textboxSettings{
    &__underline{
        height: 1px;
        width: 100%;
        background-color: $gray;
        margin: 10px 0 15px;
    }

    &__button{
        display: flex;
        justify-content: flex-end;
        margin-top: 5px;
        
        & button{
            min-width: 100px;
            max-height: 32px;
        }
    }

    &__modal{
        width: 100%;
        height: 100%;
        position: relative;

        &Content{
            display: flex;
            flex-direction: column;
            height: 100%;
            padding-top: 20px;
        }
    }

    &__modalContainer{
        width: 90%;
        height: 90%;
        top: 5%;
        display: flex;flex-direction: column;
    }
}


//  .fr-box{
//     height: 100%;
//     background-color: beige;
//     display: flex;
//     flex-direction: column;
//  }

//   .fr-basic{
//     height: 100%;
//     background-color: beige;
//     display: flex;
//     flex-direction: column;
//  }

//  .fr-top{
//     height: 100%;
//     background-color: beige;
//     display: flex;
//     flex-direction: column;
//  }