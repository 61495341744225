@import '../../styles/main.scss';

.badge {
    padding: 7px 10px 5px;
    @include item_header_12;
    letter-spacing: 0.03em;
    text-transform: uppercase;
    border-radius: 5px;
    display: inline-block;

    &.green {
        background: rgba(41, 180, 115, 0.2);
        color: $mainGreen;
    }

    &.blue {
        background: rgba(58, 108, 255, 0.2);
        color: $blue;

    }

    &.gray {
        background: rgba(151, 157, 177, 0.2);
        color: $mediumGray;

    }
}