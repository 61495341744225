@import '../../styles/main.scss';


.createOrganization {
    width: 700px;

    @media (max-width: 968px){
        width: 72vw;
    }

    @media (max-width: 600px){
        width: 100%;
    }


    &__icons{
        display: flex;
        justify-content: space-between;
        align-items: center;

        height: 32px;
        margin-bottom: 16px;

        & > *{
            display: flex;
            justify-content: center;
            align-items: center;
            cursor: pointer;
        }

        & > :first-child{
            transform: rotate(90deg);
        }

    }
  
    &__header{
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        margin-bottom: 70px;


        @media (max-width: 767px){
            margin-bottom: 25px;
        }

        & :nth-child(2){
            margin: 0 10px;
        }

        &Item {
            height: 5px;
            border-radius: 5px;
            background-color: $gray;
            width: 100%;

           &.active{
                background-color: $mainGreen;
            }
        }
       
    }
}

.modal {
    @media (max-width: 600px){
        margin-top: 10px;
        padding-top: 0;
    }
}
