@import '../../styles/main.scss';

.playmentInput {

    & > p{
        color: $darkGray;
        @include text_small_bold;
        margin: 0;
        text-align: left;
        margin-bottom: 5px;
    }


    &__input {
        display: flex;
        flex-direction: column;
        height: 44px;
        border: 1px solid #DBDEE7;
        box-shadow: 0px 3px 4px rgba(24, 24, 28, 0.05);
        overflow: hidden;
        border-radius: 7px;
        justify-content: center;
        padding: 0 15px;
        

        &.focus {
            box-shadow: 0px 0px 0px 3px rgba(58, 108, 255, 0.2);
            border: 1px solid $blue;
        }
    }
}