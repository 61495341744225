@import '../../../../styles/main.scss';

.userRequests {

    &__search {
        margin: 25px 0;
    }

    @media (max-width: 767px) {

        &__search {
            margin: 20px 0;
        }
    }

    &__member {
        flex: 2;
    }

    &__date {
        flex: 1;
    }

    &__role {
        flex: 1.5;
    }

    &__action {
        flex: 1;
    }

    @media (max-width: 1400px) {
        &__date {
            display: none;
        }
    }

    @media (max-width: 1220px) {
        &__member {
            flex: 1.7;
        }
    }

    @media (max-width: 1133px) {
        &__action {
            flex: 0.8;

        }
    }

    @media (max-width: 600px) {
        &__role {
            display: none;
        }

        &__action {
            flex: 1.5
        }
    }
}