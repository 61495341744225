@import '../../styles/main.scss';

.numberWidget {
    height: 22px;
    padding: 7px;
    border-radius: 4px;
    display: flex;
    justify-content: center;
    align-items: center;

    &.green{
        background: rgba(41, 180, 115, 0.1);
        color: $mainGreen;
    }

    &.gray{
        background: #F5F5F7;
        color: $mediumGray;
    }

    &.red{
        background: rgba(239, 47, 50, 0.1);
        color: $red;

    }

    &.blue{
        background: rgba(58, 108, 255, 0.1);
        color: $blue;
    }
}