@import "../../styles/main.scss";

.search {
  margin: 25px 0;
  display: flex;
  justify-content: space-between;
  align-items: center;

  &Right {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: 30px;
    margin-right: 30px;

    & > :first-child {
      margin-right: 10px;
    }
  }

  &Button {
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
  }
  &__modal {
    @include text_small_regular;
    width: 230px;
    padding: 10px;
    margin-left: 10px;
    overflow: hidden;
    background-color: var(--white);
    border: 1px solid var(--lightGray);
    box-shadow: 0px 5px 60px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
    transition: all 0.1s;
    -webkit-animation: anvil 0.15s cubic-bezier(0.38, 0.1, 0.36, 0.9) forwards;

    @keyframes anvil {
      0% {
        transform: scale(1) translateY(0px);
        opacity: 0;
      }

      1% {
        transform: scale(0.96) translateY(0px);
        opacity: 0;
      }

      100% {
        transform: scale(1) translateY(0px);
        opacity: 1;
      }
    }
    &Item {
      height: 32px;
      display: flex;
      align-items: center;
      border-radius: 7px;
      @include text_small_regular;
      cursor: pointer;

      &:hover {
        background-color: var(--lightGray);
      }

      &.margin {
        margin: 2px 0;
      }

      svg {
        margin: 0 10px;
        path {
          fill: var(--black);
        }
      }
    }
  }
}
