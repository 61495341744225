@import "../../styles/main.scss";
.table {
  flex: 1;
  max-height: 100%;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  word-break: break-word;

  &__title {
    @include desktop_h4;
    color: $mediumGray;
    margin: 16px 0 8px;
  }

  &__header {
    background-color: var(--lightGray);
    min-height: 40px;
    border-radius: 7px;
    display: flex;
    flex-direction: row;
    padding: 0 10px;
    display: flex;
    align-items: center;

    @include item_header_12;
    color: $mediumGray;

    &Item {
      height: 100%;
      display: flex;
      align-items: center;
      text-transform: uppercase;
      letter-spacing: 0.03em;

      & > p {
        max-width: 95%;
      }
    }
  }

  &__rows {
    position: relative;
    height: 100%;
    flex: 1;
    max-height: 100%;
    overflow-y: auto;
  }

  @media (max-width: 767px) {
    &__header {
      min-height: 28px;
      font-size: 10px;
    }
    &__rows {
      max-height: 80vh;
    }
  }
}
