@import '../../../../../../styles/main.scss';

.modal{
    width: 652px;

    &__content{
        background-color: var(--lightGray);
    }

    &__title{
        @include desktop_h3;
        color: $darkBlue;
    }

    &__row{
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-top: 20px;

        &Title{
            @include text_small_regular;
            color: $darkGray;
        }

        &Right{
            display: flex;
            align-items: center;
        }

        &Price{
            @include text_small_regular;
            color: $darkBlue;
            margin-left: 20px;
        }
    }

    &__underline{
        height: 1px;
        background-color: $gray;
        margin: 13px 0 20px;
    }

    &__button{
        width: 200px;
    }

    @media (max-width: 760px) {
        width: calc(100vw - 110px);
    }


    @media (max-width: 500px) {
        width: calc(100vw - 40px);

        &__content{
            padding: 20px 10px;
            border-radius: 7px

        }

        &__row{
            &Title{
                @include text_x_small_regular;
            }

            &Price{
                margin-left: 10px;
            }
         
        }
    }
}