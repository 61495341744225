@import '../../../../../../styles/main.scss';

.container{
    width: 100%;
    min-height: 76px;
    background-color: var(--white);
    border: 0.50px $gray solid; 
    border-radius: 7px;
    box-shadow: 0px 3px 4px rgba(24, 24, 28, 0.05);
    padding: 18px 20px;
    margin-bottom: 10px;

    &.green{
        background-color: $green10;
    }

    &__content{
        display: flex;
    }

    &__avatar{
        width: 40px;
        min-width: 40px;
        height: 40px;
        border-radius: 100px;
        margin-right: 15px;
        display: flex;
        justify-content: center;
        align-items: center;

        & > img{
            width: 100%;
            height: 100%;
        }
    }

    &__message{
        @include text_medium_regular;
    }

    &__footer{
        display: flex;
        align-items: center;
        justify-content: flex-end;
        margin-top: 10px;
        flex-wrap: wrap;
        gap: 5px;

        &Title{
            @include text_small_bold;
            color: $mediumGray;
            margin-right: 6px;
        }

        &Buttons{
            display: flex;
            gap: 5px;
            flex-wrap: wrap;
            justify-content: flex-end;
        }

        &Button{
            width: 110px;
            height: 36px;
            border-radius: 7px;
            border: 1px solid $mediumGray;
            display: flex;
            justify-content: center;
            align-items: center;
            @include text_small_bold;
            color: $mediumGray;
            cursor: pointer;
            min-width: max-content;

            &.active{
                background-color: $mainGreen;
                border-color: $mainGreen;
                color: var(--white);

                svg{
                    fill: var(--white);
                }
            }
            

            svg{
                margin-right: 7px;
                fill: $mediumGray;
            }
        }
    }
}