@import '../../../../styles/main.scss';

.adminsManagement {
    display: flex;
    flex-direction: column;
    flex: 1;
    max-height: 100%;

    &__createButton {
        width: 245px;
        height: 44px;
        border-radius: 7px;
        background-color: #29B473;
        display: flex;
        justify-content: center;
        align-items: center;
        @include text_small_bold;
        color: var(--white);
        cursor: pointer;

        &>svg {
            margin-right: 10px;
        }

        & p::before {
            content: 'Create New User';
        }
    }

    &__search {
        margin-bottom: 25px;
    }

    &__user {
        flex: 1;
    }

    &__organizations {
        flex: 0.6;
    }

    &__date {
        flex: 0.6;

        &::before {
            content: 'Date of creation';
        }
    }

    @media (max-width: 970px) {
        &__organizations {
            flex: 0.55;
        }

        &__date {
            flex: 0.4;


            &::before {
                content: 'Date';
            }
        }
    }


    @media (max-width: 767px) {
        &__search {
            margin-bottom: 20px;
            margin-top: 3px;
        }
    }

    @media (max-width: 600px) {

        &__createButton {
            height: 28px;
            width: 120px;

            &>p::before {
                content: 'Create New';
            }
        }
    }

    @media (max-width: 470px) {
        &__organizations {
            display: none;
        }
    }
}