@import '../../../../../../../../styles/main.scss';

.burnedAreas {
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: center;


    &__wrapper {
        padding: 10px 0;
    }

    &__percent {
        @include desktop_h3;
        text-align: center;
        margin: 0 auto;
        margin-top: 10px;
    }

    &> :first-child {
        margin-right: 20px;
    }

    &__adultFront {
        position: relative;
        width: 281px;
        height: 576.5px;
        display: flex;
        flex-direction: column;

        &Torso {
            left: 70px;
            top: 86.5px;
            position: absolute;
            align-self: center;
            height: 186px;
            z-index: 1;
            cursor: pointer;

            &>svg {
                opacity: 0;
            }

            &.active {
                &>svg {
                    opacity: 1;
                }
            }


            &.disable {
                cursor: default;
            }
        }

        &Head {
            position: absolute;
            align-self: center;
            left: 109px;
            top: -1px;
            cursor: pointer;

            z-index: 2;

            &>svg {
                opacity: 0;
            }


            &.active {
                &>svg {
                    opacity: 1;
                }
            }

            &.disable {
                cursor: default;
            }
        }

        &RightHand {
            position: absolute;
            top: 105px;
            left: -1px;
            z-index: 2;
            cursor: pointer;



            &>svg {
                opacity: 0;
            }


            &.active {
                &>svg {
                    opacity: 1;
                }
            }

            &.disable {
                cursor: default;
            }
        }

        &LeftHand {
            position: absolute;
            top: 105px;
            right: -2px;
            z-index: 2;
            transform: rotateY(180deg);
            cursor: pointer;


            &>svg {
                opacity: 0;
            }


            &.active {
                &>svg {
                    opacity: 1;
                }
            }

            &.disable {
                cursor: default;
            }
        }

        &RightLeg {
            position: absolute;
            left: 86px;
            bottom: -5.5px;
            z-index: 2;
            cursor: pointer;

            &>svg {
                opacity: 0;
            }

            &.active {
                &>svg {
                    opacity: 1;
                }
            }

            &.disable {
                cursor: default;
            }
        }

        &LeftLeg {
            position: absolute;
            position: absolute;
            right: 85px;
            bottom: -5.5px;
            z-index: 2;
            transform: rotateY(180deg);
            cursor: pointer;


            &>svg {
                opacity: 0;
            }


            &.active {
                &>svg {
                    opacity: 1;
                }
            }

            &.disable {
                cursor: default;
            }
        }

        &Groin {
            position: absolute;
            position: absolute;
            right: 128px;
            top: 273px;
            z-index: 3;
            cursor: pointer;

            &>svg {
                opacity: 0;
            }


            &.active {
                &>svg {
                    opacity: 1;
                }
            }

            &.disable {
                cursor: default;
            }
        }
    }

    &__adultBack {
        position: relative;
        width: 281px;
        height: 576.5px;
        display: flex;
        flex-direction: column;

        &Torso {
            left: 72.5px;
            top: 86.5px;
            position: absolute;
            align-self: center;
            height: 228px;
            z-index: 1;
            cursor: pointer;


            &>svg {
                opacity: 0;
            }

            &.active {
                &>svg {
                    opacity: 1;
                }
            }

            &.disable {
                cursor: default;
            }
        }

        &Head {
            position: absolute;
            align-self: center;
            left: 109px;
            top: -1px;
            cursor: pointer;

            z-index: 2;

            &>svg {
                opacity: 0;
            }


            &.active {
                &>svg {
                    opacity: 1;
                }
            }

            &.disable {
                cursor: default;
            }
        }

        &RightHand {
            position: absolute;
            top: 103px;
            left: 0px;
            z-index: 2;
            transform: rotateY(180deg);
            cursor: pointer;


            &>svg {
                opacity: 0;
            }


            &.active {
                &>svg {
                    opacity: 1;
                }
            }

            &.disable {
                cursor: default;
            }
        }

        &LeftHand {
            position: absolute;
            top: 103.55px;
            right: -2px;
            z-index: 2;
            cursor: pointer;


            &>svg {
                opacity: 0;
            }


            &.active {
                &>svg {
                    opacity: 1;
                }
            }

            &.disable {
                cursor: default;
            }
        }

        &RightLeg {
            position: absolute;
            left: 86px;
            bottom: -5px;
            z-index: 2;
            cursor: pointer;

            &>svg {
                opacity: 0;
            }


            &.active {
                &>svg {
                    opacity: 1;
                }
            }

            &.disable {
                cursor: default;
            }
        }

        &LeftLeg {
            position: absolute;
            position: absolute;
            right: 84px;
            bottom: -5px;
            z-index: 2;
            cursor: pointer;
            transform: rotateY(180deg);


            &>svg {
                opacity: 0;
            }


            &.active {
                &>svg {
                    opacity: 1;
                }
            }

            &.disable {
                cursor: default;
            }
        }
    }

    &__childFront {
        position: relative;
        width: 200.43px;
        height: 475.83px;
        display: flex;
        flex-direction: column;

        &Torso {
            left: 54.7px;
            top: 91px;
            position: absolute;
            align-self: center;
            height: 186px;
            z-index: 1;
            cursor: pointer;

            &>svg {
                opacity: 0;
            }

            &.active {
                &>svg {
                    opacity: 1;
                }
            }

            &.disable {
                cursor: default;
            }
        }

        &Head {
            position: absolute;
            align-self: center;
            left: 65.23px;
            top: -1px;
            cursor: pointer;

            z-index: 2;

            &>svg {
                opacity: 0;
            }


            &.active {
                &>svg {
                    opacity: 1;
                }
            }

            &.disable {
                cursor: default;
            }
        }

        &RightHand {
            position: absolute;
            top: 102px;
            left: 0px;
            z-index: 2;
            cursor: pointer;



            &>svg {
                opacity: 0;
            }


            &.active {
                &>svg {
                    opacity: 1;
                }
            }

            &.disable {
                cursor: default;
            }
        }

        &LeftHand {
            position: absolute;
            top: 102px;
            right: -3px;
            z-index: 2;
            transform: rotateY(180deg);
            cursor: pointer;


            &>svg {
                opacity: 0;
            }


            &.active {
                &>svg {
                    opacity: 1;
                }
            }

            &.disable {
                cursor: default;
            }
        }

        &RightLeg {
            position: absolute;
            left: 103.5px;
            bottom: -5px;
            z-index: 2;
            transform: rotateY(180deg);
            cursor: pointer;


            &>svg {
                opacity: 0;
            }

            &.active {
                &>svg {
                    opacity: 1;
                }
            }

            &.disable {
                cursor: default;
            }
        }

        &LeftLeg {
            position: absolute;
            position: absolute;
            bottom: -5.2px;
            left: 42.23px;
            z-index: 2;
            cursor: pointer;



            &>svg {
                opacity: 0;
            }


            &.active {
                &>svg {
                    opacity: 1;
                }
            }

            &.disable {
                cursor: default;
            }
        }

        &Groin {
            position: absolute;
            position: absolute;
            right: 128px;
            top: 273px;
            z-index: 3;
            cursor: pointer;

            &>svg {
                opacity: 0;
            }


            &.active {
                &>svg {
                    opacity: 1;
                }
            }

            &.disable {
                cursor: default;
            }
        }
    }

    &__childBack {
        position: relative;
        width: 200.43px;
        height: 475.83px;
        display: flex;
        flex-direction: column;

        &Torso {
            left: 54.3px;
            top: 91px;
            position: absolute;
            align-self: center;
            height: 186px;
            z-index: 1;
            cursor: pointer;

            &>svg {
                opacity: 0;
            }

            &.active {
                &>svg {
                    opacity: 1;
                }
            }

            &.disable {
                cursor: default;
            }
        }

        &Head {
            position: absolute;
            align-self: center;
            left: 65.1px;
            top: -1.7px;
            cursor: pointer;

            z-index: 2;

            &>svg {
                opacity: 0;
            }


            &.active {
                &>svg {
                    opacity: 1;
                }
            }

            &.disable {
                cursor: default;
            }
        }

        &RightHand {
            position: absolute;
            top: 98.5px;
            left: 1px;
            z-index: 2;
            cursor: pointer;


            &>svg {
                opacity: 0;
            }

            &.active {
                &>svg {
                    opacity: 1;
                }
            }

            &.disable {
                cursor: default;
            }
        }

        &LeftHand {
            position: absolute;
            top: 98.5px;
            right: -3px;
            z-index: 2;
            transform: rotateY(180deg);
            cursor: pointer;


            &>svg {
                opacity: 0;
            }


            &.active {
                &>svg {
                    opacity: 1;
                }
            }

            &.disable {
                cursor: default;
            }
        }

        &RightLeg {
            position: absolute;
            left: 103.5px;
            bottom: -5px;
            z-index: 2;
            transform: rotateY(180deg);
            cursor: pointer;


            &>svg {
                opacity: 0;
            }

            &.active {
                &>svg {
                    opacity: 1;
                }
            }

            &.disable {
                cursor: default;
            }
        }

        &LeftLeg {
            position: absolute;
            position: absolute;
            bottom: -5.2px;
            left: 44px;
            z-index: 2;
            cursor: pointer;


            &>svg {
                opacity: 0;
            }


            &.active {
                &>svg {
                    opacity: 1;
                }
            }

            &.disable {
                cursor: default;
            }
        }

        &Groin {
            position: absolute;
            position: absolute;
            right: 128px;
            top: 273px;
            z-index: 3;
            cursor: pointer;

            &>svg {
                opacity: 0;
            }


            &.active {
                &>svg {
                    opacity: 1;
                }
            }

            &.disable {
                cursor: default;
            }
        }
    }

    @media (max-width: 717px) {
        flex-direction: column;

        &__adultFront {
            // transform: scale(0.5);
            // &>svg {
            //     scale:0.5;
            //     overflow: hidden;
            // }

        }

        &__adultBack {
            margin-top: 10px;
            // transform: scale(0.5);
        }


        &__childFront {
            // transform: scale(0.5);
        }

        &__childBack {
            margin-top: 10px;
            // transform: scale(0.5);
        }
    }
}