@import '../../../../styles/main.scss';

.billing {
    overflow-y: auto;
    padding-left: 4px;

    &__loader {
        display: flex;
        justify-content: center;
        align-items: center;
        max-width: 691px;
        height: 314px;
        background-color: rgba(41, 180, 115, 0.1);
        border: 1px solid #29B473;
        border-radius: 7px;
        margin-top: 25px;

        &>svg {
            width: 48px;
            height: 48px;

            animation: rotation 1.5s infinite linear;


            @keyframes rotation {
                from {
                    transform: rotate(0deg);
                }

                to {
                    transform: rotate(359deg);
                }
            }
        }
    }

    &::-webkit-scrollbar {
        display: none;
    }

    & .widget {
        max-width: 700px;
        width: 100%;
        background-color: var(--lightGray);
        border-radius: 7px;
        padding: 20px 30px;
        margin-top: 25px;
        @include text_small_regular;
        color: $darkGray;

        &> :nth-child(2) {
            margin-bottom: 20px
        }

        &__title {
            @include desktop_h3;
            color: $darkBlue;
            margin-bottom: 20px;
        }

        &__row {
            display: flex;
            align-items: center;
            justify-content: space-between;

            &>span {
                display: flex;
                justify-content: center;
                align-items: center
            }
        }

        &__date {
            @include text_small_bold;
            color: $darkBlue;
        }

        &__underline {
            width: 100%;
            height: 1px;
            border-bottom: 1px solid $gray;
            margin: 13px 0;

            &Dashed {
                border-bottom: 1px dashed $gray;
                margin: 13px 0;
            }
        }

        &__button {
            width: 200px;
            height: 56px;
            max-height: 56px;
            margin-top: 13px
        }

        &__cost {
            @include text_small_bold;
            color: var(--black);
        }

        &__price {
            @include desktop_h4;
            color: $darkBlue;
        }
    }

    &__label {
        @include text_small_bold;
        color: $darkGray;
        margin: 25px 0 5px;
    }

    &__row {
        display: flex;
        align-items: center;
        margin-bottom: 25px;
    }

    &__field {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 0 15px;
        width: 420px;
        min-width: 420px;
        height: 44px;
        border-radius: 7px;
        margin-right: 15px;
        box-shadow: 0px 3px 4px rgba(24, 24, 28, 0.05);
        border: 1px solid $gray;
        @include text_small_regular;
    }

    &__defaultText {
        @include text_medium_bold;
        color: $darkGray;
    }

    &__makeDefault {
        @include text_medium_bold;
        color: $blue;
        cursor: pointer;
    }

    &__button {
        display: flex;
        align-items: center;
        @include text_medium_bold;
        color: $mainGreen;
        margin-top: 25px;
        cursor: pointer;
        max-width: fit-content;

        &>svg {
            margin-right: 10px;
        }
    }

    &__organizationModal {
        padding-bottom: 30px;
    }

    @media (max-width: 1040px) {
        &__field {
            width: 100%;
            margin-right: 0;
            margin-bottom: 8px;
        }

        &__row {
            flex-wrap: wrap;
        }
    }

    @media (max-width: 900px) {
        & .widget {
            padding: 20px 10px;

            &__title {
                @include desktop_h4
            }

            &__button {
                width: 133px;
                height: 44px;
            }

            &__price {
                @include desktop_h5
            }
        }
    }

    @media (max-width: 767px) {
        &__label {
            @include text_small_bold;
            color: $darkGray;
            margin: 20px 0 5px;
        }


        &__row {
            margin-bottom: 20px;
        }

        &__button {
            margin-top: 20px;
        }
    }
}