@import '../../../../../../../../styles/main.scss';

.timerSettings {
    &__underline{
        height: 1px;
        width: 100%;
        background-color: $gray;
        margin: 10px 0 15px;
    }
    
    &__timeInputs{
        display: flex;
        margin: 15px 0;

        & > * {
            flex: 1;
        }

        & > :first-child{
            margin-right: 15px;
        }
    }

}