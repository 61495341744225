@import '../../styles/main.scss';

.organizationSearch {
    position: relative;


    .input {
        display: flex;
        flex-direction: row;
        height: 44px;
        border: 1px solid #DBDEE7;
        box-shadow: 0px 3px 4px rgba(24, 24, 28, 0.05);
        overflow: hidden;
        border-radius: 7px;
        align-items: center;
        background-color: var(--white);
        padding: 0 12px;
        margin-bottom: 15px;
        position: relative;
        z-index: 1;

        &__defaultCursor {
            &> :first-child {
                cursor: default;
            }

            &> :nth-child(2) {
                cursor: default;
            }
        }


        &__label {
            color: $darkGray;
            @include text_small_bold;
            margin: 0;
            text-align: left;
            margin-bottom: 5px;
        }

        &__organization {

            &Wrapper {
                display: flex;
                flex: 1;
                padding: 0 15px;
                overflow: hidden;


            }

            display: flex;
            align-items: center;
            height: 36px;
            padding: 6px 10px 6px 6px;
            background: #F5F5F7;
            border-radius: 5px;
            max-width: 100%;
            overflow: hidden;

            &>p {
                @include text_small_bold;
                text-overflow: ellipsis;
                white-space: nowrap;
                overflow: hidden;
                width: 100%;
            }


            &Image {
                width: 24px;
                height: 24px;
                min-width: 24px;
                border-radius: 7px;
                margin-right: 8px;

                & img {
                    width: 100%;
                    height: 100%;
                }
            }


        }

        &__icon {
            width: 20px;
            height: 20px;
        }

        &__cross {
            width: 100%;
            height: 100%;
            min-width: 20px;
            border-radius: 100px;
            cursor: pointer;
            display: flex;
            justify-content: center;
            align-items: center;
            background-color: $mediumGray;

            & svg {
                width: 14px;
                height: 14px;
            }
        }

        &__loader {
            width: 100%;
            height: 100%;
            display: flex;
            align-items: center;
            justify-content: center;

            & svg {
                animation: rotation 1.5s infinite linear;

            }

            @keyframes rotation {
                from {
                    transform: rotate(0deg);
                }

                to {
                    transform: rotate(359deg);
                }
            }
        }


        input {
            width: 100%;
            border: none;
            outline: none;
            padding: 15px;

            &::placeholder {
                color: $mediumGray;
                @include text_small_regular;
            }
        }

        &:hover {
            cursor: text;
        }

        &.focus {
            box-shadow: 0px 0px 0px 3px rgba(58, 108, 255, 0.2);
            border: 1px solid $blue;
        }

        &.error {
            box-shadow: 0px 0px 0px 3px rgba(239, 47, 50, 0.2);
            border: 1px solid $red;
        }
    }

    .select {
        display: flex;
        flex-direction: column;
        position: absolute;

        width: 100%;
        top: calc(100% + 5px);
        border-radius: 8px;
        box-shadow: 0px 5px 60px rgba(0, 0, 0, 0.1);
        max-height: 292px;

        transition: all 0.1s;
        background-color: var(--white);
        overflow: hidden;
        z-index: 30;

        &.rotate {
            top: auto;
            bottom: calc(100% + 5px);
        }

        &Block {
            width: 100%;
            border: 1px solid var(--lightGray);
            padding: 10px 12px;
            overflow-y: auto;
            border-radius: 8px;
        }

        &.close {
            height: 0;
        }
    }

    .item {
        min-height: 52px;
        display: flex;
        align-items: center;
        margin-bottom: 2px;
        padding: 6px;
        border-radius: 4px;
        cursor: pointer;


        &:hover {
            background-color: var(--lightGray);
        }

        &.lastItem {
            margin-bottom: 0;
        }

        &__image {
            width: 40px;
            height: 40px;
            min-width: 40px;
            border-radius: 7px;
            margin-right: 10px;

            & img {
                width: 100%;
                height: 100%;
            }
        }

        &__name {
            @include text_small_bold;
        }

        &__members {
            @include text_small_regular;
            color: $mediumGray;
        }

        &__loader {
            width: 100%;
            display: flex;
            justify-content: center;
            align-items: center;


            & svg {
                animation: rotation 1.5s infinite linear;

            }

            @keyframes rotation {
                from {
                    transform: rotate(0deg);
                }

                to {
                    transform: rotate(359deg);
                }
            }
        }

        &__empty {
            width: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
            @include text_small_regular;
            color: $mediumGray;
        }
    }
}