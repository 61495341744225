@import '../../../../../../../../styles/main.scss';

.medcheckSettings {
    &__underline{
        height: 1px;
        width: 100%;
        background-color: $gray;
        margin: 10px 0 15px;
    }
    
    &__label {
        @include text_small_bold;
        color: $darkGray;
        margin-bottom: 5px;

    }
    

    &__field {
        display: flex;
        flex-wrap: wrap;
        padding: 8px 8px 3px 8px;
        border: 1px solid $gray;
        box-shadow: 0px 3px 4px rgba(24, 24, 28, 0.05);
        border-radius: 7px;
        min-height: 44px;

        &.active {
            box-shadow: 0px 0px 0px 3px rgba(58, 108, 255, 0.2);
            border: 1px solid $blue;

        }

        &Input {
            border: none;
            outline: none;
            height: 0px;
            width: 0px;
            margin-bottom: 8px;
            @include text_small_regular;
            color: var(--black);

            &.active {
                height: 28px;
                width: auto;
                flex: 1;
            }

            &.full{
                width: 100%;
                height: 28px;
                margin-bottom: 5px;
            }
        }

        & > input{
            &::placeholder{
                color: $mediumGray;
            }
        }

        &Item {
            display: flex;
            align-items: center;
            height: 28px;
            padding: 0 4.46px 0 8px;
            @include text_small_bold;
            color: $darkGray;
            background-color: rgba(151, 157, 177, 0.2);
            border-radius: 5px;
            margin: 0 5px 5px 0;

            &> :last-child {
                display: flex;
                align-items: center;
                justify-content: center;
                margin-left: 4.46;
                cursor: pointer;
            }
        }
    }
}