@import '../../../styles/main.scss';

.codeConfirmation {
    width: 400px;

    @media (max-width: 600px) {
        width: 100%;
    }

    &__header {
        @include desktop_h1;
        color: $darkBlue;
        text-align: left;

        @media (max-width: 600px) {
            @include desktop_h2
        }
    }

    &__subtitle {
        @include text_medium_regular;
        color: $darkGray;
        text-align: left;
        margin-top: 10px;

        span {
            @include text_medium_bold;
        }
    }


    &__inputs {
        display: flex;
        justify-content: center;
        margin: 25px 0 0;
        position: relative;

        &Container {
            width: 100%;
            display: flex;
            justify-content: space-between;
        }


        input {
            width: 52px;
            height: 56px;
            border: 1px solid #DBDEE7;
            border-radius: 7px;
            background: #FFFFFF;
            text-align: center;
            outline: none;

            @include desktop_h2;

            &:focus {
                border: 1px solid #3A6CFF;

                box-shadow: 0px 0px 0px 3px rgba(58, 108, 255, 0.2);
            }
        }


        &.error {
            input {
                box-shadow: 0px 0px 0px 3px rgba(239, 47, 50, 0.2);
                border: 1px solid $red;
            }
        }
    }

    &__error{
        position: absolute;
        bottom: -25px;
        @include text_x_small_regular;
        color: $red;
    }

    &__loader {
        height: 70px;
        display: flex;
        align-items: center;
        justify-content: center;
        @include text_small_regular;
        color: $darkBlue;

        svg {
            animation: rotation 1.5s infinite linear;
            margin-right: 5px;
        }


        @keyframes rotation {
            from {
                transform: rotate(0deg);
            }

            to {
                transform: rotate(359deg);
            }
        }

    }

    &__resend {
        display: flex;
        justify-content: center;

        &Button {
            @include text_small_bold;
            color: $darkGray;
            text-decoration: underline;
            cursor: pointer;

            &.inactive{
                cursor: default;
                color: $gray
            }
        }

      
    }

    &__spamText {
        @include text_x_small_regular;
        color: $darkGray;
        margin-top: 20px;
        text-align: center;
    }
}