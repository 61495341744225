@import '../../../../../../../../styles/main.scss';
.itemSettings{
    
    &__field{
        margin-bottom: 10px;
    }

    &__underline{
        width: 100%;
        height: 1px;
        background-color: $gray;
        margin: 10px 0 15px;
    }
}