@import "../../../../styles/main.scss";

.organizationDrafts {
  display: flex;
  flex-direction: column;
  flex: 1;
  max-height: 100%;

  & > :last-child {
    margin-top: 10px;
  }

  &__name {
    flex: 2.95;
  }

  &__date {
    flex: 1.3;
  }

  &__action {
    flex: 1;
  }

  &__type {
    flex: 1;
  }

  &__status {
    flex: 2;
  }

  &__search {
    margin-bottom: 25px;
    display: flex;
    justify-content: space-between;
    align-items: center;

    &Right {
      display: flex;
      justify-content: center;
      align-items: center;
      margin-left: 30px;

      & > :first-child {
        margin-right: 10px;
      }
    }

    &Button {
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;
    }
  }

  &__title {
    @include desktop_h4;
    color: $mediumGray;
    margin-bottom: 10px;
  }

  &__content {
    flex: 1;
    padding-bottom: 25px;
    overflow: hidden;
  }

  @media (max-width: 1150px) {
    &__date {
      display: none;
    }
  }

  @media (max-width: 1050px) {
    &__type {
      display: none;
    }
  }

  @media (max-width: 850px) {
    &__action {
      display: none;
    }
  }

  @media (max-width: 767px) {
    &__search {
      margin-bottom: 20px;
    }
  }

  @media (max-width: 600px) {
    &__name {
      flex: 1.7;
    }

    &__status {
      flex: 1;
    }

    &__search {
      flex-direction: column;
      align-items: flex-start;

      &Right {
        margin-left: 0px;
        margin-top: 20px;
      }
    }
  }
}

.paginationLoader {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;

  & svg {
    animation: rotation 1.5s infinite linear;
  }

  @keyframes rotation {
    from {
      transform: rotate(0deg);
    }

    to {
      transform: rotate(359deg);
    }
  }
}

.pagination {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 25px;

  &__left {
    @include text_small_regular;
    color: $darkGray;

    span {
      @include text_small_bold;
      color: var(--black);
    }
  }
}
