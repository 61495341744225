@import '../../../../styles/main.scss';

.pdfViewer {
    display: flex;
    flex-direction: column;
    width: 100vw;
    height: 100vh;

    &__header {
        display: flex;
        align-items: center;
        height: 40px;
        margin-top: 32px;

        &Arrow {
            display: flex;
            justify-content: center;
            align-items: center;
            cursor: pointer;
            width: 28px;
            height: 28px;
            margin: 0 20px;
        }

        &Content {
            display: flex;
            justify-content: space-between;
            align-items: center;
            width: 100%;
            max-width: calc(100vw - 132px);
      
            & > p {
                @include desktop_h1;
            }
        }

        &Button{
            width: 140px;
        }

    }

    &__content {
        align-items: center;
        background-color: #eeeeee;
        border-bottom: 1px solid rgba(0, 0, 0, 0.1);
        display: flex;
        padding: 4px;

        
    }

    &__page{
    }
}

.pagination {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 25px;
    width: calc(100vw - 132px);
    max-width: calc(100vw - 132px);
    margin: 16px auto;

    &__left {
        @include text_small_regular;
        color: $darkGray;

        span {
            @include text_small_bold;
            color: var(--black);
        }
    }

    &__right {
        display: flex;
        align-items: center;

        &>:first-child {
            margin-right: 10px;
        }


        &Button {
            height: 44px;
            min-height: 44px;
            width: 44px;
            box-shadow: 0px 3px 4px rgba(24, 24, 28, 0.05);
            border-radius: 7px;
            border: 1px solid $gray;
            background-color: var(--white);
            display: flex;
            justify-content: center;
            align-items: center;
            cursor: pointer;

            &.disable{
                cursor: auto;
            }


            & .rotate {
                display: flex;
                justify-content: center;
                align-items: center;
                transform: rotate(180deg);
            }
        }
    }

    @media (max-width: 767px) {
        margin-top: 10px;
    }
}