@import '../../../../../styles/main.scss';

.adminItem {
    display: flex;
    min-height: 64px;
    padding: 5px 12px;
    align-items: center;
    position: relative;


    &:hover {
        background-color: var(--hoverColor);
    }

    &__user {
        flex: 1;
        display: flex;
        align-items: center;


        &Avatar {
            width: 40px;
            height: 40px;
            min-width: 40px;
            border-radius: 100px;
            margin-right: 10px;

            & img {
                width: 100%;
                height: 100%;
            }
        }

        &Right {
            width: 100%;
        }

        &Name {
            @include text_small_bold;
            max-width: 95%;

        }

        &Email {
            @include text_small_regular;
            max-width: 95%;
            color: $mediumGray;
        }
    }

    &__organizations {
        flex: 0.6;
        @include text_small_regular;
        color: $darkGray;

        &Short {
            display: none;
        }
    }

    &__date {
        flex: 0.6;
        @include text_small_regular;
        color: $darkGray;
        display: flex;
        align-items: center;
        justify-content: space-between;

        &Short {
            display: none;
        }
    }

    &__modal {
        width: 230px;
        padding: 10px;
        overflow: hidden;
        background-color: var(--white);
        border: 1px solid var(--lightGray);
        box-shadow: 0px 5px 60px rgba(0, 0, 0, 0.1);
        border-radius: 8px;
        transition: all 0.1s;
        -webkit-animation: anvil 0.15s cubic-bezier(0.38, 0.1, 0.36, 0.9) forwards;

        @keyframes anvil {
            0% {
                transform: scale(1) translateY(0px);
                opacity: 0;
            }

            1% {
                transform: scale(0.96) translateY(0px);
                opacity: 0;
            }

            100% {
                transform: scale(1) translateY(0px);
                opacity: 1;
            }
        }


        &Underline {
            width: 100%;
            height: 1px;
            background-color: $gray;
            margin: 7px 0;
        }

        &.bottom {
            height: auto;
        }

        &Item {
            height: 32px;
            display: flex;
            align-items: center;
            border-radius: 7px;
            @include text_small_regular;
            cursor: pointer;

            &:hover {
                background-color: var(--lightGray);
            }

            &.margin {
                margin: 2px 0;
            }

            svg {
                margin: 0 10px;
            }

            &Logo {
                display: flex;
                justify-content: center;
                align-items: center;
                position: absolute;
                right: 0;
                margin-right: 10px;

                animation: rotation 1.5s infinite linear;

                @keyframes rotation {
                    from {
                        transform: rotate(0deg);
                    }

                    to {
                        transform: rotate(359deg);
                    }
                }
            }
        }
    }

    &__underline {
        width: 100%;
        height: 1px;
        background-color: $gray;
    }

    @media (max-width: 970px) {

        &__user {
            &Avatar {
                width: 24px;
                height: 24px;
                min-width: 24px;
                margin-right: 5px;
            }

            &Email {
                @include text_x_small_regular;
            }
        }

        &__organizations {
            flex: 0.55;

            &Long {
                display: none;
            }

            &Short {
                display: block;
            }

            @include text_x_small_regular
        }

        &__date {
            flex: 0.4;

            &Long {
                display: none;
            }

            &Short {
                display: block;
            }

            @include text_x_small_regular
        }
    }


    @media (max-width: 470px) {
        &__organizations {
            display: none;
        }
    }
}