@import '../../../../styles/main.scss';

.folderItem{
    position: relative;
    min-height: 52px;
    display: flex;
    align-items: center;
    // justify-content: space-between;
    padding: 5px 12px;
    border-bottom: 1px solid $gray;
    @include text_small_bold;
    cursor: pointer;
    background-color: var(--white);

    &__full{
        flex: 2;
        justify-content: space-between;
    }

    &__active{
        background-color: $green10;
    }

    &__left{
        display: flex;
        align-items: center;
        flex: 0.7;

        & svg{
            margin-right: 20px;
            min-width: 20px;
            path {
                stroke: var(--black);
            }
        }
    }

    &__date{
        flex: 0.4;
         @include text_small_regular;
        color: $mediumGray;
    }

    &__author{
        flex: 1.3;
        display: flex;
        justify-content: space-between;
        align-items: center;

        @include text_small_regular;
        color: $darkGray;
    }

    &__importButton{
        width: 232px;
        min-width: 232px;
        height: 36px;
        border-radius: 7px;
        background-color: $mainGreen;
        display: flex;
        justify-content: center;
        align-items: center;
        @include text_small_bold;
        color: $white;
        cursor: pointer;
        margin-left: 10px;

        & svg {
            animation: rotation 1.5s infinite linear;
        }

        @keyframes rotation {
            from {
                transform: rotate(0deg);
            }

            to {
                transform: rotate(359deg);
            }
        }
    }

    &__modal {
        width: 230px;
        padding: 10px;
        overflow: hidden;
        background-color: var(--white);
        border: 1px solid var(--lightGray);
        box-shadow: 0px 5px 60px rgba(0, 0, 0, 0.1);
        border-radius: 8px;
        transition: all 0.1s;
        -webkit-animation: anvil 0.15s cubic-bezier(0.38, 0.1, 0.36, 0.9) forwards;

        @keyframes anvil {
            0% {
                transform: scale(1) translateY(0px);
                opacity: 0;
            }

            1% {
                transform: scale(0.96) translateY(0px);
                opacity: 0;
            }

            100% {
                transform: scale(1) translateY(0px);
                opacity: 1;
            }
        }


        &Underline {
            width: 100%;
            height: 1px;
            background-color: $gray;
            margin: 7px 0;
        }

        &.bottom {
            height: auto;
        }

        &Item {
            height: 32px;
            display: flex;
            align-items: center;
            border-radius: 7px;
            @include text_small_regular;
            cursor: pointer;

            &:hover {
                background-color: var(--lightGray);
            }

            &.margin {
                margin: 2px 0;
            }

            svg {
                margin: 0 10px;
            }
        }
    }

    @media (max-width: 1150px) {


        &__date {
            display: none;
        }

        &__author{
            flex: 1;
        }

        &__importButton{
            width: 100px;
            min-width: 100px;
            height: 40px;
            @include item_header_10;
            text-align: center;
            padding: 5px;
            word-break: initial;
        }
    }

    @media (max-width: 767px) {
        &__author{
            justify-content: flex-end;

            & > :first-child{
                display: none;
            }
          
        }
    }
}