@import '../../../../styles/main.scss';

.homePage {
    display: flex;
    flex-direction: column;
    flex: 1;
    max-height: 100%;

    &__inviteButton {
        width: 220px;
        min-width: 220px
    }

    &__organization {
        padding: 0;
        top: 64px;
        padding-bottom: 30px;

        &Block {
            padding: 36px;
            width: 820px;
        }
    }


    @media (max-width: 966px) {
        &__organization {
            &Content {
                width: 87vw;
                max-width: 87vw;
            }

            &Block {
                padding: 36px;
                width: 100%;
            }
        }
    }

    @media (max-width: 600px) {
        &__organization {
            top: 0;
            padding: 0;

            &Content {
                width: 100vw;
                max-width: 100vw;
                min-height: 100vh;
                height: fit-content;

            }

            &Block {
                padding: 0;

            }
        }
    }



    @media (max-width: 460px) {
        &__inviteButton {
            &Wrapper{
             display: none;
            }
         }
    }
}