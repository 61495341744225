@import '../../styles/main.scss';

.feedbackModal {
    width: 402px;

    &__title {
        @include desktop_h4;
    }

    &__subtitle {
        @include text_small_regular;
        color: $darkGray;
        margin: 5px 0 20px;

        &>span {
            @include text_small_bold;
            color: $mainGreen;
        }
    }

    &__field {
        margin-bottom: 15px;
    }

    &__buttons {
        display: flex;
        margin-top: 20px;

        &> :first-child {
            margin-right: 10px;
        }

        &>* {
            width: 100%;
        }
    }

    @media (max-width: 767px) {
        &__container {
            top: 50px
        }
    }

    @media (max-width: 500px) {
        width: calc(100vw - 95px);
    }
}