@import '../../../styles/main.scss';

.resetPassword {
    width: 400px;

    @media (max-width: 600px) {
        width: 100%;
    }

    &__header {
        @include desktop_h1;
        color: $darkBlue;
        text-align: left;

        @media (max-width: 600px) {
            @include desktop_h2
        }
    }

    &__subtitle {
        @include text_medium_regular;
        color: $darkGray;
        text-align: left;
        margin: 10px 0 25px;
    }

    &__button {
        margin: 25px 0;
    }

    &__return {
        display: flex;
        align-items: center;
        @include text_small_bold;
        color: $mainGreen;

        &Button {
            display: flex;
            align-items: center;
            cursor: pointer;
        }
    }

}