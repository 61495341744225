@import "../../../../styles/main.scss";

.modal {
  width: 542px;
  height: 50vh;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  &__title {
    @include desktop_h4;
    text-align: center;
    margin-bottom: 20px;
  }
  
  &__tableHeader {
    flex: 1;
  }

  &__header {
    display: flex;
    @include desktop_h2;
    color: $mediumGray;
    align-items: center;
    margin-bottom: 15px;

    &Icon {
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;

      & svg {
        width: 28px;
        height: 28px;
        margin-right: 15px;
      }
    }
  }

  &__buttons {
    display: flex;
    align-items: center;

    & > :first-child {
      margin-right: 10px;
    }

    & > * {
      flex: 1;
    }
  }

  @media (max-width: 630px) {
    width: calc(100vw - 90px);

    &__title {
      margin-top: 5px;
    }
  }
}
