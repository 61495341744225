.authProvider{
    width: 100vw;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;

    & > svg {
        width: 50px;
        height: 50px;

        animation: rotation 1.5s infinite linear;


        @keyframes rotation {
            from {
                transform: rotate(0deg);
            }

            to {
                transform: rotate(359deg);
            }
        }
    }
}