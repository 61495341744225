@import '../../../../../../styles/main.scss';


.pdfTab {
    flex: 1;
    padding-left: 68px;
    padding-right: 32px;
    overflow-y: auto;
    padding-bottom: 20px;
    padding-top: 5px;
    display: flex;
    justify-content: center;
    align-items: center;

    &__uploader {
        height: 213px;
        width: 100%;
        background-color: rgba(253, 187, 18, 0.1);
        border: 1px dashed #FDBB12;
        border-radius: 7px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        color: #FDBB12;
        flex-direction: column;
        @include desktop_h3;
        cursor: pointer;
        position: relative;
        margin-bottom: 100px;


        &Trash {
            position: absolute;
            top: 23px;
            right: 25.5px;
            cursor: pointer;

            & svg {
                width: 24px;
                height: 24px;
            }
        }

        &Download{
            position: absolute;
            top: 23px;
            left: 25.5px;

            cursor: pointer;

            & svg {
                width: 23px;
                height: 23px;
            }  
        }



        &Icon {
            & svg {
                width: 40px;
                height: 40px;
                margin-bottom: 15px;
            }
        }


        &Loader {
            display: flex;
            justify-content: center;
            align-items: center;
            animation: rotation 1.5s infinite linear;

            & svg {
                width: 40px;
                height: 40px;
            }


            @keyframes rotation {
                from {
                    transform: rotate(0deg);
                }

                to {
                    transform: rotate(359deg);
                }
            }

        }

        &Name {
            text-overflow: ellipsis;
            white-space: nowrap;
            overflow: hidden;
            max-width: 600px;
        }

        &.active {
            background-color: rgba(58, 108, 255, 0.1);
            border: 1px solid #3A6CFF;
            color: $blue;
            cursor: default;

            & a {
                color: $blue;
            }
        }
    }
}