@import '../../styles/main.scss';

.buttonContainer{
    &__loader{
        display: flex;
        justify-content: center;
        align-items: center;
        animation: rotation 1.5s infinite linear;
        position: absolute;

        &  :first-child{
            margin: 0px;

        }

        @keyframes rotation {
            from {
                transform: rotate(0deg);
            }

            to {
                transform: rotate(359deg);
            }
        }
    }

    .button {
        width: 100%;
        height: 44px;
        border-radius: 7px;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: 'row';
        -webkit-box-sizing: border-box;
        -moz-box-sizing: border-box;
        box-sizing: border-box;
        cursor: pointer;
        outline: none;
        border: 0;
        @include text_small_bold;
        color: #000000;

        &__green{
            background-color: $mainGreen;
            color: white;

            &:active{
                background-color: $mediumGreen;
            }

            &.disable{
                background-color: var(--lightGray);
                color: $mediumGray;
                cursor: default;
            }
        }

        .centeringBlock{
            display: flex;
            justify-content: center;
            align-items: center;
        }
        // &__content{
        //     display: flex;
        //     align-items: center;
        // }

        .hiddenBlock{
            opacity: 0;
            height: 0;
        }

        &__gray{
            background-color: $gray;
            color: $black;

            &:active{
                color:$darkGray;
            }
            
            &.disable{
                color: $mediumGray;
                cursor: default;
            }
        }

        &__white{
            background-color: white;
            color: black;

            &:active{
                color:$mediumGray;
            }

            &.disable{
                color: $gray;
                cursor: default;
            }
        }

        &.disable{
            cursor: default;
        }
    }
    
    
    .shadow {
        border: 1px solid #DBDEE7;
        box-shadow: 0px 3px 4px rgba(24, 24, 28, 0.05);
    }
    
    svg {
        margin-right: 10px;
    }
}
