@import '../../styles/main.scss';

.privacyModal {
    max-height: calc(100vh - 152px);
    height: calc(100vh - 152px);
    width: 800px;
    margin: 0 auto;
    overflow: hidden;
    // overflow-y: auto;

    &__content{
        width: 100%;
        // height: 100%;
        max-height: 100%;
        margin-top: 20px;
        overflow-y: auto;
        display: flex;
        justify-content: center;


        &Document{
            // width: 800px;
            // background-color: saddlebrown;
            // height: 100%;
            // display: flex;
            // flex-direction: column;

        }

        &Page{
            // background-color: darkcyan;
            // width: 1200px;
            // margin: 0 auto;
            
        }

    }

    &::-webkit-scrollbar {
        display: none;
    }


    @media (max-width: 968px) {
        width: 82.5vw
    }

    @media (max-width: 600px) {
        max-height: 100vh;
        width: 100%;
        padding: 20px 0;
    }

    &__modal {

        @media (max-width: 600px) {
            padding: 0 20px;
        }

        &Container {
            top: 50%;
            transform: translate(-50%, -50%);
        }
    }


    &__title {
        @include desktop_h3;
        margin: 20px 0;

        @media (max-width: 600px) {
            @include desktop_h4;
        }



        &Small {
            @include desktop_h4;
        }
    }

    &__text {
        @include text_medium_regular;
        color: $darkGray;
        ;
    }
}