@import '../../styles/main.scss';

.modal {
    width: 402px;

    &__title {
        @include desktop_h4;
    }

    &__subtitle {
        @include text_small_regular;
        color: $darkGray;
        margin: 5px 0 20px;
    }

    &__input {
        margin-bottom: 15px;
    }

    &__switchedField {
        margin-bottom: 10px;
    }

    &__popup {
        background-color: var(--lightGray);
        border-bottom-left-radius: 7px;
        border-bottom-right-radius: 7px;
        padding: 16px 16px 20px;
    }

    .openSwicher {
        border-bottom-left-radius: 0px;
        border-bottom-right-radius: 0px;
        border-bottom: 1px solid $gray;
    }

    &__buttons {
        margin-top: 20px;
        display: flex;

        &> :first-child {
            margin-right: 10px;
        }

        &>* {
            width: 100%;
        }
    }

    .searchInput {
        display: flex;
        flex-direction: row;
        height: 44px;
        border: 1px solid #DBDEE7;
        box-shadow: 0px 3px 4px rgba(24, 24, 28, 0.05);
        overflow: hidden;
        border-radius: 7px;
        align-items: center;
        background-color: var(--white);
        padding: 0 12px;
        margin-bottom: 15px;
        position: relative;
        z-index: 1;

        &__wrapper {
            position: relative;
        }

        &__label {
            color: $darkGray;
            @include text_small_bold;
            margin: 0;
            text-align: left;
            margin-bottom: 5px;
        }

        &__cross {
            width: 20px;
            height: 20px;
            min-width: 20px;
            border-radius: 100px;
            cursor: pointer;
            display: flex;
            justify-content: center;
            align-items: center;
            background-color: $mediumGray;

            & svg {
                width: 14px;
                height: 14px;
            }
        }


        input {
            width: 100%;
            border: none;
            outline: none;
            padding: 15px;

            &::placeholder {
                color: $mediumGray;
                @include text_small_regular;
            }
        }

        &:hover {
            cursor: text;
        }

        &.focus {
            box-shadow: 0px 0px 0px 3px rgba(58, 108, 255, 0.2);
            border: 1px solid $blue;
        }

        &.error {
            box-shadow: 0px 0px 0px 3px rgba(239, 47, 50, 0.2);
            border: 1px solid $red;
        }
    }

    .select {
        display: flex;
        flex-direction: column;
        position: absolute;

        width: 100%;
        top: calc(100% + 5px);
        border-radius: 8px;
        box-shadow: 0px 5px 60px rgba(0, 0, 0, 0.1);
        max-height: 292px;

        transition: all 0.1s;
        background-color: var(--white);
        overflow: hidden;
        z-index: 30;

        &.rotate {
            top: auto;
            bottom: calc(100% + 5px);
        }

        &Block {
            width: 100%;
            border: 1px solid var(--lightGray);
            padding: 10px 10px;
            overflow-y: auto;
            border-radius: 8px;
        }

        &.close {
            height: 0;
        }
    }

    .item {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        height: 32px;
        min-height: 32px;
        padding: 0 10px;
        cursor: pointer;
        @include text_small_regular;
        border-radius: 7px;

        color: var(--black);

        &:hover {
            background-color: var(--lightGray);
        }
    }

    @media (max-width: 767px) {
        &__container {
            top: 27px;
        }
    }

    @media (max-width: 500px) {
        width: calc(100vw - 95px);
    }
}