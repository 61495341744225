@import '../../../../../../styles/main.scss';

.modal{
    width: 542px;

    & > svg{
        width: 48px;
        height: 48px
    }

    &__title{
        @include text_small_regular;
        color: $darkGray;
        margin-bottom: 20px;
    }


    &__buttons{
        display: flex;
        align-items: center;
        & > :first-child{
            margin-right: 10px;
        }

        & > *{
            flex: 1;
        }
    }

    @media (max-width: 630px){
        width: calc(100vw - 90px);
        &__title{
            margin-top: 5px;
        }
    }
    
}