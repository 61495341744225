@import '../../../../../styles/main.scss';

.organizationModule {
    width: 248px;
    background-color: var(--white);
    align-self: center;
    box-shadow: 0px 3px 4px rgba(24, 24, 28, 0.05);
    border-radius: 7px;
    display: flex;
    padding: 15px 0 2px 0;
    flex-direction: column;
    align-items: center;
    position: relative;
    cursor: pointer;


    &__logo {
        width: 24px;
        height: 24px;
        min-width: 24px;
        border-radius: 7px;
        overflow: hidden;

        &>img {
            width: 100%;
            height: 100%
        }
    }

    &__emptyText {
        height: 22px;
        display: flex;
        justify-content: center;
        align-items: center;
        @include text_small_regular;
        color: $mediumGray;
        margin-bottom: 3px;
    }

    &__content {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: calc(100% - 20px);
        margin-bottom: 3px;

        &Canter {
            width: 100%;
            margin: 0 10px;
        }
    }

    &__title {
        @include text_small_bold;
    }

    &__subtitle {
        @include text_x_small_regular;
        color: $darkGray;
    }

    &__counter {
        width: 22px;
        height: 22px;
        background: rgba(239, 47, 50, 0.1);
        border-radius: 4px;
        display: flex;
        justify-content: center;
        align-items: center;
        @include text_x_small_bold;
        color: $red;
    }

    &__item {
        position: relative;
        display: flex;
        align-items: center;
        flex-grow: 1;
        min-width: 100%;
        height: 36px;
        padding: 0 14px 0 20px;
        margin-bottom: 15px;
        position: relative;
        cursor: pointer;
        

        &Indicator {
            height: 24px;
            width: 4px;
            border-radius: 4px;
            background-color: $mainGreen;
            position: absolute;
            left: 8px;
        }

        &Icon {
            width: 32px;
            height: 32px;
            min-width: 32px;
            border-radius: 7px;
            background-color: $darkGray;
            overflow: hidden;

            &>img {
                width: 100%;
            }
        }

        &Center {
            width: 100%;
            margin: 0 10px;
        }

        svg {
            cursor: pointer
        }

        &Title {
            @include text_small_bold
        }

        &Members {
            @include text_x_small_regular;
            color: $darkGray;
        }

        &Popup {
            width: 168px;
            height: 39px;
            border: 1px solid var(--lightGray);
            box-shadow: 0px 5px 60px rgba(0, 0, 0, 0.1);
            border-radius: 8px;
            @include text_small_regular;
            position: absolute;
            background-color: var(--white);
            top: 6px;
            right: 7px;
            display: flex;
            justify-content: center;
            align-items: center;
        }
    }

    &__button {
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 370px;
        height: 32px;
        @include text_small_bold;
        color: $mediumGray;
        cursor: pointer;

        &Line {
            width: 370px;
            height: 1px;
            background-color: $gray;
            margin: 7px 0;
        }

        &Notification {
            display: flex;
            align-items: center;
            color: $red;
            cursor: pointer;

            svg {
                margin-right: 10px;
            }
        }

        &marginBottom0 {
            margin-bottom: 0;
        }
    }

    @media (max-width: 900px) {
        width: 236px;
        min-height: 68px;
        padding-bottom: 15px;
        &__logo {
            width: 32px;
            height: 32px;
            min-width: 32px;
        }

        & > svg{
            display: none;
        }
    }

    @media (max-width: 767px) {
        padding: 16px 0;

        & > svg{
            display: none;
        }

        &__logo {
            width: 32px;
            height: 32px;
            min-width: 32px;
        }


        &__content {
            &Canter {
                margin: 0 16px;
            }
        }
    }
}


.modalSmall {
    margin-top: -65px;
    width: 300px;
    overflow: hidden;
    max-width: 300px;
    background-color: var(--white);
    border-radius: 8px;
    box-shadow: 0px 5px 60px rgba(0, 0, 0, 0.1);
    display: flex;
    flex-direction: column;
    align-items: center;
    cursor: default;
    transition: all 0.1s;
    z-index: 10;


    @keyframes anvil {
        0% {
            transform: scale(1) translateY(0px);
            opacity: 0;
        }

        1% {
            transform: scale(0.96) translateY(-10px);
            opacity: 0;
        }

        100% {
            transform: scale(1) translateY(0px);
            opacity: 1;
        }
    }
    -webkit-animation: anvil 0.15s cubic-bezier(0.38, 0.1, 0.36, 0.9) forwards;



    &__icon {
        width: 76px;
        height: 76px;
        border-radius: 7px;
        background-color: $darkGray;
        overflow: hidden;

        &>img {
            width: 100%;
            height: 100%
        }
    }

    &__title {
        @include desktop_h3;
        margin: 20px 0 5px;
    }

    &__members {
        @include text_small_regular
    }

    &__button {
        margin: 20px 0;
        height: 36px;
        max-height: 36px;
        min-width: 260px;
    }

    &__item {
        height: 36px;
        max-height: 36px;
        min-width: 260px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        @include text_small_bold;
        color: $mediumGray;
        cursor: pointer;
    }

    &__underline {
        height: 1px;
        background-color: $gray;
        width: 100%
    }

    &__subtitle {
        @include item_header_10;
        text-transform: uppercase;
        color: $mediumGray;
        text-align: left;
        top: 20px;
        left: 20px;
        margin-top: 20px;
        width: 100%;
        padding-left: 20px
    }

    &__emptyText {
        @include text_small_regular;
        color: $mediumGray;
        margin-top: 15px;
        display: flex;
        justify-content: center;
        align-items: center;
        height: 40px;
    }

    &__organiizations {
        width: 100%;
        min-width: 100%;
        max-height: 170px;
        overflow: hidden;
        overflow-y: scroll;
    }
}