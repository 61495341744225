@import '../../../../styles/main.scss';

.pdfItem {
    position: relative;
    background-color: var(--white);

    &__horizontal {
        display: flex;
        min-height: 52px;
        padding: 5px 12px;
        align-items: center;
        position: relative;


        &:hover {
            background-color: var(--hoverColor);
        }

        &Name {
            display: flex;
            align-items: center;
            @include text_small_bold;
            flex: 0.7;
            padding-right: 10px;
            word-break: break-all;

            svg {
                margin-right: 20px;
                min-width: 20px
            }
        }

        &Date {
            @include text_small_regular;
            text-align: left;
            flex: 0.4;
            @include text_small_regular;
            color: $mediumGray;
        }


        &Author {
            display: flex;
            justify-content: space-between;
            align-items: center;
            flex: 1.3;
            @include text_small_regular;
            color: $darkGray;


            &Button {
                width: 232px;
                min-width: 232px;
                height: 36px;
                border-radius: 7px;
                background-color: $mainGreen;
                display: flex;
                justify-content: center;
                align-items: center;
                @include text_small_bold;
                color: var(--white);
                cursor: pointer;
                margin-left: 10px;

             
            }

            & svg {
                animation: rotation 1.5s infinite linear;

            }



            @keyframes rotation {
                from {
                    transform: rotate(0deg);
                }

                to {
                    transform: rotate(359deg);
                }
            }

        }
    }

    &__underline {
        width: 100%;
        height: 1px;
        background-color: $gray;
    }

    @media (max-width: 1150px) {
        &__horizontal {

            &Date {
                display: none;
            }

            &Author {
                word-break: break-all;
                flex: 1;

                &Button {
                    width: 100px;
                    min-width: 100px;
                    height: 40px;
                    @include item_header_10;
                    text-align: center;
                    padding: 5px;
                    word-break: initial;
                }
            }
        }
    }



    @media (max-width: 900px) {
        &__horizontal {
            &Name {
                @include text_x_small_bold;
                padding-right: 5px;

                svg {
                    margin-right: 10px;
                }
            }

            &Author {
                @include text_x_small_regular;
            }
        }

    }


    @media (max-width: 767px) {
        &__horizontal {

            &Author {
                flex: 0.4;
                justify-content: flex-end;

                &>p {
                    display: none;
                }
            }
        }

    }

    @media (max-width: 600px) {
        &__horizontal {

            &Name {
                flex: 1.7;

                svg {
                    margin-right: 5px;
                }
            }

            &Status {
                flex: 1;

            }
        }
    }
}