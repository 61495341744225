@import '../../../../styles/main.scss';

.inviteMembers {

    &__title {
        @include desktop_h1;
        color: $darkBlue;
        text-align: left;

        @media (max-width: 767px) {
            @include desktop_h2
        }
    }

    &__subtitle {
        @include text_medium_regular;
        color: $darkGray;
        margin: 15px 0 25px;
        text-align: left;
    }

    &__row {
        display: flex;
        align-items: center;
        align-items: flex-end;
        margin-top: 15px;

        &Input {
            width: 397px;
        }

        &Dropdown {
            width: 203px;
            margin: 0 15px 0 20px;

            @media (max-width: 600px) {
                margin: 0 10px 0;
            }
        }

        &Number {
            height: 44px;
            display: flex;
            justify-content: center;
            align-items: center;
            width: 30px;
            min-width: 30px;
            margin-right: 15px;
            @include text_medium_bold;

            @media (max-width: 767px) {
                width: 21px;
                min-width: 21px;
                margin-right: 10px;
            }
        }

        &Icon {
            height: 44px;
            display: flex;
            justify-content: center;
            align-items: center;

            svg {
                cursor: pointer;
            }
        }
    }

    &__addButton {
        width: 143px;
        margin: 15px 0 0 45px;

        @media (max-width: 767px) {
            margin-left: 31px;
        }
    }

    &__buttons {
        display: flex;
        justify-content: flex-end;
        margin-top: 70px;

        @media (max-width: 600px) {
            justify-content: space-between;
            margin-top: 25px;
        }


        &>* {
            width: 200px;

            @media (max-width: 600px) {
                width: 50%;
            }
        }

        & > :first-child {
            margin-right: 10px;

            @media (max-width: 600px) {
                margin-right: 30px;
            }
        }
    }
}