@import '../../styles/main.scss';

.pageHeader {
    display: flex;
    align-items: center;
    height: 32px;
    min-height: 32px;
    overflow-x: auto;
    &::-webkit-scrollbar {display:none;}



    &__item {
        min-width: fit-content;
        position: relative;
        display: flex;
        align-items: center;
        padding: 0 22px;
        height: 100%;
        cursor: pointer;
        border-bottom: 1px solid $gray;

        &.active {
            color: $mainGreen;
            border-bottom: 2px solid $mainGreen;
            padding-top: 1px;


        }

        

      

        @include text_small_bold;
        color: $mediumGray;

        &Counter {
            margin-left: 8px;
        }

        &Left {
            margin-right: 8px;
            display: flex;
            justify-content: center;
            align-items: center;
        }

        &Right {
            margin-left: 8px;
            display: flex;
            justify-content: center;
            align-items: center;
        }
    }

    &__underline{
        width: 100%;
        height: 100%;border-bottom: 1px solid $gray;
    }
}