@import '../../../../styles/main.scss';

.sentRequest {
    display: flex;
    height: 64px;
    align-items: center;
    flex: 1;
    @include text_small_regular;
    color: $darkGray;
    text-align: left;
    padding: 0 10px;

    &:hover {
        background-color: var(--hoverColor);
    }

    &__search {
        margin: 25px 0;
        display: flex;
        align-items: center;
        gap: 15px;
    }

    &__organization {
        display: flex;
        align-items: center;
        flex: 3;
        @include text_small_bold;
        color: var(--black);

        &Header {
            flex: 3;
        }

        &Avatar {
            width: 40px;
            height: 40px;
            border-radius: 7px;
            margin-right: 10px;
            overflow: hidden;

            &>img {
                width: 100%;
                height: 100%;
            }
        }
    }

    &__members {
        &Header {
            flex: 1;
        }

        display: flex;
        align-items: center;
        flex: 1;
        height: 100%;
        text-align: left;

        &Counter {
            margin-left: 8px;
        }
    }

    &__role {
        &Header {
            flex: 2;
        }

        flex: 2;
        display: flex;
        align-items: center;
        justify-content: space-between;


        &Right {
            display: flex;
            align-items: center;

        }

        &Button {
            width: 147px;
            height: 36px;
            border-radius: 7px;
            border: 1px solid #DBDEE7;
            box-shadow: 0px 3px 4px rgba(24, 24, 28, 0.05);
            cursor: pointer;
            display: flex;
            justify-content: center;
            align-items: center;
            @include text_small_bold;


            &>span {
                display: none;
            }

            &.showText {
                &:after {
                    visibility: visible;
                    content: "Cancel request";
                    color: var(--black);
                }
            }

            &>svg {
                animation: rotation 1.5s infinite linear;
            }


            @keyframes rotation {
                from {
                    transform: rotate(0deg);
                }

                to {
                    transform: rotate(359deg);
                }
            }


        }

        svg {
            cursor: pointer;
        }
    }

    &__underline {
        height: 1px;
        width: 100%;
        background-color: $gray;
    }


    @media (max-width: 1140px) {
        &__organization {
            flex: 2.2;

            &Header {
                flex: 2.2;
            }
        }

        &__members {
            flex: 1.2;

            &Header {
                flex: 1.2;
            }
        }

        &__role {
            flex: 2;

            &Header {
                flex: 2;
            }
        }
    }


    @media (max-width: 1010px) {
        &__members {
            display: none;

            &Header {
                display: none;
            }
        }
    }

    @media (max-width: 924px) {

        &__role {
            &Button {
                width: 71px;
                max-width: 71px;
                height: 28px;
                margin-left: 5px;

                &.showText {
                    &:after {
                        visibility: visible;
                        content: "Cancel";
                        color: var(--black);
                    }
                }

            }
        }
    }

    @media (max-width: 900px) {
        &__organization {

            &Avatar {
                width: 24px;
                height: 24px;
                min-width: 24px;
            }
        }

        &__role {
            word-break: break-word;
        }
    }


    @media (max-width: 767px) {
        &__search {
            margin: 20px 0;
        }

        &__role {
            @include text_x_small_regular;
        }
    }

    @media (max-width: 470px) {

        &__organization {

            &Avatar {
                width: 24px;
                height: 24px;
            }
        }
    }
}