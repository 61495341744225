@import '../../../../../../../../styles/main.scss';

.medcheck {

    &__inputs{
        display: flex;
        

        & > * {
            flex: 1;
        }

        & > :first-child{
            margin-right: 10px;
        }
    }

    &__buttons{
        display: flex;
        justify-content: space-between;
        margin-top: 10px;
        padding: 10px 0;
        & > * {
            width: 120px;
        }
    }

    &__result{
        @include text_small_bold;

        &.red{
            color: $red
        }


        &.green{
            color: $mainGreen
        }

        &.orange{
            color: $orange;
        }
    }


    
}