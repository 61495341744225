@import '../../../../../../../styles/main.scss';

.popupLarge {
    margin-top: -80px;
    width: 410px;
    overflow: hidden;
    max-width: 410px;
    background-color: var(--white);
    border-radius: 8px;
    box-shadow: 0px 5px 60px rgba(0, 0, 0, 0.1);
    display: flex;
    flex-direction: column;
    align-items: center;
    cursor: default;
    padding: 25px 0 0;
    transition: all 0.1s;

    @keyframes anvil {
        0% {
            transform: scale(1) translateY(0px);
            opacity: 0;
        }

        1% {
            transform: scale(0.96) translateY(-10px);
            opacity: 0;
        }

        100% {
            transform: scale(1) translateY(0px);
            opacity: 1;
        }
    }
    -webkit-animation: anvil 0.15s cubic-bezier(0.38, 0.1, 0.36, 0.9) forwards;


    &__icon {
        width: 76px;
        height: 76px;
        border-radius: 7px;
        overflow: hidden;
        @include desktop_h3;

        &>img {
            width: 100%;
            height: 100%
        }
    }

    &__title {
        @include desktop_h3;
        margin: 20px 0 5px;
    }

    &__members {
        @include text_small_regular;
        color: $darkGray;
    }

    &__button {
        margin: 20px 0;
        height: 36px;
        max-height: 36px;
        min-width: 370px;

        .disable{
            button {
                cursor: none !important;
                background-color: red ;
            }
        }

        

        &>button {
            outline: none;
            border: none;

            &:focus {
                outline: none;
                border: none;
            }
        }
    }

    &__underline {
        height: 1px;
        background-color: $gray;
        width: 100%
    }

    &__subtitle {
        @include item_header_10;
        text-transform: uppercase;
        color: $mediumGray;
        width: 100%;
        margin: 18px 0 15px 20px;
    }

    &__organiizations {
        width: 100%;
        min-width: 100%;
        max-height: 170px;
        overflow: hidden;
        overflow-y: scroll;

        &.hideScrollbar{
            &::-webkit-scrollbar {
                display: none;
            }
        }
      
    }


    @media (max-width: 767px) {
        width: 310px;

        &__icon {
            width: 57px;
            height: 57px;
            border-radius: 7px;
        }


        &__title {
            @include text_large_bold;
            margin: 15px 0 3.75px;
        }

        &__members {
            @include text_x_small_regular
        }

        &__button {
            margin: 15px 0;
            height: 28px;
            max-height: 28px;
            min-width: 280px;
        }

        &__subtitle {
            font-family: $inter-font;
            font-size: 8px;
            line-height: 10px;
            font-weight: 500;
            margin: 14px 0 11.29px 16px;
        }

        &__organiizations {
            max-height: 128.54px;
        }
    }
}


.organizationModule__button {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 370px;
    height: 32px;
    @include text_small_bold;
    color: $mediumGray;
    cursor: pointer;

    &Line {
        width: 370px;
        height: 1px;
        background-color: $gray;
        margin: 7px 0;
    }

    &Notification {
        display: flex;
        align-items: center;
        color: $red;

        svg {
            margin-right: 10px;
        }
    }

    &marginBottom0 {
        margin-bottom: 0;
    }

    @media (max-width: 767px) {
        width: 280px;
        height: 24.2px;

        &Line{
            margin: 5px 0;
        }
    }


}