@import '../../../../../../../../styles/main.scss';

.textBox {
    flex: 1;
    padding: 10px 0;
    overflow-x: auto;

    &__noData {
        @include text_small_regular;
        color: $mediumGray;
        display: flex;
        justify-content: center;
        align-items: center;
        padding-top: 7px;
        flex: 1;
    }
}