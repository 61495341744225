@import '../../styles/main.scss';

.acceptOwnership {
    width: 402px;
    &__title {
        @include desktop_h4;
    }

    &__subtitle {
        @include text_small_regular;
        color: $darkGray;
        margin: 5px 0 20px;
    }

    &__row {
        display: flex;
        align-items: center;
        margin: 20px 0;
    }

    &__image {
        width: 40px;
        height: 40px;
        border-radius: 7px;
        overflow: hidden;
        background-color: $green10;
        margin-right: 11px;

        & > img{
            width: 100%;
            height: 100%
        }
    }

    &__name {
        @include text_small_bold;
    }

    &__input {
        margin-bottom: 15px;
    }

    &__buttons {
        display: flex;
        margin-top: 20px;

        &> :first-child {
            margin-right: 10px;
            border: 1px solid $gray;
            border-radius: 7px;
        }

        &>* {
            width: auto;
            flex: 1;
        }
    }


}