@import '../../../../../../../../../styles/main.scss';

.stopwatch {
    display: flex;
    flex-direction: column;

    &__row {
        display: flex;
        align-items: center;
        flex: 1;
    }

    &__content {
        display: flex;
        align-items: center;
        justify-content: space-between;
        flex: 1;
        margin: 10px 0;

        font-family: $inter-font;
        font-size: 16px;
        line-height: 20px;
        font-weight: 600;

        &Right {
            display: flex;
            align-items: center;
            justify-content: flex-end;
            flex: 1;
        }

        &Icon {
            width: 40px;
            height: 40px;
            border-radius: 7px;
            background-color: $green20;
            display: flex;
            justify-content: center;
            align-items: center;
            margin-right: 15px;
        }

        &Button {
            width: 44px;

            & svg {
                margin: 0;
            }
        }

        &Reset {
            width: 79px;
            margin: 0 10px;
        }
    }
}