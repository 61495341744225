@import '../../styles/main.scss';


.avatar{
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;

}

.initials{
    display: flex;
    justify-content: center;
    align-items: center;
    @include text_small_bold;
    border: 1px solid $gray
}