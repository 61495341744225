@import '../../../../../../../../styles/main.scss';

.metronomeSettings {

    &__underline{
        height: 1px;
        width: 100%;
        background-color: $gray;
        margin: 10px 0 15px;
    }
    

    display: flex;
    height: 40px;
    background: #F5F5F7;
    border-radius: 7px;
    padding: 0 4px;
    align-items: center;
    position: relative;
    transition: all 0.2s;


    &>* {
        flex: 1;
        height: 32px;
        display: flex;
        justify-content: center;
        align-items: center;
        @include text_small_bold;
        color: $darkGray;
        border-radius: 5px;
        cursor: pointer;
        position: relative;
        z-index: 200px;
        transition: all 0.2s;

    }

    &__bg{
        width: 50%;
        height: 32px;
        background-color: var(--white);
        position: absolute;
        box-shadow: 0px 3px 4px rgba(24, 24, 28, 0.05);
        transition: all 0.2s;
        left: 4px;

        &.right{
            transition: all 0.3s;
            left: calc(50% - 4px);
        }
    }

    & .active {
        color: var(--black);
    }


}