@import '../../../../../../../../../styles/main.scss';

.timeMarker {
    flex: 1;
    display: flex;
    flex-direction: column;
    padding: 10px 0;

    &__markButtons{
        width: min-content;
        display: grid;
        grid-template-columns: 1fr 1fr;
        grid-column-gap: 15px;
        grid-row-gap: 10px;
        margin-bottom: 10px;
        align-self: center;
    }

    &__markButton{
        width: 240px;
        max-width: 240px;
    }

    &__buttons{
        display: flex;
        align-self: flex-end;
    }

    &__returnButton {
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: default;
        width: 44px;
        height: 44px;
        border: 1px solid #DBDEE7;
        box-shadow: 0px 3px 4px rgba(24, 24, 28, 0.05);
        border-radius: 7px;
    }

    &__resetButton {
        width: 120px;
        margin-left: 15px;
    }

    &__markButton {
        min-width: 120px;
        & button{
            padding: 0 15px;
        }
    }

}