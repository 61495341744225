@import '../../styles/main.scss';

.pagination {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 25px;

    &__left {
        @include text_small_regular;
        color: $darkGray;

        span {
            @include text_small_bold;
            color: var(--black);
        }
    }

    &__right {
        display: flex;
        align-items: center;

        &>:first-child {
            margin-right: 10px;
        }


        &Button {
            height: 44px;
            min-height: 44px;
            width: 44px;
            box-shadow: 0px 3px 4px rgba(24, 24, 28, 0.05);
            border-radius: 7px;
            border: 1px solid $gray;
            background-color: var(--white);
            display: flex;
            justify-content: center;
            align-items: center;
            cursor: pointer;

            &.disable{
                cursor: auto;
            }


            & .rotate {
                display: flex;
                justify-content: center;
                align-items: center;
                transform: rotate(180deg);
            }
        }
    }

    @media (max-width: 767px) {
        margin-top: 10px;
    }
}