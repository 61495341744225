$inter-font: "Inter", "sans-serif";
$white: #ffffff;
$black: #0b0f1c;
$darkBlue: #152659;
$blue: #3a6cff;

$lightGray: hsl(240, 11%, 96%);
$gray: #dbdee7;
$darkGray: #616a85;
$mediumGray: #979db1;
$mainGreen: #29b473;
$mediumGreen: #56c993;
$green20: rgba(41, 180, 115, 0.2);
$green10: rgba(41, 180, 115, 0.1);
$red: #ef2f32;
$orange: #fdbb12;

$red09: rgba(252, 213, 214, 0.9);

:root {
  --white: #ffffff;
  --black: #0b0f1c;
  --lightGray: hsl(240, 11%, 96%);
  --hoverColor: #FAFAFA;
  --mediumGray: #DBDEE7;
  --darkBlue: #152659;
}

body.dark-mode {
  --white: #000000;
  --black: #dfe9f2;
  --lightGray: #1b1c1d;
  --hoverColor: #38393D;
  --mediumGray:  #616A85;
  --darkBlue: #DFE9F2;
}