@import '../../styles/main.scss';

.modalBlock {
    position: absolute;
    left: 0;
    top: 0;
    left: 50%;
    top: 127px;
    transform: translate(-50%);
    z-index: 100;
    transition: none;
    opacity: 1;

   

    &__overlay {
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        inset: 0px;
        background-color: rgba(11, 15, 28, 0.6);
        outline: none;
        transition: none;
        opacity: 1;
        z-index: 10;
    }

    &__modal {
        background-color: var(--white);
        padding: 24px;
        border-radius: 15px;
        box-shadow: 0px 5px 60px rgba(0, 0, 0, 0.1);


      


        &Icon {
            position: absolute;
            right: 14px;
            top: 14px;
            cursor: pointer;
        }
    }

    @media (max-width: 600px) {

        &.fullScreen{
            top: 0;
        }
       
    }


    @media (max-width: 600px) {
        &__modal{

            &.fullScreen{
                width: 100vw;
                height: 100vh;
                border-radius: 0;
                padding: 20px;
                box-shadow: 0
            }
          
        }
    }
}