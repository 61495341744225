@import '../../../../styles/main.scss';

.copyIconWrapper {
  display: flex;
  justify-content: center;
  padding-top: 0.5rem;
  padding-left: 1.5rem;
  padding-right: 2.5rem;
}

.activeChecklist {
    display: flex;
    min-height: 52px;
    padding: 5px 12px;
    align-items: center;
    position: relative;
    background-color: var(--white);

    &:hover {
        background-color: var(--hoverColor);
    }

    &__name {
        display: flex;
        align-items: center;
        @include text_small_bold;
        flex: 1;
        padding-right: 10px;
        word-break: break-all;

        svg {
            min-width: 20px;
            margin-right: 20px;
        }
    }

    &> :nth-child(2) {
        min-width: 92px;

        & button {
            height: 36px;
        }
    }

    &__underline {
        width: 100%;
        height: 1px;
        background-color: $gray;
    }

    @media (max-width: 900px) {
        &__name {
            @include text_x_small_bold;

            svg {
                margin-right: 10px;
            }
        }
    }

    @media (max-width: 600px) {
        &> :nth-child(2) {
            min-width: 80px;

            & button {
                height: 28px;
            }

            & svg {
                margin-right: 5px;
            }
        }
    }
}
