@import '../../styles/main.scss';

.deleteUserModal {

    &__container {
        padding-bottom: 30px;
    }

    &__loading {
        width: 542px;
        height: 158px;
        display: flex;
        justify-content: center;
        align-items: center;

        &>svg {
            animation: rotation 1.5s infinite linear;
            width: 36px;
            height: 36px;
        }

        @keyframes rotation {
            from {
                transform: rotate(0deg);
            }

            to {
                transform: rotate(359deg);
            }
        }

    }

    &__warning {
        width: 542px;

        &Buttons {
            display: flex;
            flex-direction: row;
            justify-content: space-between;

            &> :first-child {
                margin-right: 10px
            }

            &>* {
                flex: 1;
            }
        }
    }

    &__transferring {
        width: 700px;

        &Buttons {
            display: flex;
            flex-direction: row;
            justify-content: flex-end;
            margin-top: 70px;

            &> :first-child {
                margin-right: 10px
            }

            &>* {
                width: 200px;
            }
        }
    }

    &__deleting {
        width: 542px;

        &Title {
            @include desktop_h4;
            text-align: center;
        }

        &Subtitle {
            @include text_medium_regular;
            color: $darkGray;
            text-align: center;
            margin: 20px 0;
        }



        &Buttons {
            display: flex;
            align-items: center;

            &> :first-child {
                margin-right: 10px;
            }

            &>* {
                flex: 1;
            }
        }
    }

    &>svg {
        margin-bottom: 10px
    }

    &__title {
        @include desktop_h4;
    }

    &__subtitle {
        @include text_small_regular;
        color: $darkGray;
        margin: 5px 0 20px;
    }

    &__row {
        display: flex;
        margin-bottom: 10px;

        &Title {
            @include text_small_bold;
            display: none;
        }

        &>* {
            width: auto;
            flex: 1;
        }

        &> :first-child {
            margin-right: 20px;
        }
    }

    &__input {
        margin: 15px 0 20px;
    }

    @media (max-width: 790px) {
        &__transferring {
            width: calc(100vw - 90px);
        }

    }

    @media (max-width: 620px) {
        &__loading {
            width: calc(100vw - 90px);
        }

        &__warning {
            width: calc(100vw - 90px);
        }

        &__deleting {
            width: calc(100vw - 90px);

            &__title {
                margin-top: 5px;
            }
        }
    }

    @media (max-width: 600px) {
        &__transferring {
            &Buttons {
                margin-top: 20px;

                & button {
                    max-height: 35px;
                }
            }
        }

        &__row {
            flex-direction: column;

            &Name {
                display: none;
            }

            &Email {
                & .label {
                    display: none;
                }
            }

            &Title {
                display: block;
                margin-bottom: 5px;
            }
        }
    }

    @media (max-width: 425px) {
        &__warning {
            &Buttons {
                &> :first-child {
                    margin: 0 0 10px;
                }

                flex-direction: column;
            }
        }
    }
}