@import '../../../../../../styles/main.scss';

.settings{
    width: 500px;
    min-width: 500px;
    border-left: 1px solid $gray;
    padding: 36px 20px;
    overflow-y: auto;


    &__empty{
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        text-align: center;
        color: $mediumGray;
        @include text_small_regular;


        & > svg{
            margin-bottom: 10px;
        }
    }

    &.empty{
        display: flex;
        justify-content: center;
        align-items: center;
    }
}