@import '../../../../styles/main.scss';

.modal {
    width: 700px;

    &__title {
        @include desktop_h3;
        color: $darkBlue;
    }

    &__field {
        display: flex;
        justify-content: space-between;
        align-items: center;
        @include text_small_regular;
        color: $darkGray;
        margin-top: 22px;

        &Icon {
            display: flex;
            justify-content: center;
            align-items: center;
            cursor: pointer;
        }
    }

    &__underline {
        height: 1px;
        background-color: $gray;
        margin: 15px 0 20px;
    }
}