@import '../../../../styles/main.scss';

.helpAndFeedback{
    display: flex;
    flex-direction: column;
    flex: 1;
    max-height: 100%;

    &__search{
        margin-bottom: 25px;
    }

    &__reporter{
        flex: 1;
    }

    &__category{
        flex: 1;
    }

    &__date{
        flex: 1.2;
    }

}

