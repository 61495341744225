@import "vars";

//DESKTOP-HEADING
@mixin desktop_h1 {
    font-family: $inter-font;
    font-style: normal;
    font-weight: 700;
    font-size: 36px;
    line-height: 40px;
}

@mixin desktop_h2 {
    font-family: $inter-font;
    font-style: normal;
    font-size: 28px;
    font-weight: 700;
    line-height: 32px;

}

@mixin desktop_h3 {
    font-family: $inter-font;
    font-style: normal;
    font-size: 24px;
    font-weight: 700;
    line-height: 28px;
}

@mixin desktop_h4 {
    font-family: $inter-font;
    font-style: normal;
    font-size: 18px;
    font-weight: 700;
    line-height: 24px;
}

@mixin desktop_h5 {
    font-family: $inter-font;
    font-style: normal;
    font-size: 14px;
    font-weight: 700;
    line-height: 18px;
}





//MOBILE-HEADING
@mixin mobile_h1 {
    font-family: $inter-font;
    font-style: normal;
    font-weight: 700;
    font-size: 28px;
    line-height: 32px;
}


//TEXT
@mixin text_small_bold {
    font-family: $inter-font;
    font-style: normal;
    font-size: 14px;
    font-weight: 600;
    line-height: 20px;
}


@mixin text_x_small_bold {
    font-family: $inter-font;
    font-style: normal;
    font-size: 12px;
    font-weight: 600;
    line-height: 16px;
}

@mixin text_small_regular {
    font-family: $inter-font;
    font-style: normal;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
}

@mixin text_x_small_regular {
    font-family: $inter-font;
    font-style: normal;
    font-size: 12px;
    font-weight: 400;
    line-height: 16px;
}

@mixin text_medium_regular {
    font-family: $inter-font;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
}

@mixin text_medium_bold {
    font-family: $inter-font;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
}

@mixin text_large_regular {
    font-family: $inter-font;
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 28px;
}

@mixin text_large_bold {
    font-family: $inter-font;
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 28px;
}

@mixin item_header_10 {
    font-family: $inter-font;
    font-style: normal;
    font-size: 10px;
    font-weight: 500;
    line-height: 12px;

}

@mixin item_header_12 {
    font-family: $inter-font;
    font-style: normal;
    font-size: 12px;
    font-weight: 600;
    line-height: 12px;
}
