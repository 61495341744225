@import "../../../../../styles/main.scss";

.activeLists {
  background-color: var(--white);
  display: flex;
  align-items: center;
  @include text_small_bold;
  color: $blue;
  position: relative;
  padding-left: 16px;
  cursor: pointer;
  height: 52px;
  min-height: 52px;

  &Shadow {
    border-radius: 8px;
    box-shadow: 0px 3px 4px 0px #18181c0d;
    height: 45px;
  }

  svg {
    margin-right: 8px;
  }

  &__counter {
    // position: absolute;
    margin-left: auto;
    margin-right: 5px;
    right: 16px;
  }

  &__noData {
    @include text_small_bold;
    color: $mediumGray;
    text-align: center;
    margin: 15px 0;
  }
}

.popup {
  width: 300px;
  margin: 0 0 10px 10px;
  border: 1px solid #efefef;
  box-shadow: 0px 5px 60px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  padding: 5px 0;
  overflow-y: scroll;
  max-height: 80vh;
  background-color: var(--white);
  -webkit-animation: anvil 0.15s cubic-bezier(0.38, 0.1, 0.36, 0.9) forwards;

  &__darkMode {
    width: 100%;
    background-color: var(--white);
    &:hover {
      background-color: var(--hoverColor);
    }
  }

  &__button {
    width: 270px;
    height: 36px;
    margin: 5px auto;

    & button {
      max-width: 270px;
      max-height: 36px;
    }
  }

  &__item {
    display: flex;
    flex-direction: row;
    align-items: center;
    min-height: 40px;
    padding: 5px 5px 5px 17px;
    cursor: pointer;
    @include text_small_regular;

    &:hover {
      background-color: var(--hoverColor);
    }

    & > svg {
      margin-right: 12px;
    }

    &Underline {
      width: 100%;
      height: 1px;
      background-color: #efefef;
    }
  }

  @keyframes anvil {
    0% {
      transform: scale(1) translateY(0px);
      opacity: 0;
    }

    1% {
      transform: scale(0.96) translateY(10px);
      opacity: 0;
    }

    100% {
      transform: scale(1) translateY(0px);
      opacity: 1;
    }
  }
}
