@import '../../../../../styles/main.scss';

.folderItem {

        display: flex;
        min-height: 64px;
        padding: 5px 12px;
        align-items: center;
        position: relative;


        &:hover {
            background-color: var(--hoverColor);
        }

        &__organization {
           
            flex: 1;
            display: flex;
            align-items: center;
            @include text_small_bold;

            &Avatar{
                width: 40px;
                height: 40px;
                border-radius: 7px;
                margin-right: 10px;

                & img{
                    width: 100%;
                    height: 100%;
                }
            }

        }

        &__members {
            flex: 0.25;
            @include text_small_regular;
            color: $darkGray;

            &Short{
                display: none;
            }
        }

        &__date {
            flex: 0.4;
            @include text_small_regular;
            color: $darkGray;

        }

        &__actions{
            flex: 0.3;
            display: flex;
            align-items: center;
            justify-content: flex-end;

            & button{
                 min-width: 74px;
                 max-height: 36px;
            }

            & > :first-child{
                margin-right: 20px;
            }
        }
    

        &Status {
            display: flex;
            justify-content: space-between;
            align-items: center;
            flex: 2;

            &Left {
                display: flex;
                flex-direction: row;
                align-items: center;
                word-break: normal;
                font-family: $inter-font;
                font-size: 10px;
                font-weight: 700;
                letter-spacing: 0.08em;
                text-transform: uppercase;

                &.yellow {
                    color: $orange;
                }

                &.red {
                    color: $red;
                }

                &.green {
                    color: $mainGreen;
                }
            }

            &Circle {
                width: 8px;
                min-width: 8px;
                height: 8px;
                border-radius: 8px;
                margin-right: 6px;


                &.yellow {
                    background-color: $orange;
                }

                &.red {
                    background-color: $red;
                }

                &.green {
                    background-color: $mainGreen;
                }
            }

            &Refresh {
                width: 24px;
                height: 24px;
                min-width: 24px;
                border-radius: 100px;
                background-color: $green20;
                display: flex;
                justify-content: center;
                align-items: center;
                margin-left: 8px;
                cursor: pointer;
            }

            &Right {
                cursor: pointer;
            }
        }

    &__modal {
        width: 230px;
        padding: 10px;
        overflow: hidden;
        background-color: var(--white);
        border: 1px solid var(--lightGray);
        box-shadow: 0px 5px 60px rgba(0, 0, 0, 0.1);
        border-radius: 8px;
        transition: all 0.1s;
        -webkit-animation: anvil 0.15s cubic-bezier(0.38, 0.1, 0.36, 0.9) forwards;

        @keyframes anvil {
            0% {
                transform: scale(1) translateY(0px);
                opacity: 0;
            }

            1% {
                transform: scale(0.96) translateY(0px);
                opacity: 0;
            }

            100% {
                transform: scale(1) translateY(0px);
                opacity: 1;
            }
        }


        &Underline {
            width: 100%;
            height: 1px;
            background-color: $gray;
            margin: 7px 0;
        }

        &.bottom {
            height: auto;
        }

        &Item {
            height: 32px;
            display: flex;
            align-items: center;
            border-radius: 7px;
            @include text_small_regular;
            cursor: pointer;

            &:hover {
                background-color: var(--lightGray);
            }

            &.margin {
                margin: 2px 0;
            }

            svg {
                margin: 0 10px;
            }

            &Logo {
                display: flex;
                justify-content: center;
                align-items: center;
                position: absolute;
                right: 0;
                margin-right: 10px;

                animation: rotation 1.5s infinite linear;

                @keyframes rotation {
                    from {
                        transform: rotate(0deg);
                    }

                    to {
                        transform: rotate(359deg);
                    }
                }
            }
        }
    }

    &__underline {
        width: 100%;
        height: 1px;
        background-color: $gray;
    }


    @media (max-width: 1180px) {
        &__organization{
            flex: 0.6;
        }
        &__date{
            display: none;
        }
    }

    @media (max-width: 900px) {
        &__organization {
            &Avatar{
                width: 24px;
                height: 24px;
            }
        }

        &__actions{
            & button{
                 min-width: 53px;
                 max-height: 28px;
            }

            & > :first-child{
                margin-right: 10px;
            }
        }
    }

    @media (max-width: 767px) {
        &__members {
            @include text_x_small_regular;
        }
    }

    @media (max-width: 600px) {
        &__organization{
            flex: 0.5;
        }
        &__members{
            flex: 0.2;
        }
        &__actions{
            flex: 0.25;
        }
        
        &__members{
            &Long{
                display: none;
            }
            &Short{
                display: block;
            }
        }
    }

    @media (max-width: 445px) {
        &__organization{
            flex: 1;
        }
        &__members{
            display: none;
        }
    }
}