@import '../../../../../styles/main.scss';

.contactUsModal {
    width: 542px;


    @media (max-width: 767px) {
        width: 70vw;
    }
    
    @media (max-width: 600px) {
        width: auto;
     }
    
    &__title {
        @include desktop_h4;
    }

    &__subtitle{
        @include text_small_regular;
        color: $darkGray;
        margin: 5px 0 20px;
    }

    &__buttons{
        display: flex;
        justify-content: space-between;

        & > *{
            width: 50%;

            &:first-child{
                margin-right: 10px;
            }
        }
    }
}