@import '../../../../../../../../styles/main.scss';

.markerSettings {
    display: flex;
    flex-direction: column;
    &__underline{
        height: 1px;
        width: 100%;
        background-color: $gray;
        margin: 10px 0 15px;
    }
    
    &__timeInputs{
        display: flex;
        margin: 15px 0;

        & > * {
            flex: 1;
        }

        & > :first-child{
            margin-right: 15px;
        }
    }

    &__row{
        display: flex;

        & svg{
            margin: 0 10px;
            cursor: pointer;
            align-self: center;
            margin-top: 20px;

        }

        margin-bottom: 10px;
    }

    &__addButton{
        align-self: flex-end;
        width: 120px;
        margin-top: 20px;
    }


    &__colorPicker{
        @include text_small_bold;
        color: $darkGray;
        display: flex;
        align-items: center;

        & p {
            margin-right: 10px;
        }
    }

}