@import '../../../../styles/main.scss';

.checklist {
    display: flex;
    flex-direction: column;
    height: 100vh;
    max-height: 100vh;
    flex: 1;

    &Dark {
        background-color: var(--lightGray);
    }

    &.fullHeight{
        height: max-content;
        max-height: none;
    }



    &__loader {
        flex: 1;
        width: 960px;
        max-height: calc(100vh - 52px);
        overflow: hidden;
        margin: 0 auto;

        &Title {
            height: 40px;
            width: 100%;
            border-radius: 7px;
            border: 1px solid #3A6CFF;
            background-color: rgba(41, 180, 115, 0.1);
        }

        &Subtitle {
            height: 32px;
            width: 100%;
            border-radius: 7px;
            border: 1px solid #3A6CFF;
            background-color: rgba(41, 180, 115, 0.1);
            width: 299px;
            margin: 20px 0;
        }

        &Content {
            height: 100%;
            border-top-left-radius: 7px;
            border-top-right-radius: 7px;
            border: 1px solid #3A6CFF;
            border-bottom: none;
            background-color: rgba(41, 180, 115, 0.1);
            display: flex;
            justify-content: center;
            align-items: center;

            &>svg {
                width: 48px;
                height: 48px;

                animation: rotation 1.5s infinite linear;

                @keyframes rotation {
                    from {
                        transform: rotate(0deg);
                    }

                    to {
                        transform: rotate(359deg);
                    }
                }
            }
        }
    }


    &__arrow {
        display: flex;
        justify-content: center;
        align-items: center;
        position: absolute;
        left: 20px;
        top: 36px;
        cursor: pointer;
    }

    &__deleteButton {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 44px;
        width: 44px;
        border-radius: 7px;
        background: rgba(239, 47, 50, 0.2);
        margin-left: 10px;
        cursor: pointer;

        &Loader {
            display: flex;
            justify-content: center;
            align-items: center;
            animation: rotation 1.5s infinite linear;


            @keyframes rotation {
                from {
                    transform: rotate(0deg);
                }

                to {
                    transform: rotate(359deg);
                }
            }
        }
    }

    &__header {
        padding: 0 calc((100vw - 960px)/ 2);

        &Content {
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: space-between;
            border-bottom: 1px solid #DBDEE7;
            padding-bottom: 20px;
        }
        &Left {
            display: flex;
            align-items: center;
            

            &>p {
                @include desktop_h1;
            }

            &> :last-child {
                margin-left: 15px;
                padding: 0 10px;
                height: 24px;
                display: flex;
                justify-content: center;
                align-items: center;
                border-radius: 5px;
                background: rgba(58, 108, 255, 0.2);
                @include item_header_12;
                color: $blue;
                margin-right: 10px;
                min-width: max-content;
            }

            &.completed {
                &> :last-child {
                    margin-left: 15px;
                    padding: 0 10px;
                    height: 24px;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    border-radius: 5px;
                    background: $green20;
                    @include item_header_12;
                    color: $mainGreen;
                    margin-right: 10px;
                    min-width: max-content;
                    
                }
            }
        }


        &Right {
            display: flex;
            align-items: center;

            &Reset {
                width: 44px;
                min-width: 44px;
                height: 44px;
                border-radius: 7px;
                border: 1px solid #DBDEE7;
                background-color: white;
                margin-right: 10px;
                display: flex;
                align-items: center;
                justify-content: center;
                cursor: pointer;

                &.loading{
                    svg{
                        animation: rotation 1.5s infinite linear;

                    }
                    @keyframes rotation {
                        from {
                            transform: rotate(0deg);
                        }
            
                        to {
                            transform: rotate(359deg);
                        }
                    }
                }
            }

            &ActionButton {
                margin-right: 10px;
                margin-bottom: 5px;
            }

            &Completed {
                width: 141px;
                min-width: 141px;
            }

            &Trash {
                display: flex;
                justify-content: center;
                align-items: center;
                height: 44px;
                width: 44px;
                border-radius: 7px;
                background: rgba(239, 47, 50, 0.2);
                margin-left: 10px;
                cursor: pointer;
            }

            &Pdf {
                width: 141px;
                min-width: 141px
            }
        }
    }


    &__dates {
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        margin-top: 22px;
        gap: 10px;
        padding: 0 calc((100vw - 960px)/ 2);

        &Start {
            height: 24px;
            padding: 0 10px;
            display: flex;
            justify-content: center;
            align-items: center;
            background-color: $green20;
            @include item_header_12;
            color: $mediumGray;
            border-radius: 5px;
            // margin-right: 15px;
            min-width: max-content;
        }

        &Finish {
            height: 24px;
            padding: 0 10px;
            display: flex;
            justify-content: center;
            align-items: center;
            background-color: $green20;
            @include item_header_12;
            color: $blue;
            border-radius: 5px;
            // margin-right: 15px;
            min-width: max-content;

        }
    }

    &__email {
        @include text_small_bold;
        color: $mediumGray;
        // width: 960px;
        padding: 0 calc((100vw - 960px)/ 2);
        // margin: 10px auto 0
        margin-top: 10px
    }

    &__tabTitle {
        @include desktop_h2;
        color: $mainGreen;
        margin: 20px 0 10px;
        padding: 0 calc((100vw - 960px)/ 2);
    }

    &__content {
        padding-top: 32px;
        display: flex;
        flex-direction: column;
        flex: 1;
        // max-height: calc(100vh - 52px);
        width: 100vw;
        margin: 0 auto;
        overflow: hidden;

        &Pdf {
            margin: 0 auto;
            overflow-y: auto;
            padding: 20px 0;

        }

        &Sections {
            max-height: 400px;
            padding: calc((100vw - 960px)/ 2);
            flex: 1;
            max-height: 100%;
            overflow-y: scroll;
            padding-bottom: 22px;
            padding-top: 10px;

            // &::-webkit-scrollbar {
            //     display: none;
            // }
        }
    }

    &__sections {
        padding: 15px;
        border: 1px solid;
        border-radius: 7px;
        
        &__wrapper {
            width: 960px;
            margin: 0 auto;
        }

        &Header {
            display: flex;
            align-items: center;
            justify-content: space-between;

            &> :first-child {
                @include desktop_h2;
            }

            &> :last-child {
                display: flex;
                align-items: center;
            }

            &Date {
                @include text_x_small_bold;
                margin-right: 10px;
            }
        }
    }

    @media (max-width: 1075px) {
        &__content {

            &Sections {
                padding-left: 5vw;
                padding-right: 5vw;
            }
        }

        &__header {
            // background-color: #3A6CFF;
            flex-wrap: wrap;
            padding-left: 5vw;
            padding-right: 5vw;
        }

        &__tabTitle {
            padding-left: 5vw;
            padding-right: 5vw;
        }

        &__dates {
            &.padding {
                padding-left: 5vw;
                padding-right: 5vw;
            }
        }

        &__email {
            padding-left: 5vw;
        }

    }

    @media (max-width: 764px) {

        &__arrow {
            left: 5vw;
            top: 15px
        }

        &__content {
            padding-top: 36px;
        }
    }


    @media (max-width: 662px) {
        &__header {
                &Right{
                    margin-top: 5px;
                }
        }
    }
}