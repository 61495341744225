@import '../../../../styles/main.scss';

.editOrganization {
    padding-left: 4px;
    overflow-y: auto;

    &::-webkit-scrollbar {
        display: none;
    }

    &__switchField {
        width: 100%;
        max-width: 454px;
        margin: 25px 0;
    }

    &__subtitle {
        @include text_small_regular;
        color: $darkGray;
    }

    &__picker{
        display: inline-block;
        margin-top: 25px;
        cursor: pointer;

        &Avatar {
            width: 140px;
            height: 140px;
            border-radius: 7px;
            border: 3px dashed $gray;
            display: flex;
            justify-content: center;
            align-items: center;
            overflow: hidden;

            &.exist{
                border: 0;
            }

            &>img {
                width: 100%;
                height: 100%;
            }
        }

        &>p {
            @include text_x_small_regular;
            color: $mediumGray;
            margin-top: 10px;
            text-align: center;
        }
    }

    &__input {
        width: 100%;
        max-width: 454px;
        margin: 30px 0 25px;
    }

    &__buttons {
        display: flex;

        &>* {
            width: 98px;
        }

        &> :first-child {
            margin-right: 10px;
        }
    }

    &__deleteButton{
        width: 191px;
        margin-top: 25px;
    }

    @media (max-width: 900px){
        &__picker{
            &Avatar {
                width: 80px;
                height: 80px;
            }
        }

        &__buttons {
    
            &>* {
                width: 83px;
            }
        }
    }

    @media (max-width: 767px){
        &__switchField {
        margin: 20px 0;
        }

        &__picker{
            margin-top: 20px;
        }

        &__input {
            margin: 20px 0;

        }

        &__buttons {
    
            & button {
               max-height: 36px;
            }
        }

        &__deleteButton{
            width: 173px;
            margin-top: 20px;
            & button {
                max-height: 36px;
            }
           
        }
    }
}

