@import '../../../../../styles/main.scss';

.addCard {
    width: 402px;

    &__title {
        @include desktop_h4;
    }

    &__subtitle {
        @include text_small_regular;
        color: $darkGray;
        margin: 5px 0 20px;
    }

    &__row {
        display: flex;
        align-items: center;
        margin: 20px 0;
    }

    &__image {
        width: 40px;
        height: 40px;
        border-radius: 7px;
        overflow: hidden;
        margin-right: 11px;

        &>img {
            width: 100%;
            height: 100%
        }
    }

    &__name {
        @include text_small_bold;
    }

    &__input {
        margin-bottom: 15px;
    }

    &__buttons {
        display: flex;
        margin-top: 20px;

        &> :first-child {
            margin-right: 10px;
            border: 1px solid $gray;
            border-radius: 7px;
        }

        &>* {
            width: auto;
            flex: 1;
        }
    }

    @media (max-width: 600px) {
        &__image {
            width: 30px;
            height: 30px;
            margin-right: 7px;
        }

        &__name {
            font-size: 12px;
            font-weight: 600;
            line-height: 15px;
            letter-spacing: 0em;
            text-align: left;
            color: var(--black);
        }

        &__row {
            margin: 15px 0;
        }

        &__buttons {
            margin-top: 15px;

            & button {
                max-height: 35px;
            }
        }


        &__input {

            & input {
                max-height: 31px;
            }
        }
    }

    @media (max-width: 510px) {
        width: calc(100vw - 110px);
    }
}