@import '../../styles/main.scss';

.dropdownContainer {
    width: 100%;
    position: relative;

    &__label {
        color: $darkGray;
        @include text_small_bold;
        margin: 0;
        text-align: left;
        margin-bottom: 5px;
    }

    &__field {
        display: flex;
        flex-direction: row;
        position: relative;
        align-items: center;
        justify-content: space-between;
        
        height: 44px;
        border-radius: 7px;
        padding: 0 10px 0 15px;
        border: 1px solid #DBDEE7;

        box-shadow: 0px 3px 4px rgba(24, 24, 28, 0.05);
        background-color: var(--white);
        overflow: hidden;
        cursor: pointer;

        @include text_small_regular;

        &Placeholder {
            color: $mediumGray;
        }

        &Icon {
            display: flex;
            justify-content: center;
            align-items: center;

            transform: rotate(0deg);
            transition: all 0.1s;


            &.rotate {
                transform: rotate(180deg);
                transition: all 0.1s;
            }
        }

        &.active {
            box-shadow: 0px 0px 0px 3px rgba(58, 108, 255, 0.2);
            border-color: $blue;
        }

        &.disable{
            cursor: default;
        }
    }


    &__select {
        display: flex;
        flex-direction: column;
        position: absolute;

        width: 100%;
        height: 0px;
        top: calc(100% + 5px);
        border-radius: 8px;
        box-shadow: 0px 5px 60px rgba(0, 0, 0, 0.1);

        transition: all 0.1s;
        background-color: var(--white);
        overflow: hidden;
        z-index: 30;

        &.rotate{
            top: auto;
            bottom: calc(100% + 5px);
        }

        &Block {
            width: 100%;
            border: 1px solid var(--lightGray);
            padding: 10px 10px;
            overflow-y: auto;
            border-radius: 8px;
        }

        &.close {
            height: 0;
        }

        &.active60 {
            height: 54px;
        }

        &.active105 {
            height: 86px;

        }

        &.active150 {
            height: 118px;
        }
    }




    &__item {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        height: 32px;
        min-height: 32px;
        padding: 0 10px;
        cursor: pointer;
        @include text_small_regular;
        border-radius: 7px;

        color: var(--black);

        &:hover {
            background-color: var(--lightGray);
        }
    }

}