@import "../../../../../styles/main.scss";

.player {
  max-width: 358px;
  &__button {
    height: 84px;
    width: 84px;
    background: #ffffff38;
    border-radius: 100px;
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  &__thumbnail {
    width: 330px;
    height: 250px;
    border-radius: 7px;
    overflow: hidden;
    & > img {
      object-fit: none;
    }
  }
  &__title {
    @include desktop_h3;
    color: $darkBlue;
    margin-top: 20px;
    margin-left: 15px;
    margin-bottom: 35px;
  }
}
