@import '../../../../../../styles/main.scss';

.modal{
    width: 542px;

    &__title{
        @include desktop_h4;
        text-align: center;
    }

    &__subtitle{
        @include text_medium_regular;
        color: $darkGray;
        text-align: center;
        margin: 20px 0;
    }

   

    &__buttons{
        display: flex;
        align-items: center;
        & > :first-child{
            margin-right: 10px;
        }

        & > *{
            flex: 1;
        }
      
    }

    @media (max-width: 630px){
        width: calc(100vw - 90px);
        &__title{
            margin-top: 5px;
        }
    }
  
}