@import '../../../../../../styles/main.scss';

.item {
    padding: 15px;
    border: 1px solid $gray;
    border-radius: 7px;
    margin-bottom: 10px;
    background-color: var(--white);
    box-shadow: 0px 3px 4px 0px #18181C0D;

    &Dark {
        background-color: var(--lightGray);
    }


    &__collapseArrow {
        display: inline;
        width: 24px;
        height: 22px;
        cursor: pointer;
        margin-right: 10px;

        &.rotate {
            transform: rotate(180deg);
        }
    }


    &__underline {
        width: 100%;
        height: 1px;
        background-color: $gray;
        margin: 15px 0 0;
    }


    &.firstItem {
        margin-top: 15px;
    }

    &__header {
        display: flex;
        justify-content: space-between;
        align-items: flex-start;
        border-bottom: 1px solid #DBDEE7;
       &Checked {
        border-bottom-color: #979DB1;
       }

        &Left {
            display: flex;
            margin-right: 30px;
            word-break:normal;
            // &Checked {
            //     text-decoration: line-through;
            // }
        }

        &Right{
            display: flex;
            flex-direction: column;
            align-items: end;
            margin-bottom: 37px;

            &Checked {
                margin-bottom: 0;
            }
            
            &Date{
                @include text_x_small_bold;
                align-self: center;
                margin-top: 15px;
                height: 16px;
                margin-bottom: 6px;
                width: max-content;
            }  
        }

    }

    &__description {
        @include text_small_bold;
        margin-top: 5px;
    }

    &.checked {
        background-color: var(--mediumGray);
    }

    & .hide {
        height: 0;
        overflow: hidden;
    }
}