@import '../../../../styles/main.scss';

.paymentBlock {
    overflow-y: scroll;
    &__title {
        @include desktop_h1;
        color: $darkBlue;
        text-align: left;

        @media (max-width: 767px) {
            @include desktop_h2
        }

    }

    &__subtitle {
        @include text_medium_regular;
        color: $darkGray;
        margin: 15px 0 25px;
        text-align: left;

        span {
            color: $mainGreen;
        }
    }

    &__chooseSubscription {
        display: flex;
        gap: 16px;
    }
    &__subscriptionPrice {
        @include desktop_h2;
        color: $mainGreen;
        text-align: center;

        margin-block: 20px;
    }
    &__feature {
        @include text_small_regular;
        border-bottom: 1px dashed $gray;
        padding-block: 13px;
        color: $darkGray;
        display: flex;
        justify-content: space-between;
        align-items: center;
    }
    &__top {
        background-color: var(--lightGray);
        padding: 20px 30px;
        border-radius: 7px;
        margin-bottom: 35px;
        flex: 1;
        cursor: pointer;

        &Active {
            border: 2px solid $mainGreen
        }

        @media (max-width: 767px) {
            padding: 30px;
            margin-bottom: 25px;
        }

        &Title {
            @include desktop_h3;
            color: $darkBlue;
            text-align: left;
        }

        &Row {
            display: flex;
            align-items: center;
            justify-content: space-between;
            @include text_small_regular;
            color: $darkGray;


            @media (max-width: 767px) {
                flex-direction: column;
                align-items: flex-start;
            }


            &Right {
                display: flex;
                align-items: center;
                justify-content: space-between;
                width: 290px;

                @media (max-width: 968px) {
                    width: 250px;
                }

                @media (max-width: 767px) {
                    margin-top: 10px;
                    width: 100%;
                }

            }

            p {
                text-align: right;
                color: $darkBlue;
            }
        }

        &Total {
            display: flex;
            align-items: center;
            justify-content: space-between;
            @include desktop_h4;
            color: $darkBlue;
            margin-top: 15px;
        }
    }


    &__prices {
        display: flex;
        align-items: center;
        justify-content: space-between;
        @include text_small_regular;
        color: $darkGray;
        align-items: flex-end;

        &Left {
            display: flex;
            align-items: center;
            margin-top: 20px;

            @media (max-width: 767px) {
                margin-top: 15px;
            }
        }

        &Right {
            color: $darkBlue;
        }
    }

    &__underline {
        border: 1px dashed $gray;
        margin: 13px 0;

        &Solid {
            border: 1px solid $gray;
            margin: 13px 0;
        }
    }

    &__paymentInput {

        margin: 25px 0;

        @media (max-width: 767px) {
            margin-top: 15px;
        }
    }

    &__buttons {
        display: flex;
        justify-content: space-between;
        margin-top: 25px;

        &>* {
            width: 200px;

            @media (max-width: 736px) {
                width: 152px;
            }
        }
    }
}