@import '../../../../styles/main.scss';

.folderItem {
    position: relative;
    background-color: var(--white);

    &__horizontal {
        display: flex;
        min-height: 52px;
        padding: 5px 12px;
        align-items: center;
        position: relative;
        cursor: pointer;


        &:hover {
            background-color: var(--hoverColor);
        }

        &Name {
            display: flex;
            align-items: center;
            @include text_small_bold;
            flex: 2.95;
            padding-right: 10px;
            word-break: break-all;

            svg {
                margin-right: 20px;
                min-width: 20px
            }
        }

        &Date {
            @include text_small_regular;
            text-align: left;
            flex: 1.3;
        }

        &Action {
            flex: 1;

            & button {
                max-width: 49px;
                max-height: 24px;
            }
        }

        &Type {
            flex: 1;
            display: flex;

            &Draft {
                @include item_header_12;
                color: var(--white);
                background-color: #52C58F;
                height: 24px;
                display: flex;
                align-items: center;
                justify-content: center;
                border-radius: 5px;
                padding: 0 10px;
            }
        }

        &Status {
            display: flex;
            justify-content: space-between;
            align-items: center;
            flex: 2;

            &Left {
                display: flex;
                flex-direction: row;
                align-items: center;
                word-break: normal;
                font-family: $inter-font;
                font-size: 10px;
                font-weight: 700;
                letter-spacing: 0.08em;
                text-transform: uppercase;

                &.yellow {
                    color: $orange;
                }

                &.red {
                    color: $red;
                }

                &.green {
                    color: $mainGreen;
                }
            }

            &Circle {
                width: 8px;
                min-width: 8px;
                height: 8px;
                border-radius: 8px;
                margin-right: 6px;


                &.yellow {
                    background-color: $orange;
                }

                &.red {
                    background-color: $red;
                }

                &.green {
                    background-color: $mainGreen;
                }
            }

            &Refresh {
                width: 24px;
                height: 24px;
                min-width: 24px;
                border-radius: 100px;
                background-color: $green20;
                display: flex;
                justify-content: center;
                align-items: center;
                margin-left: 8px;
                cursor: pointer;
            }

            &Right {
                cursor: pointer;
            }
        }
    }

    &__modal {
        width: 230px;
        padding: 10px;
        overflow: hidden;
        background-color: var(--white);
        border: 1px solid var(--lightGray);
        box-shadow: 0px 5px 60px rgba(0, 0, 0, 0.1);
        border-radius: 8px;
        transition: all 0.1s;
        -webkit-animation: anvil 0.15s cubic-bezier(0.38, 0.1, 0.36, 0.9) forwards;

        @keyframes anvil {
            0% {
                transform: scale(1) translateY(0px);
                opacity: 0;
            }

            1% {
                transform: scale(0.96) translateY(0px);
                opacity: 0;
            }

            100% {
                transform: scale(1) translateY(0px);
                opacity: 1;
            }
        }


        &Underline {
            width: 100%;
            height: 1px;
            background-color: $gray;
            margin: 7px 0;
        }

        &.bottom {
            height: auto;
        }

        &Item {
            height: 32px;
            display: flex;
            align-items: center;
            border-radius: 7px;
            @include text_small_regular;
            cursor: pointer;

            &:hover {
                background-color: var(--lightGray);
            }

            &.margin {
                margin: 2px 0;
            }

            svg {
                margin: 0 10px;
                path {
                    fill: var(--black);
                }
            }

            &Logo {
                display: flex;
                justify-content: center;
                align-items: center;
                position: absolute;
                right: 0;
                margin-right: 10px;

                animation: rotation 1.5s infinite linear;

                @keyframes rotation {
                    from {
                        transform: rotate(0deg);
                    }

                    to {
                        transform: rotate(359deg);
                    }
                }
            }
        }
    }

    &__underline {
        width: 100%;
        height: 1px;
        background-color: $gray;
    }


    @media (max-width: 1150px) {
        &__horizontal {

            &Date {
                display: none;
            }
        }
    }


    @media (max-width: 1050px) {
        &__horizontal {
            &Type {
                display: none
            }
        }
    }

    @media (max-width: 1004px) {
        &__horizontal {
            &Status {
                &Left {

                    width: min-content;

                }
            }
        }
    }


    @media (max-width: 900px) {
        &__horizontal {

            &Name {
                @include text_x_small_bold;
                padding-right: 5px;

                svg {
                    margin-right: 10px;
                }
            }
        }
    }

    @media (max-width: 850px) {
        &__horizontal {

            &Action {
                display: none;
            }
        }
    }


    @media (max-width: 600px) {
        &__horizontal {

            &Name {
                flex: 1.7;

                svg {
                    margin-right: 5px;
                }
            }

            &Status {
                flex: 1;

                &Left {
                    font-size: 8px;
                    line-height: 12px;
                    letter-spacing: 0.08em;
                }
            }
        }
    }

    @media (max-width: 452px) {
        &__horizontal {
            &Status {
                &Refresh {
                    display: none;
                }
            }
        }
    }
}