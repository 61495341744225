@import '../../../../../../../../styles/main.scss';

.selector {
    flex: 1;
    padding: 10px 0;

    &__noData {
        @include text_small_regular;
        color: $mediumGray;
        display: flex;
        justify-content: center;
        align-items: center;
        padding-top: 7px;
        flex: 1;
    }

    &__selection {
        background-color: var(--white);
        display: flex;
        align-items: center;
        height: 44px;
        padding: 0 15px;
        border: 1px solid #DBDEE7;
        border-radius: 7px;
        @include text_small_bold;
        color: $mainGreen;
        margin-bottom: 5px;
        cursor: pointer;

        &.disable {
            color: $gray;
            cursor: default;
        }

        &.defaultCursor{
            cursor: default;
        }

        &.choosed {
            background-color: rgba(41, 180, 115, 0.1);
            border: 1px solid $mainGreen;
        }

    }
}