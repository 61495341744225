@import '../../styles/main.scss';

.onboardingLayout {
    width: 100vw;
    min-height: 100vh;
    background-image: url('../../assets/images/onboardingBg.png');
    background-size: cover;

    @media (max-width: 600px) {
        background-image: none;
    }

    &__content {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-between;
        height: 100%;
        min-height: 100vh;

        padding: 61px 0 30px 0;

        @media (max-width: 600px) {
            width: 100%;
            padding: 30px 0;
        }
    }

    &__logo {
        display: flex;
        justify-content: center;

        @media (max-width: 600px) {

            svg {
                width: 168px;
                margin: 0 auto;
            }
        }
    }

    &__modal {
        display: inline-block;
        padding: 60px;
        background-color: #FFFFFF;
        box-shadow: 0px 5px 60px rgba(0, 0, 0, 0.1);
        border-radius: 15px;
        margin-top: 51.2px;

        @media (max-width: 600px) {
            box-shadow: none;
            padding: 30px;
            padding: 20px;
            margin-top: 28.12px;
            width: 100vw;
        }

        @media (max-width: 576px) {
            box-shadow: none;
            padding: 30px;
            padding: 20px;
            margin-top: 28.12px;
            width: 100vw;
        }
    }


    &__footer {
        margin-top: 40px;
        color: #979DB1;

        @media (max-width: 600px) {
            @include text_x_small_regular;
        }

        span {
            margin: 0 10px;
        }

        &Button{
            display: inline;
            // cursor: pointer;

            & span{
                cursor: pointer;
            }
        }
    }

    &__loader{
        width: 100vw;
        height: 100vh;
        background-color: red;
        display: flex;
        justify-content: center;
        align-items: center;

        @include desktop_h1;
    }

}