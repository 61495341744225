@import '../../../../styles/main.scss';

.organizationName {

    &__title {
        @include desktop_h1;
        color: $darkBlue;
        text-align: left;

        @media (max-width: 767px) {
            @include desktop_h2
        }

    }

    &__subtitle {
        @include text_medium_regular;
        color: $darkGray;
        margin: 15px 0 25px;
        text-align: left;
    }

    &__content {
        display: flex;
        flex-direction: row;
        padding-top: 30px;

        @media (max-width: 767px) {
            flex-direction: column;
            justify-content: center;
            align-items: center;
            padding-top: 0;


            &Picker {
                display: flex;
                flex-direction: column;
                align-items: center;
            }
        }

        &Input {
            width: 100%;
            margin-left: 50px;
            margin-top: 17px;

            @media (max-width: 968px) {
                margin-left: 30px;
            }

            @media (max-width: 767px) {
                margin-left: 0;
                margin-top: 25px;
            }


        }
    }

    &__button {
        display: flex;
        justify-content: flex-end;
        margin-top: 70px;

        & :last-child {
            width: 200px;
        }

        @media (max-width: 767px) {
            margin-top: 25px;
        }
    }
    
    &__picker{
        display: inline-block;
        cursor: pointer;

        &Avatar {
            width: 100px;
            height: 100px;
            border-radius: 7px;
            border: 3px dashed $gray;
            display: flex;
            justify-content: center;
            align-items: center;
            overflow: hidden;

            &.exist{
                border: 0;
            }

            &>img {
                width: 100%;
                height: 100%;
            }
        }

        &>p {
            @include text_x_small_regular;
            color: $mediumGray;
            margin-top: 10px;
            text-align: center;
        }
    }
}