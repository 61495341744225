@import '../../../../../../../styles/main.scss';

.organizationItem {
    position: relative;
    display: flex;
    align-items: center;
    flex-grow: 1;
    min-width: 100%;
    height: 36px;
    padding: 0 14px 0 20px;
    margin-bottom: 15px;
    position: relative;
    cursor: pointer;


    &__indicator {
        height: 24px;
        width: 4px;
        border-radius: 4px;
        background-color: $mainGreen;
        position: absolute;
        left: 8px;
    }

    &__icon {
        width: 32px;
        height: 32px;
        min-width: 32px;
        border-radius: 7px;
        overflow: hidden;

        &>img {
            width: 100%;
        }
    }

    &__center {
        width: 100%;
        margin: 0 10px;
    }

    svg {
        cursor: pointer
    }

    &__title {
        @include text_small_bold
    }

    &__members {
        @include text_x_small_regular;
        color: $darkGray;
    }

    &__popup {
        width: 0;
        height: 0;
        overflow: hidden;
        box-shadow: 0px 5px 60px rgba(0, 0, 0, 0.1);
        border-radius: 8px;
        @include text_small_regular;
        position: absolute;
        background-color: var(--white);
        top: 6px;
        right: 7px;
        display: flex;
        justify-content: center;
        align-items: center;
        transition: all 0.1s;

        &.active {
            width: 168px;
            height: 39px;
            border: 1px solid var(--lightGray);
        }
    }

    @media (max-width: 767px) {
        height: 32px;
        padding: 0px 15.12px 0px 10.59px;
        margin-bottom: 11px;

        &__indicator {
            width: 3.02px;
            height: 18.05px;
            left: 5px;
        }

        &__icon {
            width: 24.2px;
            height: 24.2px;
            min-width: 24.2px;
        }

        &__center {
            margin: 0 7.56px;
        }

        &__title {
            @include text_x_small_bold
        }
    
    }
}