@import '../../styles/main.scss';

.contactUsModal {

    width: 402px;

    @media (max-width: 600px) {
        width: auto;
     }
    
    &__title {
        @include desktop_h4;
    }

    &__subtitle{
        @include text_small_regular;
        color: $darkGray;
        margin: 5px 0 20px;

        span {
            @include text_small_bold;
            color: $mainGreen;
        }
    }

    &__input{
        margin: 15px 0;
    }

    &__buttons{
        display: flex;
        justify-content: space-between;
        margin-top: 20px;

        & > *{
            width: 196px;

            @media (max-width: 600px) {
                width: 50%;

                &:first-child{
                    margin-right: 10px;
                }
             }
        }
    }

}