@import './vars';

html {
  box-sizing: border-box;
}

*,
*::before,
*::after {
  box-sizing: border-box;
}

* {
  margin: 0;
  padding: 0;
}

ul,
ol,
li {
  list-style-position: inside;
  padding-left: 0;
}

a {
  text-decoration: none;
}

a:focus {
  outline: none;
}

body {
  font-family: $inter-font;
  font-weight: 400;
  font-size: 16px;
  color: var(--black);
  background-color: var(--white);
  position: relative;
}

@mixin activeNavLink {
  // background: $primary;
  padding: 13px 18px;
  border-radius: 15px;
  text-decoration: none;
  // color: var(--white);
  // font-family: $primary-font;
  font-style: normal;
  font-weight: 800;
  font-size: 16px;
  line-height: 18px;
  border: none;
}

@mixin inactiveNavLink {
  padding: 11px 16px;
  border-radius: 15px;
  text-decoration: none;
  // color: $textblack;
  // font-family: $primary-font;
  font-style: normal;
  font-weight: 800;
  font-size: 16px;
  line-height: 18px;
  // border: 2px solid $grayE4;
}

.ReactModal__Overlay {
  opacity: 0;
  transition: opacity 200ms ease-in-out;
}

.ReactModal__Overlay--after-open {
  opacity: 1;
}

.ReactModal__Overlay--before-close {
  opacity: 0;
}