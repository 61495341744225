@import '../../../../../../styles/main.scss';

.memberItem {
    display: flex;
    height: auto;
    min-height: 64px;
    padding: 5px 12px;
    position: relative;

    &:hover {
        background-color: var(--hoverColor);
    }

    &__member {
        flex: 1.2;
        display: flex;
        align-items: center;


        &Avatar {
            width: 40px;
            min-width: 40px;
            height: 40px;
            border-radius: 100px;
            overflow: hidden;
            margin-right: 10px;

            &>img {
                width: 100%;
                height: 100%;
            }
        }

        &Initials {
            width: 100%;


            &> :first-child {
                @include text_small_bold;
                line-height: 18px;
                max-width: 95%;
            }

            &> :last-child {
                @include text_small_regular;
                color: $mediumGray;
                line-height: 18px;
                max-width: 95%;
            }
        }
    }

    &__date {
        flex: 1;
        @include text_small_regular;
        display: flex;
        align-items: center;

        &Short {
            display: none;
        }

        &Header {
            flex: 1;
        }

        &.invited {
            @include text_small_bold;
            color: $blue;
        }
    }

    &__role {
        flex: 1;
        display: flex;
        justify-content: space-between;
        align-items: center;

        &Header {
            flex: 1;
        }


        &>p {
            @include text_small_regular;
        }

        &Dropdown {
            width: 230px;
        }

        &Left {
            display: flex;
            align-items: center;

            &>svg {
                margin-left: 10px;
                animation: rotation 1.5s infinite linear;

                @keyframes rotation {
                    from {
                        transform: rotate(0deg);
                    }

                    to {
                        transform: rotate(359deg);
                    }
                }

            }
        }
    }

    &__underline {
        height: 1px;
        width: 100%;
        background-color: $gray;

    }

    &__modal {
        width: 200px;
        overflow: hidden;
        background-color: var(--white);
        border: 1px solid var(--lightGray);
        border: 0;
        padding: 10px;
        box-shadow: 0px 5px 60px rgba(0, 0, 0, 0.1);
        border-radius: 8px;
        transition: all 0.1s;
        z-index: 1;
        -webkit-animation: anvil 0.15s cubic-bezier(0.38, 0.1, 0.36, 0.9) forwards;


        @keyframes anvil {
            0% {
                transform: scale(1) translateY(0px);
                opacity: 0;
            }

            1% {
                transform: scale(0.96) translateY(0px);
                opacity: 0;
            }

            100% {
                transform: scale(1) translateY(0px);
                opacity: 1;
            }
        }


        &Item {
            height: 32px;
            display: flex;
            align-items: center;
            border-radius: 7px;
            @include text_small_regular;
            cursor: pointer;

            &.disactive {
                cursor: default;
                color: $gray;

                &:hover {
                    background-color: white;
                }
            }

            &:hover {
                background-color: var(--lightGray);
            }

            &.margin {
                margin: 2px 0;
            }

            svg {
                margin: 0 10px;
            }
        }
    }



    @media (max-width: 1220px) {
        &__date {
            flex: 0.7;
        }

        &__role {
            flex: 0.8;

            &Dropdown {
                width: 140px;
            }
        }
    }

    @media (max-width: 1077px) {
        &__member {
            flex: 1.2;
            display: flex;
            align-items: center;

            &Avatar {
                width: 24px;
                height: 24px;
                min-width: 24px;
                margin-right: 8px;
                min-width: 24px;

                &>img {
                    width: 100%;
                    height: 100%;
                }
            }

            &Initials {

                &> :first-child {
                    @include text_small_bold;
                    line-height: 18px;
                }

                &> :last-child {
                    @include text_small_regular;
                    color: $mediumGray;
                    line-height: 18px;
                }
            }
        }
    }


    @media (max-width: 960px) {
        &__member {

            &Initials {
                &> :last-child {
                    @include text_x_small_regular;
                    font-size: 10px
                }
            }
        }

        &__date {
            @include text_x_small_regular;

            &.invited {
                @include text_x_small_regular;
            }

            &Large {
                display: none
            }

            &Short {
                display: inline
            }
        }

        &__role {
            &>p {
                @include text_x_small_regular;
            }

            &Dropdown {
                width: 100px;

                & * {
                    @include text_x_small_regular;
                }
            }
        }
    }

    @media (max-width: 900px) {

        &__role {
            &Dropdown {
                width: 90px;

                &> :first-child {
                    &> :first-child {
                        max-height: 24px;
                    }
                }

                & svg {
                    max-height: 20px;
                }
            }
        }
    }

    @media (max-width: 600px) {
        &__member {
            &Initials {
                &> :last-child {
                    font-size: 10px
                }
            }
        }

        &__date {
            font-size: 10px;

            &.invited {
                font-size: 10px;
            }
        }

        &__role {

            & * {
                font-size: 10px;
            }

            &>p {
                font-size: 10px;
            }



            &Dropdown {
                width: 73px;

                & * {
                    font-size: 10px;
                }

                &> :first-child {
                    border-radius: 4px;
                }

                & * {
                    border-radius: 4px;
                }

                & svg {
                    max-width: 12px;
                }
            }
        }
    }
}