.popperPopup {

    &__trigger {
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
    }
  
    &__popper {
      z-index: 3;
    }
    
  }