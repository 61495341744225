@import '../../../../../../styles/main.scss';

.footer {
    height: 52px;
    min-height: 52px;
    display: flex;
    border-top: 1px solid $gray;
    position: relative;

    &__actionButton {
        display: flex;
        align-items: center;
        justify-content: space-between;
        @include text_small_bold;
        color: $blue;
        // width: 280px;
        cursor: pointer;
        border-right: 1px solid $gray;
        padding: 0 16px;

        &> :first-child {
            display: flex;
            align-items: center;

            &> :first-child {
                margin-right: 8px;
            }
        }
    }

    &__scrollZone {
        overflow-x: scroll;
        display: flex;
        height: 100%;
        flex: 1;
        justify-content: center;

       &.removeCenteringItems {
            justify-content: flex-start;
        }
    }

    &__itemsWrapper {
        display: flex;
        height: 100%;
        justify-content: center;
        &.centering{
            margin: 0 auto;
        }
    }


    &__item {
        cursor: pointer;
        display: flex;
        justify-content: center;
        align-items: center;
        height: 100%;
        @include text_small_bold;
        color: $darkGray;
        border-right: 1px solid $gray;
        padding: 0 15px;
        min-width: max-content;

        &.leftBorder {
            border-left: 1px solid $gray;
        }
    }

    & .active {
        background: rgba(41, 180, 115, 0.1);
        color: $mainGreen;
    }

    &__pdfTab {
        height: 100%;
        width: 143px;
        background-color: rgba(253, 187, 18, 0.1);
        display: flex;
        align-items: center;
        justify-content: center;
        @include text_small_bold;
        color: $orange;
        cursor: pointer;

        & svg {
            margin-left: 5px;
        }
    }
}

.popup {
    width: 300px;
    margin: 0 0 10px 10px;
    border: 1px solid #EFEFEF;
    box-shadow: 0px 5px 60px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
    padding: 5px 0;
    background-color: var(--white);

    -webkit-animation: anvil 0.15s cubic-bezier(0.38, 0.1, 0.36, 0.9) forwards;

    &__button {
        width: 270px;
        height: 36px;
        margin: 5px auto;

        & button {
            max-width: 270px;
            max-height: 36px;
        }
    }


    @keyframes anvil {
        0% {
            transform: scale(1) translateY(0px);
            opacity: 0;
        }

        1% {
            transform: scale(0.96) translateY(10px);
            opacity: 0;
        }

        100% {
            transform: scale(1) translateY(0px);
            opacity: 1;
        }
    }

    &__item {
        display: flex;
        flex-direction: row;
        align-items: center;
        min-height: 40px;
        padding: 5px 5px 5px 17px;
        cursor: pointer;
        @include text_small_regular;

        &:hover {
            background-color: var(--hoverColor);
        }

        &.disable {
            cursor: default;

            &:hover {
                background-color: var(--white);
            }
        }

        &>svg {
            margin-right: 12px;
        }

        &Underline {
            width: 100%;
            height: 1px;
            background-color: #EFEFEF;
        }
    }
}